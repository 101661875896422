import '../../style/dialog-form.css';

import { useState } from 'react';

import {
  CollapsibleSection,
  DropdownMultiField,
  InlineTooltip,
  PropertyLabel,
  Switch
} from '../Core';
import IntegrationsSidebar from '../FormIntegrations/IntegrationsSidebar';
import useIntegrations from '../FormIntegrations/useIntegrations';
import { useParams } from 'react-router-dom';
import { AI_INTEGRATION_TYPES, AI_INTEGRATIONS_MAP } from './types';
import Combobox from '../Core/Combobox';
import { useAppSelector } from '../../hooks';
import { FieldSelectorWithModal } from '../Modals';

const DEFAULT_INBOX_CONFIG = {
  domains: [],
  panels: [],
  extract_inline_links: false,
  email_field: ''
};

function InboxSettingsSidebar() {
  const { extractionId } = useParams<{ extractionId: string }>();

  const panels = useAppSelector((state) => state.panels.panels);

  const integration = useIntegrations({
    entityType: 'ai',
    type: AI_INTEGRATION_TYPES.EMAIL_INBOX,
    extractionId
  });

  const [inboxConfig, setInboxConfig] = useState(
    integration?.data.metadata ?? DEFAULT_INBOX_CONFIG
  );

  const [isPartial, setIsPartial] = useState(false);
  const [err, setErr] = useState('');

  const updateConfig = (key: string, val: any) => {
    setInboxConfig({ ...inboxConfig, [key]: val });
  };

  function onSubmitCustom(newIsActive: boolean) {
    setErr('');

    if (newIsActive) {
      const partial = inboxConfig.domains.length === 0;
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      metadata: inboxConfig
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={AI_INTEGRATIONS_MAP[AI_INTEGRATION_TYPES.EMAIL_INBOX]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
      entityType='ai'
    >
      <form className='integration-modal-form'>
        <div>
          <PropertyLabel
            label={
              <>
                Email Domains{' '}
                <InlineTooltip text='To extract a document attached to an email, forward it to documents@feathery.io from an email address within the specified domains. You may only specify domains from your Feathery account emails.' />
              </>
            }
          />
          <Combobox
            value={inboxConfig.domains}
            onChange={(items: string[]) => {
              updateConfig('domains', items);
            }}
            placeholder='Type a domain and press enter...'
          />
        </div>
        <div>
          <PropertyLabel
            label={
              <>
                New Form Submissions{' '}
                <InlineTooltip text='(Optional) Automatically start new form submissions from the extracted document data' />
              </>
            }
          />
          <DropdownMultiField
            selected={inboxConfig.panels ?? []}
            options={Object.values(panels).map((panel: any) => ({
              value: panel.id,
              label: panel.key
            }))}
            onChange={(items: { value: string }[]) =>
              updateConfig(
                'panels',
                items.map((item) => item.value)
              )
            }
            placeholder='Choose forms'
          />
        </div>
        <div>
          <PropertyLabel
            label={
              <>
                Extract Linked Documents{' '}
                <InlineTooltip text='Extract documents that are hyperlinked inside of the email content.' />
              </>
            }
          />
          <Switch
            id='extraction-merge-uploaded'
            checked={inboxConfig.extract_inline_links ?? false}
            onCheckedChange={(checked) => {
              updateConfig('extract_inline_links', checked);
            }}
            style={{ marginLeft: '5px' }}
          />
        </div>
        <CollapsibleSection
          title={
            <>
              Save Email Info
              <InlineTooltip text='Save information from processed emails to hidden fields' />
            </>
          }
          style={{ marginLeft: '-10px' }}
        >
          <div style={{ marginBottom: '10px' }}>
            <PropertyLabel label='Email Subject' />
            <FieldSelectorWithModal
              selectId={inboxConfig.email_subject_field}
              selectType='hidden'
              placeholder='Select'
              onSelect={(data) => {
                updateConfig('email_subject_field', data.selectId);
              }}
              onlyHiddenFields
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <PropertyLabel label='Email Body' />
            <FieldSelectorWithModal
              selectId={inboxConfig.email_content_field}
              selectType='hidden'
              placeholder='Select'
              onSelect={(data) => {
                updateConfig('email_content_field', data.selectId);
              }}
              onlyHiddenFields
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <PropertyLabel label='Sender Name' />
            <FieldSelectorWithModal
              selectId={inboxConfig.email_sender_name}
              selectType='hidden'
              placeholder='Select'
              onSelect={(data) => {
                updateConfig('email_sender_name', data.selectId);
              }}
              onlyHiddenFields
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <PropertyLabel label='Sender Email' />
            <FieldSelectorWithModal
              selectId={inboxConfig.email_sender_email}
              selectType='hidden'
              placeholder='Select'
              onSelect={(data) => {
                updateConfig('email_sender_email', data.selectId);
              }}
              onlyHiddenFields
            />
          </div>
        </CollapsibleSection>
      </form>
    </IntegrationsSidebar>
  );
}

export default InboxSettingsSidebar;
