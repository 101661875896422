import { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import styles from './styles.module.scss';
import WorkspaceNavigation from '../../components/NavBar/WorkspaceNavigation';
import { Positive } from '../../components/Core/Button';
import { TextField } from '../../components/Core';
import useFeatheryRedux from '../../redux';
import { TrashIcon } from '../../components/Icons';
import { capitalizeFirstLetter } from '../../utils/format';
import classNames from 'classnames';

function WorkspaceAccountsPage() {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const {
    inviteToWorkspace,
    toasts: { addInfoToast }
  } = useFeatheryRedux();
  const workspace = useAppSelector(
    (state) => state.accounts.workspaces[workspaceId]
  );
  const [emailToAdd, setEmailToAdd] = useState('');

  return (
    <>
      <WorkspaceNavigation activeItem='accounts' />
      <div className={classNames(styles.workspacePage, styles.padded)}>
        <div className={styles.pageHeadRow}>
          <h1 className='page-head'>Accounts</h1>
        </div>
        {workspace.accounts.length === 0 && (
          <span>No accounts in workspace</span>
        )}
        {workspace.accounts.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Role</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {workspace.accounts.map((account: any) => (
                <tr key={account.email}>
                  <td>{account.email}</td>
                  <td>{capitalizeFirstLetter(account.role)}</td>
                  <td>
                    <TrashIcon
                      className={styles.deleteIcon}
                      onClick={async () => {
                        await inviteToWorkspace({
                          workspaceId: workspace.id,
                          email: account.email,
                          change: 'uninvite'
                        });
                        addInfoToast('Removed User from Workspace');
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className={styles.addUserInput}>
          <TextField
            placeholder='user@company.com'
            value={emailToAdd}
            onChange={(newEmail: string) => setEmailToAdd(newEmail)}
          />
          <Positive
            disabled={!emailToAdd}
            onClick={async () => {
              await inviteToWorkspace({
                email: emailToAdd,
                change: 'invite',
                workspaceId: workspace.id
              });
              addInfoToast('Added User to Workspace');
              setEmailToAdd('');
            }}
          >
            Add User
          </Positive>
        </div>
      </div>
    </>
  );
}

export default memo(WorkspaceAccountsPage);
