import 'react-quill/dist/quill.snow.css';
import '../../../style/dialog-form.css';

import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import IntegrationsSidebar from '../IntegrationsSidebar';
import {
  CheckboxField,
  DropdownField,
  DropdownMultiField,
  InlineTooltip,
  TextField
} from '../../Core';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from '../types';
import ReactQuill from 'react-quill';
import styles from '../styles.module.scss';
import emailStyles from './styles.module.scss';
import useIntegrations from '../useIntegrations';
import useDraftForm from '../../../utils/useDraftForm';
import { useAppSelector } from '../../../hooks';
import useFeatheryRedux from '../../../redux';
import { FieldSelectorWithModal } from '../../Modals';
import { deepEquals } from '../../../utils/core';
import { FILE_FIELDS } from '../../SelectionPanel/elementEntries';
import Label from '../../Dialog/Label';
import NumberInput from '../../Core/NumberInput';
import { Button } from '../../Core/Button/button';

const defaultEmailTemplate = () => ({
  trigger: 'form_completion',
  step: '',
  sender: 'noreply@mail.feathery.io',
  recipient: '',
  subject: '',
  body: '<p><br></p>',
  htmlMode: false,
  attachments: [],
  document_attachments: [],
  document_password: '',
  attach_generated_pdf: false,
  send_to_collaborators: false,
  send_multiple: false,
  id: uuidv4()
});

const STEP_TRIGGERS = ['step_load', 'step_submit'];
const BUSINESS_EMAIL_TRIGGERS = ['form_complete_delay', 'logic_rule'];

interface DocumentOption {
  value: string;
  label: string;
}

export default function EmailSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.EMAIL,
    panelId: formId,
    includeInactive: true
  });
  const {
    toasts: { addErrorToast }
  } = useFeatheryRedux();
  const org = useAppSelector((state) => state.accounts.organization) as any;
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const collabActive =
    org?.enterprise_features.collaboration && panel.collaboration_enabled;

  const [isPartial, setIsPartial] = useState(false);
  const [templates, setTemplates] = useState<any[]>(
    integration?.data.secret_metadata.email_templates ?? [
      defaultEmailTemplate()
    ]
  );

  const { steps } = useDraftForm();
  const stepOptions = useMemo(
    () =>
      Object.values(steps).map((step) => ({
        display: step.key,
        value: step.id
      })),
    [steps]
  );
  const servars = useAppSelector(
    (state) => state.fields.servars ?? [],
    deepEquals
  );
  let fileFieldOptions = useMemo(
    () =>
      servars
        .filter(({ type }) => FILE_FIELDS.includes(type))
        .map(({ key, id }) => ({ label: key, value: id })),
    [servars]
  );
  if (collabActive) {
    fileFieldOptions = [
      {
        label: 'Feathery: Collaborator Audit Log',
        value: 'collaboration_audit_log'
      },
      ...fileFieldOptions
    ];
  }

  const documents = useAppSelector((state) => state.documents.documents);

  const [documentOptions, setDocumentOptions] = useState<DocumentOption[]>([]);
  useEffect(() => {
    const opts = Object.values(documents)
      .map((doc) => ({
        value: doc.id,
        label: doc.key
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setDocumentOptions(opts);
  }, [documents]);

  function onSubmitCustom(newIsActive: boolean) {
    if (!org.hipaaIntegrationWhitelist) {
      addErrorToast({
        title:
          'Reach out to support@feathery.io to verify your HIPAA-compliant email provider'
      });
      return;
    }

    if (
      templates.some(
        (template) =>
          BUSINESS_EMAIL_TRIGGERS.includes(template.trigger) && org.tier !== 4
      )
    ) {
      addErrorToast({
        title:
          'Reach out to sales about advanced email triggers like conditional delays and custom logic rules'
      });
      return;
    }

    if (newIsActive) {
      let partial = false;
      templates.forEach(
        ({
          sender,
          recipient,
          send_to_collaborators,
          subject,
          body,
          trigger,
          field_id
        }) => {
          if (
            !sender ||
            (!recipient &&
              trigger !== 'collaborator_invite' &&
              (!collabActive || !send_to_collaborators)) ||
            !subject ||
            body === '<p><br></p>'
          )
            partial = true;
          else if (trigger === 'field_value_change' && !field_id)
            partial = true;
        }
      );
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      secretMetadata: { email_templates: templates }
    };
  }

  const updateTemplates = (index: number, newData: Record<string, any>) => {
    const newTemplates = JSON.parse(JSON.stringify(templates));
    const curTrigger = newTemplates[index].trigger;
    if (newData.trigger) {
      if (
        STEP_TRIGGERS.includes(newData.trigger) &&
        !STEP_TRIGGERS.includes(curTrigger)
      ) {
        const stepChoices = Object.values(steps);
        // Origin should always exist, but apparently sometimes it can be undefined
        // https://feathery-forms.sentry.io/issues/4364977263
        const choice =
          stepChoices.find((step) => step.origin) ?? stepChoices[0];
        newTemplates[index].step = choice.id;
      } else if (
        newData.trigger === 'field_value_change' &&
        curTrigger !== 'field_value_change'
      ) {
        newTemplates[index].field_id = '';
        newTemplates[index].field_type = '';
        newTemplates[index].field_value = '';
      } else if (
        newData.trigger === 'collaborator_invite' &&
        curTrigger !== 'collaborator_invite'
      ) {
        newTemplates[index].collaborator_id = panel.collaborator_template[0].id;
      } else if (
        newData.trigger === 'form_complete_delay' &&
        curTrigger !== 'form_complete_delay'
      ) {
        newTemplates[index].time_delay_type = 'number';
        newTemplates[index].time_delay = 24 * 60;
        newTemplates[index].time_delay_field = '';
        newTemplates[index].time_delay_field_type = '';
      } else if (
        newData.trigger === 'form_incomplete' &&
        curTrigger !== 'form_incomplete'
      ) {
        newTemplates[index].time_delay = 24 * 60;
      }
    }

    newTemplates[index] = { ...newTemplates[index], ...newData };
    setTemplates(newTemplates);
  };

  const removeTemplate = (index: number) => {
    const newTemplates = JSON.parse(JSON.stringify(templates));
    newTemplates.splice(index, 1);
    if (newTemplates.length === 0) newTemplates.push(defaultEmailTemplate());
    setTemplates(newTemplates);
  };

  const delayComponent = (template: any, index: number) => (
    <>
      <Label className={emailStyles.emailLabel}>Delay Amount (in Days)</Label>
      <NumberInput
        value={parseInt(template.time_delay) / 60 / 24}
        classNames={{
          root: classNames('dialog-form-input', styles.marginBottom)
        }}
        onComplete={({ value: days }: any) => {
          updateTemplates(index, {
            time_delay: days * 60 * 24
          });
        }}
        max={90}
      />
    </>
  );

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.EMAIL]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <form>
        <div
          className={classNames(
            emailStyles.emailHelperText,
            emailStyles.spacing
          )}
        >
          Include a form field value in your email recipient, subject, or body
          by wrapping its field ID in double curly braces {'{{ }}'}. Make sure
          the entire text has the same style.
          <br />
          <br />
          <b>Additional Variables</b>
          <br />
          {'{{Feathery User ID}}'} - Unique ID of the current submission / user
          <br />
          {'{{Feathery Edit Link}}'} - Unique link to the current submission
          <br />
          {'{{FeatheryDocument:<DOCUMENT_ID>}}'} - Link to sign a document
          filled with submission data
        </div>
        {templates.map((template, index) => (
          <div key={template.id} className={emailStyles.emailInputGroup}>
            <Label className={emailStyles.emailLabel}>Send email when</Label>
            <DropdownField
              className={classNames('dialog-form-input', styles.marginBottom)}
              selected={template.trigger}
              onChange={(event: any) =>
                updateTemplates(index, { trigger: event.target.value })
              }
              options={[
                { value: 'form_completion', display: 'the form is completed' },
                {
                  value: 'step_submit',
                  display: 'a step is submitted'
                },
                {
                  value: 'step_load',
                  display: 'a step is loaded'
                },
                {
                  value: 'form_complete_delay',
                  display: 'the form is completed, after a delay'
                },
                {
                  value: 'form_incomplete',
                  display: "the form isn't finished"
                },
                {
                  value: 'field_value_change',
                  display: 'a field value is changed'
                },
                {
                  value: 'logic_rule',
                  display: 'a logic rule is run'
                },
                ...(panel.collaboration_enabled
                  ? [
                      {
                        value: 'collaborator_invite',
                        display: 'a collaborator is invited'
                      }
                    ]
                  : [])
              ]}
            />
            {template.trigger === 'form_incomplete' &&
              delayComponent(template, index)}
            {template.trigger === 'collaborator_invite' && (
              <>
                <div className={emailStyles.emailHelperText}>
                  This email will be sent in place of the default invite email
                  for this collaborator. Include {'{{Collaborator Form Link}}'}{' '}
                  where you would like the autogenerated collaborator form link
                  to show up.
                </div>
                <Label className={emailStyles.emailLabel}>Collaborator</Label>
                <DropdownField
                  className={classNames(
                    'dialog-form-input',
                    styles.marginBottom
                  )}
                  selected={template.collaborator_id}
                  onChange={(event: any) =>
                    updateTemplates(index, {
                      collaborator_id: event.target.value
                    })
                  }
                  options={panel.collaborator_template.map(
                    (collaborator: any) => ({
                      value: collaborator.id,
                      display: collaborator.label
                    })
                  )}
                />
              </>
            )}
            {template.trigger === 'form_complete_delay' && (
              <>
                <Label className={emailStyles.emailLabel}>Delay Type</Label>
                <DropdownField
                  className={classNames(
                    'dialog-form-input',
                    styles.marginBottom
                  )}
                  selected={template.time_delay_type}
                  onChange={(event: any) =>
                    updateTemplates(index, {
                      time_delay_type: event.target.value
                    })
                  }
                  options={[
                    { value: 'number', display: 'Constant Amount' },
                    { value: 'field', display: 'Dynamic Field Value' }
                  ]}
                />
                {template.time_delay_type === 'number' &&
                  delayComponent(template, index)}
                {template.time_delay_type === 'field' && (
                  <>
                    <Label className={emailStyles.emailLabel}>
                      Delay Field{' '}
                      <InlineTooltip text='The value of this field (in minutes) will be used as the amount of time to delay the email post-form completion before sending. If no value or invalid, the email will not be sent. At the moment, the delay must be at least 1 day.' />
                    </Label>
                    <FieldSelectorWithModal
                      selectId={template.time_delay_field}
                      selectType={template.time_delay_field_type}
                      placeholder='Select'
                      onSelect={(data) => {
                        updateTemplates(index, {
                          time_delay_field: data.selectId,
                          time_delay_field_type: data.selectType
                        });
                      }}
                      includeServarTypes={
                        [
                          'integer_field',
                          'slider',
                          'rating',
                          'text_field',
                          'text_area'
                        ] as any
                      }
                      className={styles.marginBottom}
                    />
                  </>
                )}
              </>
            )}
            {template.trigger === 'logic_rule' && (
              <div className={emailStyles.emailHelperText}>
                You can set up a logic rule{' '}
                <Link to={`/forms/${formId}/logic/`}>here</Link>
              </div>
            )}
            {STEP_TRIGGERS.includes(template.trigger) && (
              <>
                <Label className={emailStyles.emailLabel}>Step</Label>
                <DropdownField
                  className={classNames(
                    'dialog-form-input',
                    styles.marginBottom
                  )}
                  selected={template.step}
                  onChange={(event: any) =>
                    updateTemplates(index, { step: event.target.value })
                  }
                  options={stepOptions}
                />
              </>
            )}
            {template.trigger === 'field_value_change' && (
              <>
                <Label className={emailStyles.emailLabel}>Field</Label>
                <FieldSelectorWithModal
                  selectId={template.field_id}
                  selectType={template.field_type}
                  placeholder='Select'
                  onSelect={(data) =>
                    updateTemplates(index, {
                      field_id: data.selectId,
                      field_type: data.selectType
                    })
                  }
                  className={styles.marginBottom}
                  error={isPartial && !template.field_id}
                />
                <Label className={emailStyles.emailLabel}>Matching Value</Label>
                <TextField
                  className={classNames(
                    'dialog-form-input',
                    styles.marginBottom
                  )}
                  value={template.field_value}
                  onChange={(val: string) =>
                    updateTemplates(index, { field_value: val })
                  }
                />
              </>
            )}
            <Label className={emailStyles.emailLabel}>Sender Email</Label>
            <TextField
              className={classNames('dialog-form-input', styles.marginBottom)}
              value={template.sender}
              onChange={(val: string) =>
                updateTemplates(index, { sender: val })
              }
              error={isPartial && !template.sender}
            />
            {org.tier === 4 &&
              ![
                'form_incomplete',
                'form_complete_delay',
                'form_completion'
              ].includes(template.trigger) && (
                <CheckboxField
                  checked={template.send_multiple}
                  text='Allow Multiple Emails Per Submission'
                  onChange={(checked) =>
                    updateTemplates(index, { send_multiple: checked })
                  }
                  style={{ marginTop: '8px', marginLeft: '5px' }}
                />
              )}
            {template.trigger !== 'collaborator_invite' && (
              <>
                <Label className={emailStyles.emailLabel}>
                  Recipient Emails (comma-separated)
                </Label>
                <TextField
                  className={classNames(
                    'dialog-form-input',
                    styles.marginBottom
                  )}
                  value={template.recipient}
                  onChange={(val: string) =>
                    updateTemplates(index, { recipient: val })
                  }
                  error={
                    isPartial &&
                    !template.recipient &&
                    (!collabActive || !template.send_to_collaborators)
                  }
                  placeholder='forms@company.com,{{email-field}}'
                />
              </>
            )}
            {collabActive && template.trigger !== 'collaborator_invite' && (
              <>
                <CheckboxField
                  checked={template.send_to_collaborators}
                  text={`Add ${
                    template.trigger === 'form_incomplete' ? 'Pending ' : ''
                  }Collaborators as Recipients`}
                  onChange={(checked) =>
                    updateTemplates(index, { send_to_collaborators: checked })
                  }
                  style={{ marginTop: '8px', marginLeft: '5px' }}
                />
                {template.send_to_collaborators && (
                  <div
                    className={classNames(
                      emailStyles.emailHelperText,
                      emailStyles.collaborativeVariable
                    )}
                  >
                    {'{{Collaborator Form Link}}'} - Include variable for the
                    recipient&apos;s collaborative submission link
                    <br />
                  </div>
                )}
              </>
            )}
            <Label>Subject</Label>
            <TextField
              className={classNames('dialog-form-input', styles.marginBottom)}
              value={template.subject}
              onChange={(val: string) =>
                updateTemplates(index, { subject: val })
              }
              error={isPartial && !template.subject}
            />
            <Label>Body</Label>
            <CheckboxField
              checked={template.htmlMode}
              text='Insert HTML'
              onChange={(checked) =>
                updateTemplates(index, { htmlMode: checked, body: '' })
              }
              style={{ marginBottom: '5px', marginLeft: '5px' }}
            />
            {template.htmlMode ? (
              <TextField
                style={{ height: '243px' }}
                type='textarea'
                placeholder='<div>...</div>'
                value={template.body}
                onComplete={(val: string) => {
                  if (val === template.body) return;
                  updateTemplates(index, { body: val });
                }}
                rows={11}
              />
            ) : (
              <ReactQuill
                key='rich-text'
                theme='snow'
                value={template.body}
                onChange={(val: any) => {
                  if (val === template.body) return;
                  updateTemplates(index, { body: val });
                }}
              />
            )}
            {isPartial && template.body === '<p><br></p>' && (
              <div className={styles.errorText}>Required</div>
            )}
            <Label>File Field Attachments</Label>
            <DropdownMultiField
              options={fileFieldOptions}
              selected={template.attachments}
              placeholder='Select File Fields'
              onChange={(items: { value: string }[]) => {
                updateTemplates(index, {
                  attachments: items.map((item) => item.value)
                });
              }}
            />
            {documentOptions.length > 0 && (
              <>
                <Label>Document Attachments</Label>
                <DropdownMultiField
                  options={documentOptions}
                  selected={template.document_attachments}
                  placeholder='Select Documents'
                  onChange={(items: { value: string }[]) => {
                    updateTemplates(index, {
                      document_attachments: items.map((item) => item.value)
                    });
                  }}
                />
              </>
            )}
            <CheckboxField
              checked={template.attach_generated_pdf}
              text='Attach PDF Export'
              onChange={(checked) =>
                updateTemplates(index, { attach_generated_pdf: checked })
              }
              style={{ marginTop: '10px', marginLeft: '5px' }}
            />
            {((template.document_attachments ?? []).length > 0 ||
              template['attach_generated_pdf']) && (
              <>
                <Label className={emailStyles.emailLabel}>
                  Encrypt Documents
                  <InlineTooltip text='PDFs included as attachments on this email will be password-protected.' />
                </Label>
                <TextField
                  className={classNames(
                    'dialog-form-input',
                    styles.marginBottom
                  )}
                  value={template.document_password}
                  onChange={(val: string) =>
                    updateTemplates(index, {
                      document_password: val
                    })
                  }
                  placeholder='Password'
                />
              </>
            )}
            <Button
              className='mt-6'
              variant='outline-primary'
              type='button'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                removeTemplate(index);
              }}
            >
              {templates.length > 1 ? 'Remove Email' : 'Clear Email'}
            </Button>
          </div>
        ))}
        <Button
          variant='outline-primary'
          type='button'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (org.tier < 4)
              addErrorToast({
                title: 'Add additional emails on the Business plan'
              });
            else setTemplates([...templates, defaultEmailTemplate()]);
          }}
        >
          Add New Email
        </Button>
      </form>
    </IntegrationsSidebar>
  );
}
