import {
  CollapsibleSection,
  InlineTooltip,
  PropertyLabel,
  SoloImageField,
  TextField
} from '../../Core';
import { memo } from 'react';
import {
  assetUpdateOperations,
  elementOperation,
  getAsset
} from '../../../utils/themes';

import { HideIfsSection } from './components';
import { calculateOverrideObjects } from '../utils';
import { objectPick } from '../../../utils/core';
import { TYPE_IMAGE } from '../../../utils/elements';
import FieldSelectorWithModal from '../../Modals/FieldSelectorWithModal';
import { useAppSelector } from '../../../hooks';
import { IMAGE_FIELDS } from '../../SelectionPanel/elementEntries';
import LogicSection from '../../FormFieldEditors/DefaultEditor/LogicSection';
import Col from '../../Core/Layout/Col';
import Row from '../../Core/Layout/Row';

function ImagePropertiesPanel({
  mode,
  viewport,
  theme,
  baseProps,
  overrideProps,
  handleUpdates
}: any) {
  const activeStepId: string = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );

  const { result: element, isOverride: isOverrideProp } =
    calculateOverrideObjects(baseProps, overrideProps, true);
  const asset = getAsset(theme, TYPE_IMAGE, element.source_asset);

  function handlePropChange(propUpdate: any) {
    handleUpdates([elementOperation({ viewport, propUpdate })]);
  }

  function labelData(...propList: string[]) {
    return {
      mode,
      highlighted: isOverrideProp({ properties: propList }),
      themeOperations: {
        instance: {
          label: 'Reset to asset value',
          operation: () => {
            handleUpdates([
              elementOperation({ viewport, propReset: propList })
            ]);
          }
        },
        ...(asset && {
          asset: {
            label: asset.key,
            operation: () => {
              handleUpdates(
                assetUpdateOperations({
                  viewport,
                  elementType: TYPE_IMAGE,
                  asset,
                  newProps: objectPick(element.properties, propList),
                  newElementProps: objectPick(element, propList)
                })
              );
            }
          }
        })
      }
    };
  }

  return (
    <>
      {(mode === 'builder' || asset) && (
        <CollapsibleSection collapsible={false}>
          <Row>
            <Col sm='3'>
              <PropertyLabel
                centered={false}
                label='Image'
                {...labelData('source_image')}
              />
            </Col>
            <Col>
              <SoloImageField
                imageId={element.source_image}
                // @ts-expect-error TS(2322) FIXME: Type '(newImage: any) => void' is not assignable t... Remove this comment to see the full error message
                setImage={(newImage: any) =>
                  handlePropChange({
                    properties: { source_image: newImage?.file || '' },
                    source_image: newImage?.id || ''
                  })
                }
                fileTypes={['image/*']}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='5'>
              <PropertyLabel
                label={
                  <>
                    Show Image from Field
                    <InlineTooltip text="The image will be rendered from the field value, whether it's an uploaded image or URL" />
                  </>
                }
                {...labelData(
                  'uploaded_image_file_field',
                  'uploaded_image_file_field_type'
                )}
              />
            </Col>
            <Col>
              <FieldSelectorWithModal
                selectId={element.properties.uploaded_image_file_field}
                selectType={element.properties.uploaded_image_file_field_type}
                onSelect={(data: any) => {
                  handlePropChange({
                    properties: {
                      uploaded_image_file_field: data.selectId,
                      uploaded_image_file_field_type: data.selectType
                    }
                  });
                }}
                currentStepId={activeStepId}
                inFormBuilder
                includeServarTypes={IMAGE_FIELDS as any}
              />
            </Col>
          </Row>
        </CollapsibleSection>
      )}
      {mode === 'builder' && (
        <>
          <LogicSection elementId={element.id} />
          <HideIfsSection
            element={element}
            hideIfs={element.hide_ifs}
            showLogic={element.show_logic}
            handleUpdates={handleUpdates}
          />
        </>
      )}
      <CollapsibleSection title='Accessibility' expanded={false}>
        <Row>
          <Col sm='4'>
            <PropertyLabel label='Aria Label' {...labelData('aria_label')} />
          </Col>
          <Col>
            <TextField
              value={element.properties.aria_label ?? ''}
              onComplete={(label: any) =>
                handlePropChange({
                  properties: {
                    aria_label: label
                  }
                })
              }
              triggerCleanUp
            />
          </Col>
        </Row>
      </CollapsibleSection>
    </>
  );
}

export default memo(ImagePropertiesPanel);
