import { useMemo } from 'react';
import {
  DropdownField,
  InlineTooltip,
  YesNoSwitch
} from '../../../components/Core';
import useDraftForm from '../../../utils/useDraftForm';
import SettingsContainer from '../../../components/SettingsContainer';

const FormFillPluginSection = ({ title, formData, handleValueChange }: any) => {
  const { steps: _steps } = useDraftForm();

  const steps = Object.values(_steps);
  const servarFieldOptions = useMemo(() => {
    const servarFields: Record<string, string> = {};
    steps.forEach((step: any) =>
      step.servar_fields.forEach((field: any) => {
        if (!field.servar.repeated) {
          servarFields[field.servar.id] = field.servar.key;
        }
      })
    );

    return Object.entries(servarFields).map(([servarId, servarKey]) => ({
      display: servarKey,
      value: servarId
    }));
  }, [steps]);

  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Enable Chrome Plugin
            <InlineTooltip text='If enabled, the form can be used to autofill web forms via the Feathery chrome plugin' />
          </p>
          <YesNoSwitch
            id='enable-chrome-extension'
            checked={formData.plugin_autofill ?? false}
            onCheckedChange={(value) =>
              handleValueChange('plugin_autofill')(value)
            }
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Search Field
            {formData.plugin_autofill && (
              <InlineTooltip text='Select the field to filter form submissions in the chrome extension.' />
            )}
          </p>
          <DropdownField
            disabled={!formData.plugin_autofill}
            selected={
              formData.plugin_autofill ? formData.plugin_lookup_field ?? '' : ''
            }
            options={servarFieldOptions}
            onChange={(e: any) => {
              handleValueChange('plugin_lookup_field')(e.target.value);
            }}
          />
        </label>
      </div>
    </SettingsContainer>
  );
};

export default FormFillPluginSection;
