import { memo, useCallback, useState } from 'react';

import Dialog from '../../Dialog';
import { Positive } from '../../Core/Button';
import { DropdownField, TextField } from '../../Core';
import styles from './styles.module.scss';
import modalStyles from '../styles.module.scss';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import { useParams } from 'react-router-dom';
import useFeatheryRedux from '../../../redux';
import classNames from 'classnames';
import { openTab } from '../../../utils/domOperations';
import { getSignUrl } from '../../../utils/documents';
import { useAppSelector } from '../../../hooks';

function SignDocumentModal({ show, close }: any) {
  const { documentId } = useParams<{ documentId: string }>();
  const doc = useAppSelector((state) => state.documents.documents[documentId]);
  const isDocx = doc?.type === 'docx';
  const [inviteMessage, setInviteMessage] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteMethod, setInviteMethod] = useState('direct');

  const {
    createEnvelope,
    toasts: { addInfoToast }
  } = useFeatheryRedux();

  const submit = useCallback(async () => {
    if (isDocx) {
      addInfoToast(
        'Word Documents cannot be signed directly. They must be triggered from a form submission.'
      );
    }
    createEnvelope({
      documentId,
      signer: inviteEmail,
      invite_method: inviteMethod,
      invite_message: inviteMessage
    })
      .then((res: any) => {
        if (inviteMethod === 'direct') {
          openTab(getSignUrl(res));
        } else {
          addInfoToast('Email invite sent to signer');
        }
      })
      .catch(() => {
        addInfoToast('Document creation failure.');
      })
      .finally(() => {
        close();
      });
  }, [inviteEmail, inviteMessage, inviteMethod, isDocx]);

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  return (
    <Dialog isOpen={show} title='Sign Document' size='sm' onClose={close}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        <div className={modalStyles.inviteTeamEmailRow}>
          <div className={modalStyles.inviteLabelWide}>Who is signing?</div>
          <DropdownField
            className={classNames('dialog-form-input', styles.marginBottom)}
            selected={inviteMethod}
            onChange={(event: any) => setInviteMethod(event.target.value)}
            options={[
              { value: 'direct', display: 'You' },
              { value: 'email', display: 'Someone Else' }
            ]}
          />
        </div>
        {inviteMethod === 'email' && (
          <>
            <div className={modalStyles.inviteTeamEmailRow}>
              <div className={modalStyles.inviteLabelWide}>Signer email</div>
              <TextField
                placeholder='user@email.com'
                value={inviteEmail}
                onChange={setInviteEmail}
                type='email'
                required
              />
            </div>
            <div className={modalStyles.inviteTeamEmailRow}>
              <div className={modalStyles.inviteLabelWide}>Invite Message</div>
              <TextField
                type='textarea'
                rows={10}
                placeholder="I'm inviting you to sign this document"
                value={inviteMessage}
                onChange={(val: string) => {
                  setInviteMessage(val);
                }}
              />
            </div>
          </>
        )}
        <div className='dialog-form-action text-center'>
          <Positive lockoutOverride={lockOutFlag}>
            {inviteMethod === 'direct' ? 'Sign' : 'Invite'}
          </Positive>
        </div>
      </form>
    </Dialog>
  );
}

export default memo(SignDocumentModal);
