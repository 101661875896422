import { EMoney, Orion, Redtail, Salesforce, Temenos } from '../../../Logos';
import { CUSTOM_TEMPLATE } from './custom';
import {
  SALESFORCE_CREATE,
  SALESFORCE_PREFILL,
  SALESFORCE_UPDATE
} from './salesforce';
import { ORION_CREATE, ORION_GET_NET_WORTH } from './orion';
import type { App, Template } from './types';
import { REDTAIL_GET_CONTACT } from './redtail';
import { TEMENOS_CREATE_LOAN } from './temenos';
import { EMONEY_GET_CLIENTS } from './emoney';

export const TEMPLATES: Record<string, Template> = {
  salesforce_prefill: SALESFORCE_PREFILL,
  salesforce_create: SALESFORCE_CREATE,
  salesforce_update: SALESFORCE_UPDATE,
  orion_create: ORION_CREATE,
  orion_get_net_worth: ORION_GET_NET_WORTH,
  redtail_get_contact: REDTAIL_GET_CONTACT,
  temenos_create_loan: TEMENOS_CREATE_LOAN,
  emoney_get_clients: EMONEY_GET_CLIENTS
};

export const APPS: Record<string, App> = {
  custom: {
    header: 'Custom Connector',
    logo: null,
    actions: [CUSTOM_TEMPLATE]
  },
  salesforce: {
    header: 'Salesforce',
    logo: <Salesforce height={45} width={65} />,
    actions: [SALESFORCE_PREFILL, SALESFORCE_CREATE, SALESFORCE_UPDATE],
    type: 'rollout',
    premiumIntegration: 'salesforce'
  },
  orion: {
    header: 'Orion',
    logo: <Orion height={40} width={40} />,
    actions: [ORION_CREATE, ORION_GET_NET_WORTH],
    premiumIntegration: 'orion'
  },
  redtail: {
    header: 'Redtail',
    logo: <Redtail height={45} width={65} />,
    actions: [REDTAIL_GET_CONTACT],
    premiumIntegration: 'redtail'
  },
  emoney: {
    header: 'EMoney',
    logo: <EMoney height={45} width={65} />,
    actions: [EMONEY_GET_CLIENTS],
    premiumIntegration: 'emoney'
  },
  temenos: {
    header: 'Temenos',
    logo: <Temenos height={45} width={65} />,
    actions: [TEMENOS_CREATE_LOAN],
    premiumIntegration: 'temenos'
  }
};
