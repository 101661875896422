import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { DropdownField, TextField } from '../../../../../Core';
import styles from '../../styles.module.scss';
import useFeatheryRedux from '../../../../../../redux';
import { Spinner } from '../../../../../Core/Spinner';
import { Button } from '../../../../../Core/Button/button';
import { JSONEditor } from '../../../../../JSONEditor';

const useSalesforceObjects = (credential: any) => {
  const [objects, setObjects] = useState<
    { label: string; name: string; updateable: boolean; createable: boolean }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { fetchSalesforceObjects } = useFeatheryRedux();
  useEffect(() => {
    const fetchObjects = async () => {
      setError(null);
      setLoading(true);
      setObjects([]);
      try {
        const data = await fetchSalesforceObjects({
          salesforce_instance_url: credential.data.userCustomDomainUrl,
          salesforce_access_token: credential.data.accessToken
        });
        if (!data?.sobjects) {
          throw new Error('No sobjects returned');
        }

        const updateable_objects = data.sobjects.filter(
          (obj: any) => obj.createable || obj.updateable
        );
        setObjects(updateable_objects);
        setLoading(false);
      } catch (err: any) {
        console.error(err.message);
        setError('Failed to load Salesforce objects');
        setLoading(false);
      }
    };

    if (credential) {
      fetchObjects();
    }
  }, [credential]);

  return { objects, loading, error };
};

export const SalesforceObjectPicker = ({
  credential,
  onChange,
  selected,
  error: fieldError,
  errorMessage
}: any) => {
  const { objects, loading, error } = useSalesforceObjects(credential);
  const dropdownOptions = useMemo(() => {
    return (
      objects
        // map to dropdown option structure
        .map((obj: any) => ({
          display: obj.label,
          value: obj.name
        }))
        // sort alphabetically by label
        .sort((objectA, objectB) => {
          return objectA.display.localeCompare(objectB.display);
        })
    );
  }, [objects]);

  if (loading) return <Spinner role='status' className='block text-primary' />;
  if (error)
    return <div className='text-[var(--red-border)]'>Error: {error}</div>;

  return (
    <DropdownField
      options={dropdownOptions}
      selected={selected}
      onChange={onChange}
      placeholder='Select Salesforce Object'
      error={fieldError}
      errorMessage={errorMessage}
    />
  );
};

const useSalesforceFields = (credential: any, object_name: any) => {
  const [fields, setFields] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { fetchSalesforceObjectFields } = useFeatheryRedux();

  useEffect(() => {
    const fetchFields = async () => {
      setError(null);
      setLoading(true);
      setFields([]);
      try {
        const data = await fetchSalesforceObjectFields({
          salesforce_instance_url: credential.data.userCustomDomainUrl,
          salesforce_access_token: credential.data.accessToken,
          object_name: object_name
        });
        if (!data?.fields) {
          throw new Error('No fields returned');
        }

        const createable_fields = data.fields.filter(
          (obj: any) => obj.createable
        );
        setFields(createable_fields);
        setLoading(false);
      } catch (err: any) {
        console.error(err.message);
        setError('Failed to load Salesforce fields');
        setLoading(false);
      }
    };

    if (credential && object_name) {
      fetchFields();
    }
  }, [credential, object_name]);

  return { fields, loading, error };
};

const isFieldRequired = (field: any) => {
  return !field.nillable && !field.defaultedOnCreate;
};

export const SalesforceFieldList = forwardRef(function SalesforceFieldList(
  {
    credential,
    object_name,
    value: fieldValues = {},
    onChange,
    error: fieldError,
    errorMessage,
    create = false
  }: any,
  ref
) {
  const { fields, loading, error } = useSalesforceFields(
    credential,
    object_name
  );
  const [isBulkMode, setIsBulkMode] = useState(false);
  const [bulkValidationErrors, setBulkValidationErrors] = useState<string[]>(
    []
  );

  useImperativeHandle(ref, () => ({
    getInvalidFields(field_values: Record<string, string>) {
      if (!fields || !fields.length) {
        return false;
      }
      if (!create) {
        return [];
      }
      return fields
        .filter((field) => isFieldRequired(field) && !field_values[field.name])
        .map((field) => field.name);
    }
  }));

  const validateBulkJson = (
    jsonString: string
  ): {
    isValid: boolean;
    errors: string[];
    mappings: Record<string, string>;
  } => {
    const errors: string[] = [];
    let parsedJson: Record<string, string>;
    const existingFieldNames = new Set(fields.map((f) => f.name));

    try {
      parsedJson = JSON.parse(jsonString);

      if (typeof parsedJson !== 'object' || Array.isArray(parsedJson)) {
        errors.push('Input must be a JSON object');
        return { isValid: false, errors, mappings: {} };
      }

      Object.keys(parsedJson).forEach((fieldName) => {
        if (!existingFieldNames.has(fieldName)) {
          errors.push(`Unknown field: ${fieldName}`);
        }
      });

      Object.entries(parsedJson).forEach(([fieldName, value]) => {
        if (value !== null && typeof value !== 'string') {
          errors.push(`Value for ${fieldName} must be a string.`);
        }
      });
    } catch (e) {
      errors.push('Invalid JSON format');
      return { isValid: false, errors, mappings: {} };
    }

    return {
      isValid: errors.length === 0,
      errors,
      mappings: errors.length === 0 ? parsedJson : {}
    };
  };

  const bulkText = useMemo(() => {
    if (!fields) return '{}';

    const initialMapping = fields.reduce((acc, field) => {
      acc[field.name] = fieldValues[field.name] || '';
      return acc;
    }, {} as Record<string, string>);

    return JSON.stringify(initialMapping, null, 2);
  }, [fieldValues, fields]);

  const handleIndividualChange = (name: string, value: string) => {
    onChange({
      ...fieldValues,
      [name]: value
    });
  };

  const handleBulkEditorChange = (code?: string) => {
    if (!code) {
      setBulkValidationErrors([]);
      onChange({});
      return;
    }

    const { errors, mappings, isValid } = validateBulkJson(code);
    setBulkValidationErrors(errors);

    if (isValid) {
      const nonEmptyMappings = Object.entries(mappings).reduce(
        (acc, [key, value]) => {
          if (value !== null && value !== '') {
            acc[key] = value;
          }
          return acc;
        },
        {} as Record<string, string>
      );

      onChange(nonEmptyMappings);
    }
  };

  if (loading) return <Spinner role='status' className='block text-primary' />;
  if (error)
    return <div className='text-[var(--red-border)]'>Error: {error}</div>;

  return (
    <div className='space-y-4'>
      <div className='flex justify-end items-center -mt-10'>
        <Button
          onClick={() => {
            setIsBulkMode(!isBulkMode);
            setBulkValidationErrors([]);
          }}
          variant='gray'
          size='sm'
        >
          {isBulkMode ? 'Switch to Individual Edit' : 'Switch to Bulk Edit'}
        </Button>
      </div>

      {isBulkMode ? (
        <div className='space-y-2'>
          <div className='text-sm text-gray-600 mb-2'>
            Enter field mappings as a JSON object. Field IDs need to be wrapped
            in double curly braces.
          </div>
          <JSONEditor
            height='300px'
            code={bulkText}
            placeholder="// Enter field mappings as JSON:
{
  'fieldName': 'value'
}"
            onChange={handleBulkEditorChange}
          />
          {bulkValidationErrors.length > 0 && (
            <div className='flex items-center p-4 mb-4 bg-red-50 border border-red-200 rounded-lg'>
              <div className='flex-1 text-sm font-medium text-red-800'>
                Validation Errors:
                <ul className='list-disc pl-4 mt-2'>
                  {bulkValidationErrors.map((error, idx) => (
                    <li key={idx}>{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='space-y-4'>
          {fields.map((field) => (
            <SalesforceField
              key={field.name}
              field={field}
              value={fieldValues[field.name] ?? ''}
              onChange={handleIndividualChange}
              error={fieldError && errorMessage[field.name]}
              errorMessage={
                errorMessage && typeof errorMessage === 'object'
                  ? errorMessage[field.name]
                  : undefined
              }
              showRequired={create}
            />
          ))}
        </div>
      )}
      {errorMessage && typeof errorMessage === 'string' && (
        <div className='text-red-500 mt-2'>{errorMessage}</div>
      )}
    </div>
  );
});

const SalesforceField = ({
  field,
  value,
  onChange,
  error,
  errorMessage,
  showRequired = false
}: any) => {
  const required = showRequired && isFieldRequired(field);
  const isReference = field.referenceTo.length;
  return (
    <div className={styles.field}>
      <div className={styles.label}>
        {isReference ? `${field.referenceTo[0]} Reference` : field.label}
        {required && <span className='text-red-600'> *</span>}
        <span className='text-gray-600'>({field.name})</span>
      </div>
      <TextField
        value={value}
        onChange={(val: string) => {
          onChange(field.name, val);
        }}
        error={error}
        errorMessage={errorMessage}
      />
    </div>
  );
};
