import { memo, useMemo, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { Cell } from '../../RenderingEngine/GridInGrid/engine';
import { InlineTooltip, PropertyLabel, TextField } from '../../Core';
import { getGigUndoRedoPayload } from '../../../utils/step';
import useFeatheryRedux from '../../../redux';
import Row from '../../Core/Layout/Row';
import Col from '../../Core/Layout/Col';

type KeyFieldProps = {
  cellNode: Cell;
  maxLength?: number;
};

const KeyField = ({ cellNode, maxLength = 128 }: KeyFieldProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const workingSteps = useAppSelector((s: any) => s.formBuilder.workingSteps);
  const activeStepId = useAppSelector((s: any) => s.formBuilder.activeStepId);

  const {
    formBuilder: { setPanelDataWithUndoRedo }
  } = useFeatheryRedux();

  // Create a hash map of existing keys in all working steps
  const existingKeys = useMemo(() => {
    const steps = Object.values(workingSteps) as any[];

    return steps.reduce((allKeys: string[], { subgrids }: any) => {
      return {
        ...allKeys,
        ...subgrids.reduce((allStepKeys: string[], { key }: any) => {
          return {
            ...allStepKeys,
            [key]: true
          };
        }, {})
      };
    }, {});
  }, [workingSteps]);

  const validateKey = (key: string) => {
    if (key === cellNode.key) {
      setErrorMessage('');
      return true;
    }

    // Check uniqueness of the key
    if (existingKeys[key]) {
      setErrorMessage(`ID '${key}' already exists in this form`);
      return false;
    }

    // Check for empty key
    if (!key) {
      setErrorMessage('ID cannot be empty');
      return false;
    }

    // Check for length
    if (key.length > maxLength) {
      setErrorMessage(`ID cannot exceed ${maxLength} characters`);
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const handleChange = (val: any) => {
    if (!validateKey(val)) return; // Check for errors upon the input changing
  };

  const handleComplete = (val: any) => {
    if (!validateKey(val)) return;
    if (val === cellNode.key) return; // Avoid unnecessary updates

    cellNode.key = val; // Set the container's key to the new value

    // Remove the callback_id after updating the key (because we're deprecating it)
    // TODO: Eventually we'll phase out the callback_id and remove this
    const cellProperties = cellNode.getProperties();
    if (cellProperties.callback_id) {
      delete cellProperties.callback_id;
      cellNode.setProperties(cellProperties);
    }

    const nextWorkingSteps = JSON.parse(JSON.stringify(workingSteps));
    nextWorkingSteps[activeStepId] = cellNode.getGig()?.toStep();

    const undoRedoPayload = getGigUndoRedoPayload({
      stepId: activeStepId,
      oldSteps: workingSteps,
      newSteps: nextWorkingSteps,
      changedStepKeys: [activeStepId]
    });

    setPanelDataWithUndoRedo(undoRedoPayload);
  };

  return (
    <Row>
      <Col sm='3'>
        <PropertyLabel
          label={
            <>
              ID
              <InlineTooltip
                placement='bottom'
                text={
                  <>
                    The ID used to identify this container in form interactions
                    (click action callbacks, locating custom components, etc.)
                    <br />
                    <br />
                    Check out docs.feathery.io for more details.
                  </>
                }
              />
            </>
          }
        />
      </Col>
      <Col>
        <TextField
          key={`${cellNode?.uuid}-text-field`}
          placeholder='Container ID'
          value={cellNode.key ?? ''}
          onChange={handleChange}
          onComplete={handleComplete}
          error={!!errorMessage}
          errorMessage={errorMessage}
          triggerCleanUp
        />
      </Col>
    </Row>
  );
};

export default memo(KeyField);
