import '../../style/dialog-form.css';

import { Fragment, useState } from 'react';
import IntegrationsSidebar from './IntegrationsSidebar';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { InlineTooltip, TextField } from '../Core';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FieldSelectorWithModal } from '../Modals';
import { PlusIcon, TrashIcon } from '../Icons';
import useIntegrations from './useIntegrations';
import useReceiveOauthMessage from './shared/useReceiveOauthMessage';
import { useParams } from 'react-router-dom';

const oauthRedirect = `https://account.box.com/api/oauth2/authorize?response_type=code&client_id=h4ikc28vtkwcud3jelw4zl8hf4kgu07d&redirect_uri=${location.origin}/oauth-redirect/`;

const DEFAULT_FILE_CONNECTIONS = [
  {
    field_id: '',
    field_type: '',
    folder_id: '',
    name_field_id: '',
    name_field_type: ''
  }
];

function BoxSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.BOX,
    panelId: formId,
    includeInactive: true
  });

  // Local/draft integration settings
  const [error, setError] = useState('');
  const [isPartial, setIsPartial] = useState(false);
  const meta = integration?.data.secret_metadata ?? {};
  const [fileConnections, setFileConnections] = useState(
    meta.file_connections?.length
      ? meta.file_connections
      : DEFAULT_FILE_CONNECTIONS
  );
  const oauthToken: string | undefined = meta.oauth_token;

  function onSubmitCustom(newIsActive: boolean) {
    setError('');
    // Filter out completely empty custom property rows
    const filtered = fileConnections.filter(
      (fc: any) => fc.folder_id || fc.field_id
    );

    if (newIsActive) {
      let partial = false;
      filtered.forEach(({ folder_id, field_id }: any) => {
        if (!folder_id || !field_id) partial = true;
      });
      setIsPartial(partial);
      if (partial) {
        setError(
          'Folder connections must have both the field and folder ID specified'
        );
        return;
      }
    }

    if (!oauthToken) {
      setError('You must authorize your Box account');
    } else if (filtered.length === 0) {
      setError('You must specify at least one Box file connection');
    } else {
      return {
        isUpdate: integration?.data,
        apiKey: '',
        secretMetadata: {
          file_connections: filtered
        }
      };
    }
  }

  const authorizeBox = useReceiveOauthMessage(
    INTEGRATIONS.BOX,
    oauthRedirect,
    !!oauthToken
  );

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.BOX]}
      authorizeData={{
        authorize: authorizeBox,
        oauthToken
      }}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={error}
    >
      <form>
        <div className={styles.productContainer}>
          <div className={styles.fieldHeaderText}>
            Box Folder{' '}
            <InlineTooltip text='The folder ID of the Box folder to upload files to' />
          </div>
          <div className={styles.fieldHeaderText}>
            File Field{' '}
            <InlineTooltip text='The field receiving files to upload to the Box folder' />
          </div>
          <div className={styles.fieldHeaderText}>
            Name Field{' '}
            <InlineTooltip text='(Optional) A field containing a name to rename the uploaded Box files to' />
          </div>
          {fileConnections.map((connection: any, index: any) => (
            <Fragment key={index}>
              <TextField
                placeholder='Box Folder ID'
                className='dialog-form-input'
                value={connection.folder_id}
                onComplete={(newFolderId: any) => {
                  const newConnections = [...fileConnections];
                  newConnections[index] = {
                    ...connection,
                    folder_id: newFolderId
                  };
                  setFileConnections(newConnections);
                }}
                error={isPartial && !connection.folder_id}
              />
              <FieldSelectorWithModal
                selectId={connection.field_id}
                selectType={connection.field_type}
                placeholder='Select'
                onSelect={(data) => {
                  const newConnections = [...fileConnections];
                  newConnections[index] = {
                    ...connection,
                    field_id: data.selectId,
                    field_type: data.selectType
                  };
                  setFileConnections(newConnections);
                }}
                error={isPartial && !connection.field_id}
                className={classNames(
                  styles.marginBottom,
                  styles.fieldSelector
                )}
                includeServarTypes={['file_upload']}
              />
              <div style={{ display: 'flex', gap: '10px' }}>
                <FieldSelectorWithModal
                  selectId={connection.name_field_id}
                  selectType={connection.name_field_type}
                  placeholder='Select'
                  onSelect={(data) => {
                    const newConnections = [...fileConnections];
                    newConnections[index] = {
                      ...connection,
                      name_field_id: data.selectId,
                      name_field_type: data.selectType
                    };
                    setFileConnections(newConnections);
                  }}
                  error={isPartial && !connection.name_field_id}
                  className={classNames(
                    styles.marginBottom,
                    styles.fieldSelector
                  )}
                  excludeServarTypes={['file_upload', 'signature'] as any}
                />
                <TrashIcon
                  height={16}
                  width={16}
                  className={classNames('tr-icon', styles.customPropertyDelete)}
                  onClick={() => {
                    const newConnections = [...fileConnections];
                    newConnections.splice(index, 1);
                    setFileConnections(newConnections);
                  }}
                />
              </div>
            </Fragment>
          ))}
        </div>
        <PlusIcon
          className={styles.customPropertyAdd}
          onClick={() => {
            const newFields = [...fileConnections];
            newFields.push(DEFAULT_FILE_CONNECTIONS[0]);
            setFileConnections(newFields);
          }}
        />
      </form>
    </IntegrationsSidebar>
  );
}

export default BoxSettingsSidebar;
