import { OPERATOR_CODE } from '@feathery/react';

export interface OperatorMeta {
  code: OPERATOR_CODE;
  infix: boolean;
  multiValue: boolean;
  display: string;
  modifier?: 'other_only';
  noValues?: boolean;
}
type FieldOperatorRecords = Record<OPERATOR_CODE, OperatorMeta>;
const FIELD_OPERATORS: FieldOperatorRecords = {
  equal: {
    code: 'equal',
    infix: true,
    multiValue: true,
    display: 'is'
  },
  not_equal: {
    code: 'not_equal',
    infix: true,
    multiValue: true,
    display: "isn't"
  },
  equal_ignore_case: {
    code: 'equal_ignore_case',
    infix: true,
    multiValue: true,
    display: 'is (ignore case)'
  },
  not_equal_ignore_case: {
    code: 'not_equal_ignore_case',
    infix: true,
    multiValue: true,
    display: "isn't (ignore case)"
  },
  greater_than: {
    code: 'greater_than',
    infix: true,
    multiValue: false,
    display: 'is greater than'
  },
  greater_than_or_equal: {
    code: 'greater_than_or_equal',
    infix: true,
    multiValue: false,
    display: 'is greater than or equal'
  },
  less_than: {
    code: 'less_than',
    infix: true,
    multiValue: false,
    display: 'is less than'
  },
  less_than_or_equal: {
    code: 'less_than_or_equal',
    infix: true,
    multiValue: false,
    display: 'is less than or equal'
  },
  is_filled: {
    code: 'is_filled',
    infix: false,
    multiValue: false,
    display: 'is filled',
    noValues: true
  },
  is_empty: {
    code: 'is_empty',
    infix: false,
    multiValue: false,
    display: 'is empty',
    noValues: true
  },
  is_true: {
    code: 'is_true',
    infix: false,
    multiValue: false,
    display: 'is true',
    noValues: true
  },
  is_false: {
    code: 'is_false',
    infix: false,
    multiValue: false,
    display: 'is false',
    noValues: true
  },
  contains: {
    code: 'contains',
    infix: true,
    multiValue: true,
    display: 'contains'
  },
  not_contains: {
    code: 'not_contains',
    infix: true,
    multiValue: true,
    display: "doesn't contain"
  },
  contains_ignore_case: {
    code: 'contains_ignore_case',
    infix: true,
    multiValue: true,
    display: 'contains (ignore case)'
  },
  not_contains_ignore_case: {
    code: 'not_contains_ignore_case',
    infix: true,
    multiValue: true,
    display: "doesn't contain (ignore case)"
  },
  starts_with: {
    code: 'starts_with',
    infix: true,
    multiValue: true,
    display: 'starts with'
  },
  not_starts_with: {
    code: 'not_starts_with',
    infix: true,
    multiValue: true,
    display: "doesn't start with"
  },
  ends_with: {
    code: 'ends_with',
    infix: true,
    multiValue: true,
    display: 'ends with'
  },
  not_ends_with: {
    code: 'not_ends_with',
    infix: true,
    multiValue: true,
    display: "doesn't end with"
  },
  is_numerical: {
    code: 'is_numerical',
    infix: false,
    multiValue: false,
    display: 'is numerical',
    noValues: true
  },
  is_text: {
    code: 'is_text',
    infix: false,
    multiValue: false,
    display: 'is text',
    noValues: true
  },
  selections_include: {
    code: 'selections_include',
    infix: true,
    multiValue: true,
    display: 'selections include'
  },
  selections_dont_include: {
    code: 'selections_dont_include',
    infix: true,
    multiValue: true,
    display: "selections don't include"
  }
};

const EQUAL_OPERATORS: Partial<FieldOperatorRecords> = {
  [FIELD_OPERATORS.equal.code]: FIELD_OPERATORS.equal,
  [FIELD_OPERATORS.not_equal.code]: FIELD_OPERATORS.not_equal,
  [FIELD_OPERATORS.equal_ignore_case.code]: FIELD_OPERATORS.equal_ignore_case,
  [FIELD_OPERATORS.not_equal_ignore_case.code]:
    FIELD_OPERATORS.not_equal_ignore_case
};

const STRING_OPERATORS: Partial<FieldOperatorRecords> = {
  [FIELD_OPERATORS.contains.code]: FIELD_OPERATORS.contains,
  [FIELD_OPERATORS.not_contains.code]: FIELD_OPERATORS.not_contains,
  [FIELD_OPERATORS.contains_ignore_case.code]:
    FIELD_OPERATORS.contains_ignore_case,
  [FIELD_OPERATORS.not_contains_ignore_case.code]:
    FIELD_OPERATORS.not_contains_ignore_case,
  [FIELD_OPERATORS.starts_with.code]: FIELD_OPERATORS.starts_with,
  [FIELD_OPERATORS.not_starts_with.code]: FIELD_OPERATORS.not_starts_with,
  [FIELD_OPERATORS.ends_with.code]: FIELD_OPERATORS.ends_with,
  [FIELD_OPERATORS.not_ends_with.code]: FIELD_OPERATORS.not_ends_with
};

const SELECTION_OPERATORS: Partial<FieldOperatorRecords> = {
  [FIELD_OPERATORS.selections_include.code]: FIELD_OPERATORS.selections_include,
  [FIELD_OPERATORS.selections_dont_include.code]:
    FIELD_OPERATORS.selections_dont_include
};

const TEXT_OPERATORS: Partial<FieldOperatorRecords> = {
  ...EQUAL_OPERATORS,
  ...STRING_OPERATORS
};
const FILLED_EMPTY_OPERATIONS: Partial<FieldOperatorRecords> = {
  [FIELD_OPERATORS.is_filled.code]: FIELD_OPERATORS.is_filled,
  [FIELD_OPERATORS.is_empty.code]: FIELD_OPERATORS.is_empty
};
const UNVERSALLY_SUPPORTED_OPERATORS = FILLED_EMPTY_OPERATIONS;

const COMMON_TEXT_OPERATIONS: Partial<FieldOperatorRecords> = {
  ...TEXT_OPERATORS,
  ...FILLED_EMPTY_OPERATIONS,
  [FIELD_OPERATORS.is_numerical.code]: FIELD_OPERATORS.is_numerical,
  [FIELD_OPERATORS.is_text.code]: FIELD_OPERATORS.is_text
};

const FIELD_OPERATOR_MAP: {
  [fieldType: string]: Partial<FieldOperatorRecords>;
} = {
  text_field: COMMON_TEXT_OPERATIONS,
  text_area: COMMON_TEXT_OPERATIONS,
  dropdown: { ...TEXT_OPERATORS, ...FILLED_EMPTY_OPERATIONS },
  dropdown_multi: {
    ...SELECTION_OPERATORS,
    ...EQUAL_OPERATORS,
    ...STRING_OPERATORS,
    [FIELD_OPERATORS.is_filled.code]: {
      ...FIELD_OPERATORS.is_filled,
      display: 'is selected'
    },
    [FIELD_OPERATORS.is_empty.code]: {
      ...FIELD_OPERATORS.is_empty,
      display: 'is not selected'
    },
    [FIELD_OPERATORS.is_numerical.code]: FIELD_OPERATORS.is_numerical,
    [FIELD_OPERATORS.is_text.code]: FIELD_OPERATORS.is_text
  },
  checkbox: {
    [FIELD_OPERATORS.is_true.code]: {
      ...FIELD_OPERATORS.is_true,
      display: 'is checked'
    },
    [FIELD_OPERATORS.is_false.code]: {
      ...FIELD_OPERATORS.is_false,
      display: 'is not checked'
    }
  },
  multiselect: {
    ...SELECTION_OPERATORS,
    ...EQUAL_OPERATORS,
    ...STRING_OPERATORS,
    [FIELD_OPERATORS.is_filled.code]: {
      ...FIELD_OPERATORS.is_filled,
      display: 'is selected'
    },
    [FIELD_OPERATORS.is_empty.code]: {
      ...FIELD_OPERATORS.is_empty,
      display: 'is not selected'
    },
    [FIELD_OPERATORS.is_numerical.code]: FIELD_OPERATORS.is_numerical,
    [FIELD_OPERATORS.is_text.code]: FIELD_OPERATORS.is_text
  },
  select: {
    ...TEXT_OPERATORS,
    [FIELD_OPERATORS.is_filled.code]: {
      ...FIELD_OPERATORS.is_filled,
      display: 'is selected'
    },
    [FIELD_OPERATORS.is_empty.code]: {
      ...FIELD_OPERATORS.is_empty,
      display: 'is not selected'
    },
    [FIELD_OPERATORS.is_numerical.code]: FIELD_OPERATORS.is_numerical,
    [FIELD_OPERATORS.is_text.code]: FIELD_OPERATORS.is_text
  },
  button_group: {
    ...SELECTION_OPERATORS,
    ...EQUAL_OPERATORS,
    ...STRING_OPERATORS,
    [FIELD_OPERATORS.is_filled.code]: {
      ...FIELD_OPERATORS.is_filled,
      display: 'is selected'
    },
    [FIELD_OPERATORS.is_empty.code]: {
      ...FIELD_OPERATORS.is_empty,
      display: 'is not selected'
    }
  },
  integer_field: {
    [FIELD_OPERATORS.equal.code]: FIELD_OPERATORS.equal,
    [FIELD_OPERATORS.not_equal.code]: FIELD_OPERATORS.not_equal,
    ...FILLED_EMPTY_OPERATIONS,
    [FIELD_OPERATORS.greater_than.code]: FIELD_OPERATORS.greater_than,
    [FIELD_OPERATORS.greater_than_or_equal.code]:
      FIELD_OPERATORS.greater_than_or_equal,
    [FIELD_OPERATORS.less_than.code]: FIELD_OPERATORS.less_than,
    [FIELD_OPERATORS.less_than_or_equal.code]:
      FIELD_OPERATORS.less_than_or_equal
  },
  slider: {
    [FIELD_OPERATORS.equal.code]: FIELD_OPERATORS.equal,
    [FIELD_OPERATORS.not_equal.code]: FIELD_OPERATORS.not_equal,
    ...FILLED_EMPTY_OPERATIONS,
    [FIELD_OPERATORS.greater_than.code]: FIELD_OPERATORS.greater_than,
    [FIELD_OPERATORS.greater_than_or_equal.code]:
      FIELD_OPERATORS.greater_than_or_equal,
    [FIELD_OPERATORS.less_than.code]: FIELD_OPERATORS.less_than,
    [FIELD_OPERATORS.less_than_or_equal.code]:
      FIELD_OPERATORS.less_than_or_equal
  },
  rating: {
    [FIELD_OPERATORS.equal.code]: FIELD_OPERATORS.equal,
    [FIELD_OPERATORS.not_equal.code]: FIELD_OPERATORS.not_equal,
    ...FILLED_EMPTY_OPERATIONS,
    [FIELD_OPERATORS.greater_than.code]: FIELD_OPERATORS.greater_than,
    [FIELD_OPERATORS.greater_than_or_equal.code]:
      FIELD_OPERATORS.greater_than_or_equal,
    [FIELD_OPERATORS.less_than.code]: FIELD_OPERATORS.less_than,
    [FIELD_OPERATORS.less_than_or_equal.code]:
      FIELD_OPERATORS.less_than_or_equal
  },
  email: { ...TEXT_OPERATORS, ...FILLED_EMPTY_OPERATIONS },
  ssn: { ...TEXT_OPERATORS, ...FILLED_EMPTY_OPERATIONS },
  phone_number: { ...TEXT_OPERATORS, ...FILLED_EMPTY_OPERATIONS },
  file_upload: FILLED_EMPTY_OPERATIONS,
  url: { ...TEXT_OPERATORS, ...FILLED_EMPTY_OPERATIONS },
  pin_input: { ...TEXT_OPERATORS, ...FILLED_EMPTY_OPERATIONS },
  qr_scanner: { ...TEXT_OPERATORS, ...FILLED_EMPTY_OPERATIONS },
  custom: { ...FILLED_EMPTY_OPERATIONS },
  gmap_line_1: { ...TEXT_OPERATORS, ...FILLED_EMPTY_OPERATIONS },
  gmap_line_2: { ...TEXT_OPERATORS, ...FILLED_EMPTY_OPERATIONS },
  gmap_city: {
    [FIELD_OPERATORS.equal.code]: FIELD_OPERATORS.equal,
    [FIELD_OPERATORS.not_equal.code]: FIELD_OPERATORS.not_equal,
    ...FILLED_EMPTY_OPERATIONS
  },
  gmap_state: {
    [FIELD_OPERATORS.equal.code]: FIELD_OPERATORS.equal,
    [FIELD_OPERATORS.not_equal.code]: FIELD_OPERATORS.not_equal,
    ...FILLED_EMPTY_OPERATIONS
  },
  gmap_country: {
    [FIELD_OPERATORS.equal.code]: FIELD_OPERATORS.equal,
    [FIELD_OPERATORS.not_equal.code]: FIELD_OPERATORS.not_equal,
    ...FILLED_EMPTY_OPERATIONS
  },
  gmap_zip: { ...TEXT_OPERATORS, ...FILLED_EMPTY_OPERATIONS },
  hex_color: {
    /* None supported */
  },
  signature: {
    [FIELD_OPERATORS.is_filled.code]: {
      ...FIELD_OPERATORS.is_filled,
      display: 'is signed'
    },
    [FIELD_OPERATORS.is_empty.code]: {
      ...FIELD_OPERATORS.is_empty,
      display: 'is not signed'
    }
  },
  password: COMMON_TEXT_OPERATIONS
};

export function getComparisonOperators(servar?: {
  type: string;
  metadata: { other: boolean };
}) {
  // Figure out which comparison operators are supported by the field/type
  let fieldOperators = UNVERSALLY_SUPPORTED_OPERATORS;
  if (servar) {
    const mappedOperators = FIELD_OPERATOR_MAP[servar.type as string];
    // Don't fail on some unmapped potentially new servar type, just fall back to
    // the universally supported comparison operators
    if (mappedOperators) fieldOperators = mappedOperators;
  } else fieldOperators = FIELD_OPERATORS; // untyped hidden fields get all comparison operators

  return Object.values(fieldOperators).filter(
    // Some operators/conditions only apply when the 'other' option is configured
    (operator: OperatorMeta) =>
      !operator.modifier ||
      (operator.modifier === 'other_only' && servar && servar.metadata.other)
  );
}

export {
  EQUAL_OPERATORS,
  FIELD_OPERATORS,
  FIELD_OPERATOR_MAP,
  SELECTION_OPERATORS,
  UNVERSALLY_SUPPORTED_OPERATORS
};
