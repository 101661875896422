import { InlineTooltip, TextField } from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { useAppSelector } from '../../../hooks';
import { useParams } from 'react-router-dom';
import DocumentReplaceButton from '../DocumentReplaceButton';
import Combobox from '../../../components/Core/Combobox';
import CopyableText from '../../../components/Core/CopyableText';

const DocumentPropertiesSection = ({ title, formData, updateData }: any) => {
  const { documentId } = useParams<{ documentId: string }>();
  const doc = useAppSelector((state) => state.documents.documents[documentId]);

  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <label>
          <p className='form-label'>Name</p>
          <TextField
            placeholder='My Document'
            value={formData.key}
            onComplete={(val: string) => updateData('key', val)}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>ID</p>
          <CopyableText
            text={documentId}
            toastMessage='Document ID copied to clipboard.'
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Tags
            <InlineTooltip text='Optional, custom document identifiers' />
          </p>
          <Combobox
            value={formData.tag_metadata ?? []}
            onChange={(items: string[]) => {
              updateData('tag_metadata', items);
            }}
            classNames={{
              multiFieldControl: '!rounded-[8px]'
            }}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>Replace Document File</p>
          <DocumentReplaceButton />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>Download Document</p>
          <a href={doc.file} target='_blank' rel='noreferrer'>
            {formData.key}
          </a>
        </label>
      </div>
    </SettingsContainer>
  );
};

export default DocumentPropertiesSection;
