import '../style/fields.scss';

import { useState } from 'react';
import { Elements } from '@feathery/react';
import { SketchPicker } from 'react-color';

import { objectApply } from './core';
import { ColorPickerOpener, TransparencyIndicator } from '../components/Icons';

// Element Types
const TYPE_BUTTON = 'button_element';
const TYPE_TEXT = 'text_element';
const TYPE_IMAGE = 'image_element';
const TYPE_VIDEO = 'video_element';
const TYPE_FIELD = 'servar_field';
const TYPE_PROGRESS_BAR = 'progress_bar';
const TYPE_CONTAINER = 'container';

export type ElementType =
  | 'image_element'
  | 'video_element'
  | 'progress_bar'
  | 'text_element'
  | 'button_element'
  | 'servar_field';

const COMPONENT_MAP = {
  [TYPE_BUTTON]: Elements.ButtonElement,
  [TYPE_TEXT]: Elements.TextElement,
  [TYPE_IMAGE]: Elements.ImageElement,
  [TYPE_VIDEO]: Elements.VideoElement,
  [TYPE_PROGRESS_BAR]: Elements.ProgressBarElement,
  text_field: Elements.TextField,
  text_area: Elements.TextArea,
  integer_field: Elements.TextField,
  rating: Elements.RatingField,
  slider: Elements.SliderField,
  button_group: Elements.ButtonGroupField,
  select: Elements.RadioButtonGroupField,
  multiselect: Elements.CheckboxGroupField,
  checkbox: Elements.CheckboxField,
  date_selector: Elements.DateSelectorField,
  dropdown: Elements.DropdownField,
  dropdown_multi: Elements.DropdownMultiField,
  matrix: Elements.MatrixField,
  file_upload: Elements.FileUploadField,
  signature: Elements.SignatureField,
  hex_color: Elements.ColorPickerField,
  email: Elements.TextField,
  password: Elements.PasswordField,
  payment_method: Elements.PaymentMethodField,
  phone_number: Elements.PhoneField,
  pin_input: Elements.PinInputField,
  qr_scanner: Elements.QRScanner,
  custom: Elements.CustomField,
  ssn: Elements.TextField,
  url: Elements.TextField,
  gmap_line_1: Elements.TextField,
  gmap_line_2: Elements.TextField,
  gmap_city: Elements.TextField,
  gmap_state: Elements.DropdownField,
  gmap_country: Elements.DropdownField,
  gmap_zip: Elements.TextField
};

const fieldTypeNameMap: Record<string, string> = {
  matrix: 'Matrix',
  text_field: 'Text Field',
  text_area: 'Text Area',
  integer_field: 'Number',
  slider: 'Slider',
  rating: 'Rating',
  button_group: 'Button Group',
  select: 'Radio Button Group',
  multiselect: 'Checkbox Group',
  checkbox: 'Checkbox',
  dropdown: 'Dropdown',
  dropdown_multi: 'Dropdown Multiselect',
  date_selector: 'Date Selector',
  file_upload: 'File Upload',
  signature: 'Signature',
  hex_color: 'Hex Color',
  email: 'Email',
  password: 'Password',
  phone_number: 'Phone Number',
  pin_input: 'Pin Input',
  qr_scanner: 'QR Code Scanner',
  custom: 'Custom Field',
  ssn: 'Social Security Number',
  url: 'URL',
  gmap_line_1: 'Street Address',
  gmap_line_2: 'Address Line 2',
  gmap_city: 'Address City',
  gmap_state: 'Address State',
  gmap_country: 'Address Country',
  gmap_zip: 'Address Zip Code',
  payment_method: 'Payment Method'
};

const FIELD_TYPES = Object.keys(fieldTypeNameMap);

const elementTypeNameMap: Record<string, string> = {
  [TYPE_FIELD]: 'Field',
  [TYPE_BUTTON]: 'Button',
  [TYPE_TEXT]: 'Text',
  [TYPE_IMAGE]: 'Image',
  [TYPE_VIDEO]: 'Video',
  [TYPE_PROGRESS_BAR]: 'Progress Bar'
};

// Convert list of style objects into a single object with hierarchy
function createThemeMap(themeElements: any) {
  const themeMap = {};
  Object.values(themeElements).forEach((element) => {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'level_2' does not exist on type 'unknown... Remove this comment to see the full error message
      level_2: l2,
      // @ts-expect-error TS(2339) FIXME: Property 'level_1' does not exist on type 'unknown... Remove this comment to see the full error message
      level_1: l1,
      // @ts-expect-error TS(2339) FIXME: Property 'styles' does not exist on type 'unknown'... Remove this comment to see the full error message
      styles,
      // @ts-expect-error TS(2339) FIXME: Property 'mobile_styles' does not exist on type 'u... Remove this comment to see the full error message
      mobile_styles: mobileStyles
    } = element;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    themeMap[l2] = themeMap[l2] ?? {};
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    themeMap[l2][l1] = { styles, mobileStyles };
  });

  return themeMap;
}

function flattenStyleMap(themeElements = []) {
  const themeMap = createThemeMap(themeElements);

  if (Object.keys(themeMap).length === 0) {
    return {};
  }

  const categories = [
    ['global', ''],
    ['button', ''],
    ['image', ''],
    ['video', ''],
    ['text', ''],
    ['progress_bar', ''],
    ['field', ''],
    ...FIELD_TYPES.map((f) => ['field', f])
  ];

  // Flatten styles so each element type maps to the final style (pending any instance-based styles)
  const result = {};
  categories.forEach(([l2, l1]) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (!result[l2]) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[l2] = {};
    }
    if (l2 === 'global') {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[l2][''] = {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        styles: themeMap[l2][''].styles,
        mobileStyles: objectApply(
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          themeMap[l2][''].styles,
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          themeMap[l2][''].mobileStyles
        )
      };
    } else {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!themeMap[l2]) themeMap[l2] = { '': {} };
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[l2][l1] = {
        styles: objectApply(
          (themeMap as any).global[''].styles,
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          themeMap[l2][''].styles
        ),
        mobileStyles: objectApply(
          (themeMap as any).global[''].styles,
          (themeMap as any).global[''].mobileStyles,
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          themeMap[l2][''].styles,
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          themeMap[l2][''].mobileStyles
        )
      };
      // TODO (tyler): custom fields do not support styles yet
      if (l1 && l1 != 'custom') {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        result[l2][l1] = {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          styles: objectApply(result[l2][l1].styles, themeMap[l2][l1].styles),
          mobileStyles: objectApply(
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            result[l2][l1].mobileStyles,
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            themeMap[l2][l1].styles,
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            themeMap[l2][l1].mobileStyles
          )
        };
      }
    }
  });

  return result;
}

function ColorPicker({
  color,
  setColor,
  reference = null,
  horizontal = 'left',
  vertical = 'bottom',
  style = {},
  colorPickerStyle
}: any) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  return (
    <div className='color-picker-container'>
      <div
        className='color-indicator'
        ref={reference}
        data-color-code={color}
        style={{ overflow: 'hidden', ...style }}
        onClick={(e) => {
          e.preventDefault();
          setDisplayColorPicker(true);
        }}
      >
        <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
          <TransparencyIndicator />
        </div>
        <div
          style={{
            background: `${color === 'transparent' ? color : `#${color}`}`,
            position: 'absolute',
            height: '100%',
            width: '100%'
          }}
        />
        <div className='open-indicator'>
          <ColorPickerOpener />
        </div>
      </div>
      {displayColorPicker ? (
        <div
          className='color-picker'
          style={{
            right: horizontal === 'right' && 0,
            bottom: vertical === 'bottom' && 0,
            ...colorPickerStyle
          }}
        >
          <div
            className='color-picker-close'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setDisplayColorPicker(false);
            }}
          />
          <SketchPicker
            color={`#${color}`}
            onChangeComplete={(colorInfo: any) => {
              if (colorInfo.hex === 'transparent') {
                setColor(colorInfo.hex);
              } else {
                let alphaHex = Math.round(colorInfo.rgb.a * 255).toString(16);
                if (colorInfo.rgb.a < 0.07) alphaHex = `0${alphaHex}`;
                setColor(`${colorInfo.hex.substr(1, 6)}${alphaHex}`);
              }
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

export {
  ColorPicker,
  createThemeMap,
  flattenStyleMap,
  fieldTypeNameMap,
  elementTypeNameMap,
  FIELD_TYPES,
  TYPE_IMAGE,
  TYPE_VIDEO,
  TYPE_TEXT,
  TYPE_FIELD,
  TYPE_PROGRESS_BAR,
  TYPE_BUTTON,
  TYPE_CONTAINER,
  COMPONENT_MAP
};
