import { InlineTooltip, YesNoSwitch } from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { PUBLISH_STATUS } from '../../../redux/utils';

const ReportingSection = ({
  title,
  curWeeklyReport,
  setCurWeeklyReport,
  setPublishStatus
}: any) => {
  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Receive Weekly Submission Report
            <InlineTooltip text="Once a week on Monday, you'll receive an emailed report summarizing your submissions over the past week" />
          </p>
          <YesNoSwitch
            id='submissions-report'
            checked={curWeeklyReport}
            onCheckedChange={(value) => {
              setCurWeeklyReport(value);
              setPublishStatus(PUBLISH_STATUS.ACTIVE);
            }}
          />
        </label>
      </div>
    </SettingsContainer>
  );
};

export default ReportingSection;
