import { useMemo, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { useParams } from 'react-router-dom';
import { onFreePlan } from '../../../components/PricingPlans/plans';
import { getPanelSlug } from '../../../utils/panel';
import { ThemeSwapModal } from '../../../components/Modals';
import {
  DropdownField,
  InlineTooltip,
  TextField,
  YesNoSwitch
} from '../../../components/Core';
import useDraftForm from '../../../utils/useDraftForm';
import SettingsContainer from '../../../components/SettingsContainer';
import Combobox from '../../../components/Core/Combobox';
import CopyableText from '../../../components/Core/CopyableText';
import { formatDate } from '../../../utils/format';
import { Tooltip } from '../../../components/Core/Tooltip/Tooltip';

const FormPropertiesSection = ({
  title,
  formData,
  handleValueChange,
  updateFormData
}: any) => {
  const { formId } = useParams<{ formId: string }>();
  const org = useAppSelector((state) => state.accounts.organization);
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const liveSlug = getPanelSlug(panel, 'live');
  const themes = useAppSelector((state) => state.themes.themes);

  const panelThemeAssetUse = useAppSelector(
    (state) => state.themes.panelThemeAssetUse
  );
  const assetUsage = useMemo(
    () => panelThemeAssetUse.find((data: any) => data.panel_id === formId),
    [panelThemeAssetUse, formId]
  );
  const [themeSwapId, setThemeSwapId] = useState('');

  const { draftSteps, draftTheme } = useDraftForm();
  const draftExists = Boolean(draftSteps || draftTheme);

  let themeDropdown = (
    <DropdownField
      disabled={draftExists}
      selected={formData.theme ?? ''}
      onChange={(e: any) => {
        const newThemeId = e.target.value;
        // Do not trigger swap modal if switching back to original theme or
        // there are no assets to swap
        if (
          formData.theme.id === newThemeId ||
          (assetUsage && assetUsage.assets_in_use.length === 0)
        )
          updateFormData({ assetSwaps: {}, theme: newThemeId });
        else setThemeSwapId(newThemeId);
      }}
      options={Object.values(themes).map(({ id, key }) => ({
        value: id,
        display: key
      }))}
    />
  );
  if (draftExists)
    themeDropdown = (
      <Tooltip
        content="You can't change the theme since this form has draft changes.
          Please publish or discard your draft."
      >
        {themeDropdown}
      </Tooltip>
    );

  return (
    <SettingsContainer title={title}>
      <ThemeSwapModal
        curThemeId={panel?.theme}
        newThemeId={themeSwapId}
        hideModal={() => setThemeSwapId('')}
        setAssetSwaps={(assetSwaps: any) =>
          updateFormData({ assetSwaps, theme: themeSwapId })
        }
      />
      <div className='field-group'>
        <label>
          <p className='form-label'>Form Name</p>
          <TextField
            placeholder='My Form'
            value={formData.key ?? ''}
            onComplete={handleValueChange('key')}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Form ID
            <InlineTooltip text='The ID is used to embed your form or access the developer API.' />
          </p>
          <CopyableText
            text={liveSlug}
            toastMessage={'Form ID copied to clipboard.'}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>Theme</p>
          {themeDropdown}
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Tags
            <InlineTooltip text='Optional, custom form identifiers' inline />
          </p>
          <Combobox
            value={formData.tag_metadata ?? []}
            onChange={(items: string[]) => {
              updateFormData({ tag_metadata: items });
            }}
            classNames={{
              multiFieldControl: '!rounded-[8px]'
            }}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Auto-Expire Form Link
            <InlineTooltip text='If enabled, the live form link will automatically change at a regular time interval' />
          </p>
          <YesNoSwitch
            id='autoexpire-form-link'
            checked={formData.expire_slug !== 0}
            onCheckedChange={(value) =>
              handleValueChange('expire_slug')(value ? 60 * 24 : 0)
            }
          />
        </label>
      </div>
      {formData.expire_slug !== 0 && (
        <div className='field-group'>
          <label>
            <p className='form-label'>Expiration Time</p>
            <DropdownField
              name='expire-form-link-time'
              selected={(formData.expire_slug ?? '').toString()}
              onChange={(e: any) =>
                handleValueChange('expire_slug')(parseInt(e.target.value))
              }
              options={[
                { value: '60', display: '1 Hour' },
                { value: '720', display: '12 Hours' },
                { value: '1440', display: '1 Day' },
                { value: '4320', display: '3 Days' },
                { value: '10080', display: '1 Week' },
                { value: '43200', display: '1 Month' }
              ]}
            />
          </label>
        </div>
      )}
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Custom Head Code
            <InlineTooltip
              text={`This code will be added to the end of the form <head>. ${
                onFreePlan(org)
                  ? 'You must be on a paid plan to use this feature.'
                  : ''
              }`}
            />
          </p>
          <TextField
            style={{ height: '200px' }}
            type='textarea'
            disabled={onFreePlan(org)}
            required={false}
            rows={10}
            maxLength={4096}
            value={formData.custom_head}
            onComplete={(val: string) => handleValueChange('custom_head')(val)}
            placeholder='<script>...</script>'
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Custom CSS
            <InlineTooltip
              text={`Specified CSS selectors will be applied to your form. ${
                onFreePlan(org)
                  ? 'You must be on a paid plan to use this feature.'
                  : ''
              }`}
            />
          </p>
          <TextField
            style={{ height: '200px' }}
            type='textarea'
            disabled={onFreePlan(org)}
            required={false}
            rows={10}
            maxLength={4096}
            value={formData.custom_css}
            onComplete={(val: string) => handleValueChange('custom_css')(val)}
            placeholder={
              'body {\n     background-color: #f0f0f0 !important;\n     padding-top: 20px;\n}'
            }
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>Created At</p>
          {formatDate(formData.created_at)}
        </label>
      </div>
      {formData.created_by && (
        <div className='field-group'>
          <label>
            <p className='form-label'>Created By</p>
            {formData.created_by}
          </label>
        </div>
      )}
      <div className='field-group'>
        <label>
          <p className='form-label'>Last Edited</p>
          {formatDate(formData.updated_at)}
        </label>
      </div>
      {formData.updated_by && (
        <div className='field-group'>
          <label>
            <p className='form-label'>Last Edited By</p>
            {formData.updated_by}
          </label>
        </div>
      )}
    </SettingsContainer>
  );
};

export default FormPropertiesSection;
