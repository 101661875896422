import classNames from 'classnames';
import styles from '../styles.module.scss';
import { Tooltip } from '../../Core/Tooltip/Tooltip';
import { Button } from '../../Core/Button/button';
import { INTEGRATIONS } from '../types';

const AuthorizeOauthButton = ({
  integrationType,
  authorize,
  hasToken,
  integrationEnabled,
  domain
}: any) => {
  const domainProvided = integrationType !== INTEGRATIONS.EGNYTE || domain;
  const disabled = hasToken && integrationEnabled;
  const buttonComponent = (
    <Button
      className={classNames(hasToken && styles.authorized)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!disabled && domainProvided) authorize();
      }}
    >
      {hasToken ? 'Unauthorize' : 'Authorize'}
    </Button>
  );
  return !domainProvided || disabled ? (
    <Tooltip
      content={
        !domainProvided
          ? 'Egnyte Domain name needs to be provided before authorization.'
          : 'This integration must first be disconnected before you can unauthorize.'
      }
    >
      {buttonComponent}
    </Tooltip>
  ) : (
    buttonComponent
  );
};

export default AuthorizeOauthButton;
