import styles from '../styles.module.scss';
import { InlineTooltip, TextField } from '../../../components/Core';
import useFeatheryRedux from '../../../redux';
import { useAppSelector } from '../../../hooks';
import { useState } from 'react';

const DEFAULT_CUSTOM_FIELD = {
  label: '',
  key: '',
  code: '',
  icon: ''
};

export default function CustomFieldTab() {
  const {
    updateCustomField,
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();

  const org = useAppSelector((state) => state.accounts.organization);
  const [fieldConfig, setFieldConfig] = useState(
    org?.custom_field ?? DEFAULT_CUSTOM_FIELD
  );

  const updateFieldConfig = (key: string, value: string) => {
    setFieldConfig({ ...fieldConfig, [key]: value });
  };

  const onSave = () => {
    let err = '';
    Object.keys(DEFAULT_CUSTOM_FIELD).forEach((key) => {
      if (!fieldConfig[key]) {
        err = 'All custom field settings must be defined to save';
      }
    });
    if (err) {
      addErrorToast({ title: err });
      return;
    }

    updateCustomField(fieldConfig)
      .then(() => addInfoToast('Custom field updated'))
      .catch(() => addErrorToast({ title: 'Invalid custom field config' }));
  };

  return (
    <>
      <div className={styles.sectionSeparator} />
      <div className={styles.lowerSection}>
        <label className={styles.settingsLabel}>
          Custom Field Name
          <InlineTooltip text='User-friendly label for the field' />
        </label>
        <div className={styles.teamFieldSection}>
          <TextField
            placeholder='Inventory Selector'
            maxLength={32}
            value={fieldConfig.label}
            onChange={(val: string) => updateFieldConfig('label', val)}
            className={styles.settingsField}
          />
        </div>
        <label className={styles.settingsLabel}>
          Custom Field Type
          <InlineTooltip text='Specify a unique, static internal identifier for the field type' />
        </label>
        <div className={styles.teamFieldSection}>
          <TextField
            placeholder='inventor_selector'
            maxLength={32}
            value={fieldConfig.key}
            onChange={(val: string) =>
              updateFieldConfig('key', val.toLowerCase())
            }
            className={styles.settingsField}
          />
        </div>
        <label className={styles.settingsLabel}>
          Custom Field Icon URL
          <InlineTooltip text='Specify a link to the icon to display for the custom field in the form designer' />
        </label>
        <div className={styles.teamFieldSection}>
          <TextField
            type='url'
            placeholder='https://icon.com/selector.svg'
            value={fieldConfig.icon}
            onChange={(val: string) => updateFieldConfig('icon', val)}
            className={styles.settingsField}
          />
        </div>
        <label className={styles.settingsLabel}>
          Custom Field Code
          <InlineTooltip text='React code to render for the custom field' />
        </label>
        <div className={styles.teamFieldSection}>
          <TextField
            type='textarea'
            rows={20}
            value={fieldConfig.code}
            onChange={(val: string) => updateFieldConfig('code', val)}
            className={styles.settingsField}
            style={{ width: '500px', height: '330px' }}
            placeholder={codeExamplePlaceholder}
          />
        </div>
        <div className={styles.teamFieldSection} style={{ marginTop: '20px' }}>
          <span className={styles.teamFieldSave} onClick={onSave}>
            Save
          </span>
        </div>
      </div>
    </>
  );
}

const codeExamplePlaceholder =
  "import React from 'react';\n" +
  '\n' +
  'const Counter = ({ value = 0, onChange }) => {\n' +
  '  const handleClick = () => {\n' +
  '    onChange(value + 1); // Increments the current value\n' +
  '  };\n' +
  '\n' +
  '  return (\n' +
  '    <div>\n' +
  '      <p>Current value: {value}</p>\n' +
  '      <button onClick={handleClick}>Increment</button>\n' +
  '    </div>\n' +
  '  );\n' +
  '};\n' +
  '\n' +
  'export default Counter;';
