import '../../style/dialog-form.css';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import { InlineTooltip, TextField } from '../Core';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Label from '../Dialog/Label';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';

const DEFAULT_SETTINGS = {
  certificate_test: '',
  certificate_production: '',
  api_client_id: '',
  api_key: ''
};

function EMoneySettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.EMONEY,
    panelId: formId,
    includeInactive: true
  });

  const [eMoneyConfig, setEMoneyConfig] = useState(
    integration?.data.secret_metadata ?? DEFAULT_SETTINGS
  );

  const updateConfig = (key: string, value: string) =>
    setEMoneyConfig((newConfig: any) => ({
      ...newConfig,
      [key]: value
    }));

  const [isPartial, setIsPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial =
        !eMoneyConfig.api_client_id ||
        !eMoneyConfig.api_key ||
        (!eMoneyConfig.certificate_test &&
          !eMoneyConfig.certificate_production);
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      secretMetadata: { ...eMoneyConfig }
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.EMONEY]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <div>
        <Label>API Client ID</Label>
        <TextField
          className={styles.marginBottom}
          value={eMoneyConfig.api_client_id}
          onChange={(val: string) => updateConfig('api_client_id', val)}
          error={isPartial && !eMoneyConfig.api_client_id}
        />
        <Label>API Key</Label>
        <TextField
          className={styles.marginBottom}
          value={eMoneyConfig.api_key}
          onChange={(val: string) => updateConfig('api_key', val)}
          error={isPartial && !eMoneyConfig.api_key}
        />
        <Label>
          Production Certificate Private Key{' '}
          <InlineTooltip
            text='Will be used to authenticate the Feathery live form, unless it is not specified but the test key is.'
            style={{ display: 'inline' }}
          />
        </Label>
        <TextField
          className={classNames(styles.marginBottom, styles.certificateField)}
          value={eMoneyConfig.certificate_production || ''}
          onChange={(value: string) =>
            updateConfig('certificate_production', value)
          }
          type='textarea'
          error={isPartial && !eMoneyConfig.certificate_production}
        />
      </div>
      <div>
        <Label>
          Test Certificate Private Key
          <InlineTooltip
            text='Will be used to authenticate the Feathery test form, unless it is not specified but the production key is.'
            style={{ display: 'inline' }}
          />
        </Label>
        <TextField
          className={classNames(styles.marginBottom, styles.certificateField)}
          value={eMoneyConfig.certificate_test || ''}
          onChange={(value: string) => updateConfig('certificate_test', value)}
          type='textarea'
          error={isPartial && !eMoneyConfig.certificate_test}
        />
      </div>
      <p className='text-muted'>
        * Either or both the production and test eMoney certificate private keys
        must be specified.
      </p>
    </IntegrationsSidebar>
  );
}

export default EMoneySettingsSidebar;
