import * as Icons from '../../Icons';

import {
  assetUpdateOperations,
  BORDER_PROPS,
  BUTTON_MARGIN_PROPS,
  CORNER_PROPS,
  elementOperation,
  getAsset,
  HOVER_BORDER_PROPS,
  level1UpdateOperations,
  level2UpdateOperations,
  SELECTED_BORDER_PROPS,
  UPLOADER_PADDING_PROPS
} from '../../../utils/themes';
import {
  BorderStyleInput,
  CheckboxField,
  CollapsibleSection,
  CornerStyleInput,
  DropdownField,
  FColorPicker,
  MarginPaddingInput,
  NumberInput,
  PaddingInput,
  PropertyLabel,
  RadioButtonGroup,
  TextField
} from '../../Core';
import { fieldTypeNameMap } from '../../../utils/elements';
import { TEXT_BASED_FIELDS } from '../../SelectionPanel/elementEntries';
import { memo } from 'react';

import { calculateOverrideObjects } from '../utils';
import { objectPick } from '../../../utils/core';
import FontStyleSection from './components/FontStyleSection';
import ImageStyleSection from './components/ImageStyleSection';
import { Viewport } from '../../RenderingEngine/GridInGrid/engine';
import { ShadowStyleSection } from './components';
import { AXIS, SIZE_UNITS } from '../../../utils/constants';
import BoxSpacingInput from '../../Core/BoxSpacingInput';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Core/Tabs';
import Row from '../../Core/Layout/Row';
import Col from '../../Core/Layout/Col';

function FieldStylePanel({
  fieldType,
  mode,
  viewport,
  theme,
  baseProps,
  baseStyle,
  overrideProps,
  overrideStyle,
  handleUpdates,
  isAsset,
  cellNode
}: any) {
  const { result: element } = calculateOverrideObjects(
    baseProps,
    overrideProps,
    true
  );
  const { result: elementStyle, isOverride: isOverrideStyle } =
    calculateOverrideObjects(baseStyle, overrideStyle);
  const asset = getAsset(theme, fieldType, element.source_asset);

  function handlePropChange(propUpdate: any) {
    handleUpdates([elementOperation({ viewport, propUpdate })]);
  }

  function handleInheritedPropChange(propUpdate: any) {
    handlePropChange({ properties: propUpdate });
  }

  function handleStyleChange(styleUpdate: any) {
    handleUpdates([elementOperation({ viewport, styleUpdate })]);
  }

  function labelData(...props: string[]) {
    const newStyle = objectPick(elementStyle, props);
    const key = viewport === Viewport.Mobile ? 'Desktop' : 'Theme';
    return {
      mode,
      highlighted: isOverrideStyle(props),
      themeOperations: {
        instance: {
          label: `Reset to ${key}`,
          operation: () => {
            handleUpdates([elementOperation({ viewport, styleReset: props })]);
          }
        },
        ...(mode === 'builder' && {
          level2: {
            label: 'Fields',
            operation: () => {
              handleUpdates(
                level2UpdateOperations({
                  viewport,
                  newStyle,
                  asset,
                  elementType: fieldType,
                  level2: 'field',
                  level1: fieldType
                })
              );
            }
          },
          level1: {
            label: fieldTypeNameMap[fieldType],
            operation: () => {
              handleUpdates(
                level1UpdateOperations({
                  viewport,
                  newStyle,
                  asset,
                  elementType: fieldType,
                  level2: 'field',
                  level1: fieldType
                })
              );
            }
          },
          ...(asset && {
            asset: {
              label: asset.key,
              operation: () => {
                handleUpdates(
                  assetUpdateOperations({
                    viewport,
                    elementType: fieldType,
                    asset,
                    newStyle
                  })
                );
              }
            }
          })
        })
      }
    };
  }

  const inFieldThemeEditor = mode === 'theme' && !fieldType;
  const showPlaceholderStyles =
    TEXT_BASED_FIELDS.includes(fieldType) || inFieldThemeEditor;
  const showPropControls = mode === 'builder' || isAsset;
  let fontLabel = 'Font';
  if (fieldType === 'select') fontLabel = 'Radio Fill';
  else if (['multiselect', 'checkbox', 'matrix'].includes(fieldType))
    fontLabel = 'Checkmark';

  if (fieldType === 'custom') {
    return (
      <div style={{ padding: '10px 15px' }}>
        No styles to configure for this field
      </div>
    );
  }

  return (
    <>
      <CollapsibleSection title='Layout' collapsible>
        <Row>
          <Col sm='3'>
            <PropertyLabel
              label='Width'
              {...labelData('width', 'width_unit')}
            />
          </Col>
          <Col>
            <NumberInput
              required
              min={0}
              value={elementStyle.width}
              unit={elementStyle.width_unit}
              units={fieldType === 'checkbox' ? ['px'] : ['px', '%']}
              onComplete={({ value, unit }: any) => {
                const styleUpdate = { width: value, width_unit: unit };
                // Enforce square checkbox
                if (fieldType === 'checkbox') {
                  // @ts-expect-error TS(2339) FIXME: Property 'height' does not exist on type '{ width:... Remove this comment to see the full error message
                  styleUpdate.height = value;
                  // @ts-expect-error TS(2339) FIXME: Property 'height_unit' does not exist on type '{ w... Remove this comment to see the full error message
                  styleUpdate.height_unit = unit;
                }
                handleStyleChange(styleUpdate);
              }}
              triggerCleanUp
              dimension='width'
            />
          </Col>
          {!['rating', 'qr_scanner'].includes(fieldType) && (
            <>
              <Col sm='3'>
                <PropertyLabel
                  label='Height'
                  indented
                  {...labelData('height', 'height_unit')}
                />
              </Col>
              <Col>
                <NumberInput
                  required
                  min={0}
                  value={elementStyle.height}
                  unit={elementStyle.height_unit}
                  units={
                    fieldType === 'checkbox'
                      ? ['px']
                      : fieldType === 'button_group' || fieldType === 'matrix'
                      ? ['px', '%', 'fit']
                      : ['px', '%']
                  }
                  onComplete={({ value, unit }: any) => {
                    const styleUpdate = {
                      height:
                        unit === SIZE_UNITS.FIT
                          ? ''
                          : unit !== SIZE_UNITS.FIT && !value
                          ? 100
                          : value,
                      height_unit: unit
                    };
                    // Enforce square checkbox
                    if (fieldType === 'checkbox') {
                      // @ts-expect-error TS(2339) FIXME: Property 'width' does not exist on type '{ height:... Remove this comment to see the full error message
                      styleUpdate.width = value;
                      // @ts-expect-error TS(2339) FIXME: Property 'width_unit' does not exist on type '{ he... Remove this comment to see the full error message
                      styleUpdate.width_unit = unit;
                    }
                    handleStyleChange(styleUpdate);
                  }}
                  triggerCleanUp
                  dimension='height'
                />
              </Col>
            </>
          )}
        </Row>
        {fieldType === 'button_group' && (
          <>
            <Row>
              <Col sm='3'>
                <PropertyLabel
                  label='Button Width'
                  {...labelData('button_width', 'button_width_unit')}
                />
              </Col>
              <Col>
                <NumberInput
                  required
                  min={0}
                  value={elementStyle.button_width}
                  unit={elementStyle.button_width_unit ?? 'px'}
                  units={['px', '%']}
                  onComplete={({ value, unit }: any) => {
                    handleStyleChange({
                      button_width: value,
                      button_width_unit: unit
                    });
                  }}
                  triggerCleanUp
                />
              </Col>
              <Col sm='3'>
                <PropertyLabel
                  label='Button Height'
                  indented
                  {...labelData('button_height', 'button_height_unit')}
                />
              </Col>
              <Col>
                <NumberInput
                  required
                  min={0}
                  value={elementStyle.button_height}
                  unit={elementStyle.button_height_unit ?? 'px'}
                  units={['px', '%']}
                  onComplete={({ value, unit }: any) => {
                    handleStyleChange({
                      button_height: value,
                      button_height_unit: unit
                    });
                  }}
                  triggerCleanUp
                />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <CheckboxField
                  checked={elementStyle.content_responsive}
                  onChange={(value) =>
                    handleStyleChange({
                      content_responsive: value
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Responsive - Expand to Content'
                      {...labelData('content_responsive')}
                    />
                  }
                  style={{ marginLeft: '5px' }}
                />
              </Col>
            </Row>
          </>
        )}
        {['multiselect', 'select'].includes(fieldType) && (
          <Row>
            <Col sm='5'>
              <PropertyLabel
                label='Row Spacing'
                {...labelData('row_separation')}
              />
            </Col>
            <Col>
              <NumberInput
                required
                min={0}
                value={elementStyle.row_separation}
                unit='px'
                units={['px']}
                onComplete={({ value }: any) => {
                  handleStyleChange({ row_separation: value });
                }}
                triggerCleanUp
                dimension='row-spacing'
              />
            </Col>
          </Row>
        )}
        <BoxSpacingInput
          elementStyle={elementStyle}
          handleStyleChange={handleStyleChange}
          labelData={labelData}
          elementType={fieldType}
        />
        {fieldType === 'file_upload' && (
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label='Uploader Padding'
                centered={false}
                {...labelData(...UPLOADER_PADDING_PROPS)}
              />
            </Col>
            <Col className='grid justify-center'>
              <PaddingInput
                value={{
                  top: elementStyle.uploader_padding_top,
                  right: elementStyle.uploader_padding_right,
                  bottom: elementStyle.uploader_padding_bottom,
                  left: elementStyle.uploader_padding_left
                }}
                onComplete={(newPadding: any) =>
                  handleStyleChange({
                    uploader_padding_top: newPadding.top,
                    uploader_padding_right: newPadding.right,
                    uploader_padding_bottom: newPadding.bottom,
                    uploader_padding_left: newPadding.left
                  })
                }
              />
            </Col>
          </Row>
        )}
        {fieldType === 'button_group' && (
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label='Button Margin & Padding'
                centered={false}
                {...labelData(
                  ...UPLOADER_PADDING_PROPS,
                  ...BUTTON_MARGIN_PROPS
                )}
              />
            </Col>
            <Col className='grid justify-center'>
              <MarginPaddingInput
                value={{
                  marginTop: elementStyle.button_margin_top,
                  marginRight: elementStyle.button_margin_right,
                  marginBottom: elementStyle.button_margin_bottom,
                  marginLeft: elementStyle.button_margin_left,
                  paddingTop: elementStyle.uploader_padding_top,
                  paddingRight: elementStyle.uploader_padding_right,
                  paddingBottom: elementStyle.uploader_padding_bottom,
                  paddingLeft: elementStyle.uploader_padding_left
                }}
                onComplete={(newPadding: any) => {
                  handleStyleChange({
                    button_margin_top: newPadding.marginTop,
                    button_margin_right: newPadding.marginRight,
                    button_margin_bottom: newPadding.marginBottom,
                    button_margin_left: newPadding.marginLeft,
                    uploader_padding_top: newPadding.paddingTop,
                    uploader_padding_right: newPadding.paddingRight,
                    uploader_padding_bottom: newPadding.paddingBottom,
                    uploader_padding_left: newPadding.paddingLeft
                  });
                }}
              />
            </Col>
          </Row>
        )}
        {cellNode && (
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label='Align Self'
                {...labelData('layout', 'vertical_layout')}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.layout}
                name='field-editor-horizontal-alignment'
                allowUnselect
                hideValueIfDisabled
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.COL}
                onChange={(value: any) => handleStyleChange({ layout: value })}
                options={[
                  {
                    value: 'flex-start',
                    display: <Icons.HorizontalLeftIcon />
                  },
                  { value: 'center', display: <Icons.HorizontalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.HorizontalRightIcon /> }
                ]}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.vertical_layout}
                name='field-editor-vertical-alignment'
                allowUnselect
                hideValueIfDisabled
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.ROW}
                onChange={(value: any) =>
                  handleStyleChange({ vertical_layout: value })
                }
                options={[
                  { value: 'flex-start', display: <Icons.VerticalTopIcon /> },
                  { value: 'center', display: <Icons.VerticalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.VerticalBottomIcon /> }
                ]}
              />
            </Col>
          </Row>
        )}
        {fieldType === 'button_group' && (
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label='Align Content'
                {...labelData('horizontal_align', 'vertical_align')}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle?.horizontal_align}
                name='field-editor-horizontal-alignment'
                onChange={(value: any) =>
                  handleStyleChange({ horizontal_align: value })
                }
                options={[
                  {
                    value: 'flex-start',
                    display: <Icons.HorizontalLeftIcon />
                  },
                  {
                    value: 'center',
                    display: <Icons.HorizontalCenterIcon />
                  },
                  {
                    value: 'flex-end',
                    display: <Icons.HorizontalRightIcon />
                  }
                ]}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle?.vertical_align}
                name='field-editor-vertical-alignment'
                onChange={(value: any) =>
                  handleStyleChange({ vertical_align: value })
                }
                options={[
                  { value: 'flex-start', display: <Icons.VerticalTopIcon /> },
                  { value: 'center', display: <Icons.VerticalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.VerticalBottomIcon /> }
                ]}
              />
            </Col>
          </Row>
        )}
      </CollapsibleSection>

      <CollapsibleSection title='Typography' collapsible>
        <FontStyleSection
          mode={mode}
          elementType={fieldType}
          level2={{ type: 'field', label: 'Fields' }}
          level1={fieldType}
          viewport={viewport}
          baseProps={baseProps}
          overrideProps={overrideProps}
          baseStyle={baseStyle}
          overrideStyle={overrideStyle}
          handleUpdates={handleUpdates}
          theme={theme}
          labelCustomData={labelData}
          handleCustomStyleChange={handleStyleChange}
          customValues={{
            line_height: elementStyle.line_height,
            text_align: elementStyle.text_align
          }}
        />
      </CollapsibleSection>

      {['file_upload', 'button_group'].includes(fieldType) && (
        <CollapsibleSection title='Image' collapsible>
          <ImageStyleSection
            element={element}
            elementStyle={elementStyle}
            fieldType={fieldType}
            labelData={labelData}
            handleStyleChange={handleStyleChange}
            level2={{ type: 'field', label: 'Fields' }}
            viewport={viewport}
            handleUpdates={handleUpdates}
            isAsset={asset}
            hideImageSelection={fieldType === 'button_group'}
          />
        </CollapsibleSection>
      )}

      {showPlaceholderStyles && (
        <>
          <CollapsibleSection title='Placeholder' collapsible>
            {showPropControls && fieldType !== 'payment_method' && (
              <Row>
                <Col sm='4'>
                  <PropertyLabel label='Text' />
                </Col>
                <Col>
                  <TextField
                    placeholder='Placeholder'
                    value={element.properties.placeholder}
                    onComplete={(val: any) =>
                      handleInheritedPropChange({ placeholder: val })
                    }
                    triggerCleanUp
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col sm='4'>
                <PropertyLabel
                  label='Style'
                  {...labelData('placeholder_italic')}
                />
              </Col>
              <Col>
                <RadioButtonGroup
                  selected={elementStyle.placeholder_italic}
                  name='servar-field-editor-placeholder-italic-toggle'
                  onChange={(value: any) =>
                    handleStyleChange({ placeholder_italic: value === 'true' })
                  }
                  options={[
                    { value: false, display: <Icons.CursorIcon /> },
                    { value: true, display: <Icons.ItalicIcon /> }
                  ]}
                />
              </Col>
            </Row>
            {fieldType !== 'payment_method' && (
              <Row>
                <Col sm='4'>
                  <PropertyLabel
                    label='On Select'
                    {...labelData('placeholder_transition')}
                  />
                </Col>
                <Col>
                  <DropdownField
                    selected={elementStyle.placeholder_transition}
                    onChange={(e: any) =>
                      handleStyleChange({
                        placeholder_transition: e.target.value
                      })
                    }
                    options={[
                      { value: '', display: 'Hide Placeholder' },
                      { value: 'shrink_top', display: 'Shrink to Top' }
                    ]}
                  />
                </Col>
              </Row>
            )}
          </CollapsibleSection>
          {showPropControls && (
            <CollapsibleSection title='Tooltip' collapsible>
              <Row>
                <Col sm='4'>
                  <PropertyLabel label='Text' />
                </Col>
                <Col>
                  <TextField
                    placeholder='Field information'
                    value={element.properties.tooltipText}
                    onComplete={(txt: any) =>
                      handleInheritedPropChange({ tooltipText: txt })
                    }
                    triggerCleanUp
                  />
                </Col>
              </Row>
            </CollapsibleSection>
          )}
        </>
      )}

      {fieldType === 'rating' && (
        <CollapsibleSection title='Icon' collapsible>
          <Row>
            <Col sm='4'>
              <PropertyLabel label='Icon' />
            </Col>
            <Col>
              <DropdownField
                selected={elementStyle.icon_type ?? ''}
                onChange={(e: any) =>
                  handleStyleChange({
                    icon_type: e.target.value
                  })
                }
                options={[
                  { value: '', display: 'Star' },
                  { value: 'heart', display: 'Heart' }
                ]}
              />
            </Col>
          </Row>
        </CollapsibleSection>
      )}

      {fieldType !== 'rating' && (
        <>
          <CollapsibleSection title='Display' collapsible>
            {fieldType === 'file_upload' && (
              <Row>
                <Col sm='12'>
                  <CheckboxField
                    checked={elementStyle.hide_file_preview}
                    onChange={(value) =>
                      handleStyleChange({
                        hide_file_preview: value
                      })
                    }
                    text={
                      <PropertyLabel
                        label="Don't show uploaded file preview"
                        {...labelData('hide_file_preview')}
                      />
                    }
                    style={{ marginLeft: '5px' }}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <PropertyLabel
                  label='Corners'
                  {...labelData(...CORNER_PROPS)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CornerStyleInput
                  topLeft={elementStyle.corner_top_left_radius}
                  topRight={elementStyle.corner_top_right_radius}
                  bottomLeft={elementStyle.corner_bottom_left_radius}
                  bottomRight={elementStyle.corner_bottom_right_radius}
                  onComplete={(newCorners: any) =>
                    handleStyleChange({
                      corner_top_left_radius: newCorners.topLeft,
                      corner_top_right_radius: newCorners.topRight,
                      corner_bottom_left_radius: newCorners.bottomLeft,
                      corner_bottom_right_radius: newCorners.bottomRight
                    })
                  }
                />
              </Col>
            </Row>
            {fieldType === 'text_area' && (
              <Row>
                <Col sm='4'>
                  <PropertyLabel
                    label='Max Row Count'
                    {...labelData('num_rows')}
                  />
                </Col>
                <Col>
                  <NumberInput
                    min={1}
                    value={elementStyle.num_rows}
                    onComplete={({ value }: any) =>
                      handleStyleChange({ num_rows: value })
                    }
                    triggerCleanUp
                  />
                </Col>
              </Row>
            )}
          </CollapsibleSection>
          <ShadowStyleSection
            labelData={labelData}
            styles={elementStyle}
            handleStyleChange={handleStyleChange}
          />
        </>
      )}
      <Tabs defaultValue='DefaultColors'>
        <TabsList unstyled className='legacyTabsList'>
          <TabsTrigger
            unstyled
            className='legacyTabsTriggerFlex'
            value='DefaultColors'
          >
            Default
          </TabsTrigger>
          <TabsTrigger
            unstyled
            className='legacyTabsTriggerFlex'
            value='HoverColors'
          >
            Hover
          </TabsTrigger>
          <TabsTrigger
            unstyled
            className='legacyTabsTriggerFlex'
            value='SelectedColors'
          >
            Selected
          </TabsTrigger>
          <TabsTrigger
            unstyled
            className='legacyTabsTriggerFlex'
            value='DisabledColors'
          >
            Disabled
          </TabsTrigger>
          {fieldType === 'payment_method' && (
            <TabsTrigger
              unstyled
              className='legacyTabsTriggerFlex'
              value='CompletedColors'
            >
              Completed
            </TabsTrigger>
          )}
        </TabsList>

        <TabsContent
          unstyled
          className='legacyTabsContent'
          value='DefaultColors'
          style={{ padding: '0 1em' }}
        >
          {['select', 'multiselect', 'checkbox', 'matrix'].includes(
            fieldType
          ) && (
            <Row>
              <Col sm='4'>
                <PropertyLabel
                  label={fontLabel}
                  {...labelData('selected_font_color')}
                />
              </Col>
              <Col>
                <FColorPicker
                  value={elementStyle.selected_font_color}
                  onChange={(color: any) =>
                    handleStyleChange({ selected_font_color: color })
                  }
                  colorPickerStyle={{ left: '-30px' }}
                />
              </Col>
            </Row>
          )}
          {fieldType !== 'hex_color' && (
            <>
              <Row>
                <Col sm='4'>
                  <PropertyLabel
                    label='Background'
                    {...labelData('background_color')}
                  />
                </Col>
                <Col>
                  <FColorPicker
                    value={elementStyle.background_color}
                    onChange={(color: any) =>
                      handleStyleChange({ background_color: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              </Row>
              {fieldType === 'button_group' && (
                <Row>
                  <Col sm='5'>
                    <CheckboxField
                      style={{ marginLeft: '5px' }}
                      checked={!!elementStyle.gradient_color}
                      onChange={(value) =>
                        handleStyleChange({
                          gradient_color: value ? '000000FF' : ''
                        })
                      }
                      text={
                        <PropertyLabel
                          label='Gradient'
                          {...labelData('gradient_color')}
                        />
                      }
                    />
                  </Col>
                  {elementStyle.gradient_color && (
                    <Col>
                      <FColorPicker
                        value={elementStyle.gradient_color}
                        onChange={(color: any) =>
                          handleStyleChange({ gradient_color: color })
                        }
                        colorPickerStyle={{ left: '-30px' }}
                      />
                    </Col>
                  )}
                </Row>
              )}
            </>
          )}
          {showPlaceholderStyles && (
            <Row>
              <Col sm='4'>
                <PropertyLabel
                  label='Placeholder'
                  {...labelData('placeholder_color')}
                />
              </Col>
              <Col>
                <FColorPicker
                  value={elementStyle.placeholder_color}
                  onChange={(color: any) =>
                    handleStyleChange({ placeholder_color: color })
                  }
                  colorPickerStyle={{ left: '-30px' }}
                />
              </Col>
            </Row>
          )}
          {fieldType !== 'rating' && (
            <>
              <Row>
                <Col>
                  <PropertyLabel
                    label='Border'
                    {...labelData(...BORDER_PROPS)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BorderStyleInput
                    elementStyles={elementStyle}
                    onComplete={handleStyleChange}
                  />
                </Col>
              </Row>
            </>
          )}
        </TabsContent>
        <TabsContent
          unstyled
          className='legacyTabsContent'
          value='HoverColors'
          style={{ padding: '0 1em' }}
        >
          {fieldType !== 'rating' && (
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle.hover_font_color}
                  onChange={(value) =>
                    handleStyleChange({
                      hover_font_color: value ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label={fontLabel}
                      {...labelData('hover_font_color')}
                    />
                  }
                />
              </Col>
              {elementStyle.hover_font_color && (
                <Col>
                  <FColorPicker
                    value={elementStyle.hover_font_color}
                    onChange={(color: any) =>
                      handleStyleChange({ hover_font_color: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
          )}
          {fieldType !== 'hex_color' && (
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle.hover_background_color}
                  onChange={(value) =>
                    handleStyleChange({
                      hover_background_color: value ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Background'
                      {...labelData('hover_background_color')}
                    />
                  }
                />
              </Col>
              {elementStyle.hover_background_color && (
                <Col>
                  <FColorPicker
                    value={elementStyle.hover_background_color}
                    onChange={(color: any) =>
                      handleStyleChange({ hover_background_color: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
          )}
          {showPlaceholderStyles && (
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle.hover_placeholder_color}
                  onChange={(value) =>
                    handleStyleChange({
                      hover_placeholder_color: value ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Placeholder'
                      {...labelData('hover_placeholder_color')}
                    />
                  }
                />
              </Col>
              {elementStyle.hover_placeholder_color && (
                <Col>
                  <FColorPicker
                    value={elementStyle.hover_placeholder_color}
                    onChange={(color: any) =>
                      handleStyleChange({ hover_placeholder_color: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
          )}
          {fieldType !== 'rating' && (
            <>
              <Row>
                <Col sm='5'>
                  <CheckboxField
                    checked={!!elementStyle.hover_border_top_color}
                    onChange={(value) =>
                      handleStyleChange({
                        hover_border_top_color: value ? '000000FF' : '',
                        hover_border_right_color: value ? '000000FF' : '',
                        hover_border_bottom_color: value ? '000000FF' : '',
                        hover_border_left_color: value ? '000000FF' : '',
                        hover_border_top_width: 0,
                        hover_border_right_width: 0,
                        hover_border_bottom_width: 0,
                        hover_border_left_width: 0,
                        hover_border_top_pattern: value ? 'solid' : '',
                        hover_border_right_pattern: value ? 'solid' : '',
                        hover_border_bottom_pattern: value ? 'solid' : '',
                        hover_border_left_pattern: value ? 'solid' : ''
                      })
                    }
                    text={
                      <PropertyLabel
                        label='Border'
                        {...labelData(...HOVER_BORDER_PROPS)}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {!!elementStyle.hover_border_top_color && (
                    <BorderStyleInput
                      elementStyles={elementStyle}
                      onComplete={handleStyleChange}
                      prefix='hover_'
                    />
                  )}
                </Col>
              </Row>
            </>
          )}
          {fieldType === 'button_group' && (
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle.hover_image_color}
                  onChange={(value) =>
                    handleStyleChange({
                      hover_image_color: value ? 'black' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Image'
                      {...labelData('hover_image_color')}
                    />
                  }
                />
              </Col>
              {elementStyle.hover_image_color && (
                <Col>
                  <DropdownField
                    selected={elementStyle.hover_image_color}
                    onChange={(e: any) =>
                      handleStyleChange({ hover_image_color: e.target.value })
                    }
                    options={[
                      { value: 'black', display: 'Black' },
                      { value: 'white', display: 'White' }
                    ]}
                  />
                </Col>
              )}
            </Row>
          )}
        </TabsContent>
        <TabsContent
          unstyled
          className='legacyTabsContent'
          value='SelectedColors'
          style={{ padding: '0 1em' }}
        >
          {fieldType !== 'rating' && (
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle.selected_font_color}
                  onChange={(value) =>
                    handleStyleChange({
                      selected_font_color: value ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label={fontLabel}
                      {...labelData('selected_font_color')}
                    />
                  }
                />
              </Col>
              {elementStyle.selected_font_color && (
                <Col>
                  <FColorPicker
                    value={elementStyle.selected_font_color}
                    onChange={(color: any) =>
                      handleStyleChange({ selected_font_color: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
          )}
          {fieldType !== 'hex_color' && (
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle.selected_background_color}
                  onChange={(isChecked) =>
                    handleStyleChange({
                      selected_background_color: isChecked ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Background'
                      {...labelData('selected_background_color')}
                    />
                  }
                />
              </Col>
              {elementStyle.selected_background_color && (
                <Col>
                  <FColorPicker
                    value={elementStyle.selected_background_color}
                    onChange={(color: any) =>
                      handleStyleChange({ selected_background_color: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
          )}
          {showPlaceholderStyles && (
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle.selected_placeholder_color}
                  onChange={(isChecked) =>
                    handleStyleChange({
                      selected_placeholder_color: isChecked ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Placeholder'
                      {...labelData('selected_placeholder_color')}
                    />
                  }
                />
              </Col>
              {elementStyle.selected_placeholder_color && (
                <Col>
                  <FColorPicker
                    value={elementStyle.selected_placeholder_color}
                    onChange={(color: any) =>
                      handleStyleChange({ selected_placeholder_color: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
          )}
          {fieldType !== 'rating' && (
            <>
              <Row>
                <Col sm='5'>
                  <CheckboxField
                    checked={!!elementStyle.selected_border_top_color}
                    onChange={(value) =>
                      handleStyleChange({
                        selected_border_top_color: value ? '000000FF' : '',
                        selected_border_right_color: value ? '000000FF' : '',
                        selected_border_bottom_color: value ? '000000FF' : '',
                        selected_border_left_color: value ? '000000FF' : '',
                        selected_border_top_width: 0,
                        selected_border_right_width: 0,
                        selected_border_bottom_width: 0,
                        selected_border_left_width: 0,
                        selected_border_top_pattern: value ? 'solid' : '',
                        selected_border_right_pattern: value ? 'solid' : '',
                        selected_border_bottom_pattern: value ? 'solid' : '',
                        selected_border_left_pattern: value ? 'solid' : ''
                      })
                    }
                    text={
                      <PropertyLabel
                        label='Border'
                        {...labelData(...SELECTED_BORDER_PROPS)}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {!!elementStyle.selected_border_top_color && (
                    <BorderStyleInput
                      elementStyles={elementStyle}
                      onComplete={handleStyleChange}
                      prefix='selected_'
                    />
                  )}
                </Col>
              </Row>
            </>
          )}
          {fieldType === 'button_group' && (
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle.selected_image_color}
                  onChange={(value) =>
                    handleStyleChange({
                      selected_image_color: value ? 'black' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Image'
                      {...labelData('selected_image_color')}
                    />
                  }
                />
              </Col>
              {elementStyle.selected_image_color && (
                <Col>
                  <DropdownField
                    selected={elementStyle.selected_image_color}
                    onChange={(e: any) =>
                      handleStyleChange({
                        selected_image_color: e.target.value
                      })
                    }
                    options={[
                      { value: 'black', display: 'Black' },
                      { value: 'white', display: 'White' }
                    ]}
                  />
                </Col>
              )}
            </Row>
          )}
        </TabsContent>
        <TabsContent
          unstyled
          className='legacyTabsContent'
          value='DisabledColors'
          style={{ padding: '0 1em' }}
        >
          <Row>
            <Col sm='5'>
              <CheckboxField
                checked={!!elementStyle.disabled_background_color}
                onChange={(isChecked) =>
                  handleStyleChange({
                    disabled_background_color: isChecked ? 'FFFFFFFF' : ''
                  })
                }
                text={
                  <PropertyLabel
                    label='Background'
                    {...labelData('disabled_background_color')}
                  />
                }
              />
            </Col>
            {elementStyle.disabled_background_color && (
              <Col>
                <FColorPicker
                  value={elementStyle.disabled_background_color}
                  onChange={(color: any) =>
                    handleStyleChange({ disabled_background_color: color })
                  }
                  colorPickerStyle={{ left: '-30px' }}
                />
              </Col>
            )}
          </Row>
        </TabsContent>
        {fieldType === 'payment_method' && (
          <TabsContent
            unstyled
            className='legacyTabsContent'
            value='CompletedColors'
            style={{ padding: '0 1em' }}
          >
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle.completed_font_color}
                  onChange={(value) =>
                    handleStyleChange({
                      completed_font_color: value ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Font'
                      {...labelData('completed_font_color')}
                    />
                  }
                />
              </Col>
              {elementStyle.completed_font_color && (
                <Col>
                  <FColorPicker
                    value={elementStyle.completed_font_color}
                    onChange={(color: any) =>
                      handleStyleChange({ completed_font_color: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle.completed_placeholder_color}
                  onChange={(isChecked) =>
                    handleStyleChange({
                      completed_placeholder_color: isChecked ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Placeholder'
                      {...labelData('completed_placeholder_color')}
                    />
                  }
                />
              </Col>
              {elementStyle.completed_placeholder_color && (
                <Col>
                  <FColorPicker
                    value={elementStyle.completed_placeholder_color}
                    onChange={(color: any) =>
                      handleStyleChange({ completed_placeholder_color: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
          </TabsContent>
        )}
      </Tabs>
    </>
  );
}

export default memo(FieldStylePanel);
