import { useAppSelector } from '../../../hooks';
import SettingsContainer from '../../../components/SettingsContainer';
import { Link, useHistory, useParams } from 'react-router-dom';
import useFeatheryRedux from '../../../redux';
import { uniqifyKey } from '../../../utils/format';
import { onFreePlan } from '../../../components/PricingPlans/plans';
import { InfoCard } from '../../../components/Core';
import LockoutButton from '../../../components/Core/Button/LockoutButton';
import useDraftForm from '../../../utils/useDraftForm';

const PromotionSection = ({ title }: any) => {
  const { formId } = useParams<{ formId: string }>();
  const panels = useAppSelector((state) => state.panels.panels);
  const panel = panels[formId];
  const org = useAppSelector((state) => state.accounts.organization);

  const history = useHistory();
  const {
    createPanel,
    getPanelThemeAssetUse,
    getThemes,
    getIntegrations,
    toasts: { addToast }
  } = useFeatheryRedux();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const oldKey = panel.key;
    const allKeys = Object.values(panels).map((panel) => (panel as any).key);
    const newKey = uniqifyKey(oldKey, allKeys);
    const response = await createPanel({
      key: newKey,
      copy_panel_key: oldKey,
      link_fields: false,
      copy_servar_suffix: '',
      promotable: true
    });
    await Promise.all([
      getPanelThemeAssetUse({ cached: false }),
      getThemes(),
      getIntegrations()
    ]);
    addToast({
      text: (
        <>
          <strong>{newKey}</strong> was created.
        </>
      )
    });
    history.push(`/forms/${response.id}`);
  };

  const { draftSteps, draftTheme } = useDraftForm();
  const draftExists = draftSteps || draftTheme;

  const isRollback =
    panel && panel.promote_from && !panel.promote_live && !panel.promote_to;
  return (
    <SettingsContainer title={title}>
      <InfoCard
        showCloseIcon={false}
        text={
          <div className='flex gap-4 items-center'>
            Form Promotion allows a copy of a form to send its updates to the
            original form it was copied from. Ex: promoting staging to
            production.
            <a
              href='https://docs.feathery.io/platform/build-forms/dev-and-staging-environments'
              target='_blank'
              rel='noreferrer'
              className='text-nowrap'
            >
              Learn&nbsp;more
            </a>
          </div>
        }
      />
      {panel.promote_to && (
        <div className='field-group'>
          <p className='form=label'>
            {panel.promote_live ? 'Rollback Form' : 'Promotes To'}
          </p>
          <Link to={`/forms/${panel.promote_to}`}>
            {panels[panel.promote_to]?.key}
          </Link>
        </div>
      )}
      <div className='field-group'>
        <p className='form-label'>
          {isRollback ? 'Rolls Back To' : 'Receives Promotion From'}
        </p>
        {panel.promote_from ? (
          <Link to={`/forms/${panel.promote_from}`}>
            {panels[panel.promote_from]?.key}
          </Link>
        ) : (
          <>
            <LockoutButton
              className='!ml-0 !mt-0 !mb-0 !px-4 !py-3'
              disabled={onFreePlan(org) || panel.promote_from || draftExists}
              onClick={handleSubmit}
            >
              Create Promotable Copy
            </LockoutButton>
            {onFreePlan(org) ? (
              <p style={{ color: 'gray', marginTop: 6, maxWidth: '40ch' }}>
                You must be on a paid plan to use this feature.
              </p>
            ) : draftExists ? (
              <p style={{ color: 'gray', marginTop: 6, maxWidth: '40ch' }}>
                There are unpublished draft changes on this form. Publish or
                discard them to make a promotable copy.
              </p>
            ) : null}
          </>
        )}
      </div>
    </SettingsContainer>
  );
};

export default PromotionSection;
