import {
  AIExtractionEntry,
  AIExtractionEntryValue,
  AIExtractionRun,
  AIQuestionGroup
} from './types';
import { BACKEND_ENV } from '../../api/utils';

export const getValueId = (
  entry: AIExtractionEntry | string,
  index: number
) => {
  if (typeof entry === 'string') {
    return `${entry}-${index}`;
  }

  return `${entry.id}-${index}`;
};

const getEntryValue = (
  entry: AIExtractionEntry,
  index: number
): AIExtractionEntryValue => {
  const {
    id,
    fieldId,
    value,
    xCoordinates,
    yCoordinates,
    widths,
    heights,
    pageNumbers
  } = entry;

  return {
    id,
    fieldId,
    value: value[index],
    xCoordinate: xCoordinates[index],
    yCoordinate: yCoordinates[index],
    width: widths[index],
    height: heights[index],
    pageNumber: pageNumbers[index],
    source: entry.sources?.[index],
    index
  };
};

export const splitEntryValues = (
  entry: AIExtractionEntry,
  relatedEntries: AIExtractionEntry[] = []
): AIExtractionEntryValue[] => {
  const {
    id,
    fieldId,
    value,
    xCoordinates,
    yCoordinates,
    widths,
    heights,
    pageNumbers,
    sources
  } = entry;

  return value.map((_value, index) => {
    const entryValue: AIExtractionEntryValue = {
      id,
      fieldId,
      value: _value,
      xCoordinate: xCoordinates[index],
      yCoordinate: yCoordinates[index],
      width: widths[index],
      height: heights[index],
      pageNumber: pageNumbers[index],
      source: sources?.[index],
      index,
      relatedEntries: {}
    };

    if (relatedEntries.length > 0) {
      entryValue.relatedEntries = relatedEntries.reduce((acc, relatedEntry) => {
        return {
          ...acc,
          [getValueId(relatedEntry, index)]: getEntryValue(relatedEntry, index)
        };
      }, {});
    }

    return entryValue;
  });
};

export const parseRawRunData = (run: any) => {
  const parseDisplayPDF = (
    displayPDFUrl: string,
    fallback?: string
  ): { url: string; name: string } | null => {
    if (!displayPDFUrl && !fallback) {
      return null;
    }

    if (!displayPDFUrl) {
      if (fallback) {
        return parseDisplayPDF(fallback);
      }

      return { url: '', name: '' };
    }

    const ext = displayPDFUrl.split('.').at(-1);
    if (ext?.toLowerCase() !== 'pdf' && !fallback) {
      return { url: '', name: '' };
    }

    const path =
      displayPDFUrl.startsWith('/') || !displayPDFUrl.startsWith('http')
        ? displayPDFUrl
        : new URL(displayPDFUrl).pathname;

    const displayPdf = {
      url: displayPDFUrl,
      name: path.split('/').at(-1) as string
    };

    if (BACKEND_ENV === 'local')
      displayPdf.url = `http://localhost:8006${displayPdf.url}`;

    return displayPdf;
  };

  const parseEntry = (entry: any) => {
    return {
      id: entry.id,
      fieldId: entry.field_id,
      value: entry.value,
      xCoordinates: entry.x_coordinates,
      yCoordinates: entry.y_coordinates,
      widths: entry.widths,
      heights: entry.heights,
      pageNumbers: entry.page_numbers,
      sources: entry.sources,
      committed: entry.committed ?? false
    } as AIExtractionEntry;
  };

  const parseEntity = (entity: any) => {
    return {
      name: entity.name,
      criteria: entity.criteria,
      fieldId: entity.field_id
    };
  };

  const parseQuestionGroup = (questionGroup: any) => {
    return {
      id: questionGroup.id,
      questionType: questionGroup.question_type,
      entities: questionGroup.entities.map((entity: any, i: number) =>
        parseEntity({ ...entity, field_id: questionGroup.field_ids[i] })
      ),
      entries: questionGroup.entries.map(parseEntry),
      criteria: questionGroup.criteria,
      fieldIds: questionGroup.field_ids
    } as AIQuestionGroup;
  };

  return {
    id: run.id,
    displayPDF: parseDisplayPDF(run.display_pdf, run.file_sources?.[0].url),
    questionGroups: run.question_groups.map(parseQuestionGroup),
    emailContent: run.email_content,
    success: run.success,
    errorMessage: run.error_message,
    approved: run.approved,
    approver: run.approver,
    fetchingBoundingBoxes: run.fetching_bounding_boxes,
    boundingBoxSuccess: run.bounding_box_success,
    boundingBoxErrorMessage: run.bounding_box_error_message
  } as AIExtractionRun;
};

export const getAllEntries = (run: AIExtractionRun) => {
  return run.questionGroups.reduce((acc, questionGroup) => {
    return [...acc, ...questionGroup.entries];
  }, [] as AIExtractionEntry[]);
};
