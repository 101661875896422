import { useEffect, useState, useCallback } from 'react';
import { TextField } from '../../../Core';
import { Button } from '../../../Core/Button/button';
import { InfoIcon, TrashIcon } from '../../../Icons';
import styles from './styles.module.scss';
import { Tooltip } from '../../../Core/Tooltip/Tooltip';
import { JSONEditor } from '../../../JSONEditor';

type KeyValuePair = [string, string];

export interface KeyComponentProps {
  value: string;
  index: number;
  onComplete?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
}

const BasicKeyComponent = ({
  value,
  onComplete = () => {},
  disabled = false
}: KeyComponentProps) => (
  <TextField
    className={styles.textField}
    placeholder='Key'
    value={value}
    onComplete={onComplete}
    disabled={disabled}
  />
);

export interface ValueComponentProps
  extends React.ComponentPropsWithoutRef<typeof TextField> {
  keyValue?: string;
  value: string;
  index: number;
  onComplete?: (value: string) => void;
  disabled?: boolean;
  error?: boolean;
}

export const BasicValueComponent = ({
  value,
  onComplete = () => {},
  disabled = false,
  error = false,
  ...props
}: ValueComponentProps) => (
  <TextField
    className={styles.textField}
    placeholder='Value'
    value={value}
    onComplete={onComplete}
    disabled={disabled}
    error={error}
    errorMessage={''}
    {...props}
  />
);

interface KeyValueInputProps {
  value: Record<string, string>;
  onChange: (value: Record<string, string>) => void;
  addButtonText?: string;
  readOnlyValues?: Record<string, string>;
  readOnlyInfo?: string;
  editableKeys?: boolean;
  allowEmptyKeys?: boolean;
  hideAddButton?: boolean;
  hideDeleteButton?: boolean;
  hideBulkEdit?: boolean;

  KeyComponent?: React.ComponentType<KeyComponentProps>;
  ValueComponent?: React.ComponentType<ValueComponentProps>;
}

export const KeyValueInput = ({
  value,
  onChange,
  addButtonText = 'Add',
  readOnlyValues = {},
  readOnlyInfo,
  allowEmptyKeys = false,
  editableKeys = true,
  hideAddButton = false,
  hideDeleteButton = false,
  hideBulkEdit = true,
  KeyComponent = BasicKeyComponent,
  ValueComponent = BasicValueComponent
}: KeyValueInputProps) => {
  const [isBulkMode, setIsBulkMode] = useState(false);
  const [bulkValidationError, setBulkValidationError] = useState<string | null>(
    null
  );

  const [pairs, setPairs] = useState<KeyValuePair[]>(() => {
    const entries = Object.entries(value);
    return entries.length ? entries : [['', '']];
  });

  useEffect(() => {
    // special logic to allow state updates AND allow empty pairs while editing
    const entries = Object.entries(value);
    const entriesJson = JSON.stringify(entries);
    const pairsJson = JSON.stringify(pairs.filter(([key]) => key));

    if (entriesJson !== pairsJson) {
      setPairs(entries.length ? entries : [['', '']]);
    }
  }, [value]);

  const notifyChange = useCallback(
    (newPairs: KeyValuePair[]) => {
      // Only notify parent of valid pairs
      const validPairs = newPairs.filter(([key]) => key);
      const newValue = Object.fromEntries(validPairs);

      if (JSON.stringify(newValue) !== JSON.stringify(value)) {
        onChange(newValue);
      }
    },
    [onChange, value]
  );

  const handleAdd = useCallback(() => {
    // Don't notify parent when adding empty pair
    setPairs((currentPairs) => [...currentPairs, ['', '']]);
  }, []);

  const handleChange = useCallback(
    (index: number, field: 'key' | 'value', newValue: string) => {
      setPairs((currentPairs) => {
        const newPairs = currentPairs.map((pair, i) =>
          i === index
            ? ([
                field === 'key' ? newValue : pair[0],
                field === 'value' ? newValue : pair[1]
              ] as KeyValuePair)
            : pair
        );

        const changedPair = newPairs[index];
        if (changedPair[0]) {
          notifyChange(newPairs);
        }

        return newPairs;
      });
    },
    [notifyChange]
  );

  const handleDelete = useCallback(
    (index: number) => {
      setPairs((currentPairs) => {
        const newPairs = currentPairs.filter((_, i) => i !== index);
        const finalPairs = newPairs.length
          ? newPairs
          : ([['', '']] as KeyValuePair[]);
        notifyChange(finalPairs);
        return finalPairs;
      });
    },
    [notifyChange]
  );

  const bulkText = JSON.stringify(
    {
      ...readOnlyValues,
      ...Object.fromEntries(pairs.filter(([key]) => allowEmptyKeys || !!key))
    },
    null,
    2
  );

  const handleBulkChange = (code?: string) => {
    if (!code) {
      onChange({});
      setBulkValidationError(null);
      return;
    }

    try {
      const parsed = JSON.parse(code);

      if (typeof parsed !== 'object' || Array.isArray(parsed)) {
        setBulkValidationError('Input must be a JSON object');
        return;
      }

      const invalidValues = Object.values(parsed).filter(
        (value) => value !== null && typeof value !== 'string'
      );

      if (invalidValues.length > 0) {
        setBulkValidationError('All values must be strings.');
        return;
      }

      setBulkValidationError(null);
      onChange(parsed);
    } catch (e) {
      setBulkValidationError('Invalid JSON format');
    }
  };

  return (
    <div className={styles.keyValueInputContainer}>
      {isBulkMode ? (
        <div className='space-y-2'>
          <JSONEditor
            height='300px'
            code={bulkText}
            placeholder="// Enter key-value pairs as JSON:
{
  'key': 'value'
}"
            onChange={handleBulkChange}
          />
          <div className={'flex justify-end items-center pl-1 pr-5'}>
            {!hideBulkEdit && (
              <Button
                variant='gray'
                size='sm'
                onClick={() => {
                  setIsBulkMode(!isBulkMode);
                  setBulkValidationError(null);
                }}
              >
                {isBulkMode
                  ? 'Switch to Individual Edit'
                  : 'Switch to Bulk Edit'}
              </Button>
            )}
          </div>
          {bulkValidationError && (
            <div className='text-red-500 mt-2'>{bulkValidationError}</div>
          )}
        </div>
      ) : (
        <>
          {Object.entries(readOnlyValues || {}).map(
            ([key, value]: any, index) => (
              <div
                key={`${key}-${value}-${index}`}
                className={styles.keyValueInput}
                style={{
                  gridTemplateColumns: !readOnlyInfo
                    ? '1fr auto 1fr'
                    : '1fr auto 1fr auto'
                }}
              >
                <KeyComponent index={index} value={key} disabled />
                <div>=</div>
                <ValueComponent index={index} value={value} disabled />
                {readOnlyInfo && (
                  <Tooltip content={readOnlyInfo}>
                    <div>
                      <InfoIcon width={16} height={16} color='var(--grey-80)' />
                    </div>
                  </Tooltip>
                )}
              </div>
            )
          )}
          {pairs.map(([key, value], index) => (
            <div
              key={`${key}-${value}-${index}`}
              className={styles.keyValueInput}
              style={{
                gridTemplateColumns: hideDeleteButton
                  ? '1fr auto 1fr'
                  : '1fr auto 1fr auto'
              }}
            >
              <KeyComponent
                value={key}
                index={index}
                disabled={!editableKeys}
                onComplete={(key: any) =>
                  editableKeys && handleChange(index, 'key', key)
                }
              />
              <div>=</div>
              <ValueComponent
                keyValue={key}
                value={value}
                index={index}
                onComplete={(value: any) => handleChange(index, 'value', value)}
                disabled={!allowEmptyKeys && !key}
              />
              {!hideDeleteButton && (
                <button onClick={() => handleDelete(index)}>
                  <TrashIcon width={16} height={16} />
                </button>
              )}
            </div>
          ))}
          <div className={'flex justify-between items-center pl-1 pr-5'}>
            {!hideAddButton && (
              <Button
                variant='text-primary'
                size='sm'
                onClick={(e) => {
                  e.preventDefault();
                  handleAdd();
                }}
              >
                + {addButtonText}
              </Button>
            )}
            {!hideBulkEdit && (
              <Button
                variant='gray'
                size='sm'
                onClick={() => {
                  setIsBulkMode(!isBulkMode);
                  setBulkValidationError(null);
                }}
              >
                {isBulkMode
                  ? 'Switch to Individual Edit'
                  : 'Switch to Bulk Edit'}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
