import { IAPIActionState } from '../../pages/types';
import type { Template } from '../types';

export const EMONEY_GET_CLIENTS: Template = {
  id: 'emoney_get_clients',
  display: 'Get Clients',
  configuration: {
    name: { type: 'textfield', label: 'Connector Name' }
  },
  defaultState: {
    name: 'EMoney Get Clients',
    template: 'emoney_get_clients',
    url: 'https://api-externalbeta2.emaplan.com/public/v2/clients',
    method: 'GET',
    headers: {
      Authorization: 'Bearer {{emoney_token}}',
      apikey: '{{emoney_api_token}}'
    },
    body: '',
    params: {},
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  },
  loadConfig: (state) => {
    return { name: state.name };
  },
  saveConfig: (state, configValues) => {
    const { name } = configValues;
    const newValues: Partial<IAPIActionState> = { name };

    return {
      ...state,
      ...newValues
    };
  }
};
