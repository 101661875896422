import React, { useEffect, useState } from 'react';
import Dialog from '../../Dialog';
import useQuikFieldConfig from '../../Panels/PropertiesPanel/components/useQuikFieldConfig';
import { DynamicTextTooltip, TextField } from '../../Core';
import styles from './styles.module.scss';
import classNames from 'classnames';
import ruleStyles from '../../NavigationRules/styles.module.scss';
import { SearchIcon } from '../../Icons';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Core/Tabs';
import Table from '../../Table';
import { Positive } from '../../Core/Button';

export interface QuikFieldRow {
  id: string;
  field: string;
  role: string;
}

export interface QuikTableColumn {
  key: string;
  name: string;
}

interface QuikFieldSelectorProps {
  showQuikFieldSelector: any;
  setShowQuikFieldSelector: any;
  servarMeta: any;
  elementType: string;
  isRepeatable?: boolean;
  onSelect: (data: any) => void;
  disabled?: boolean;
}

const QuikFieldSelector: React.FC<QuikFieldSelectorProps> = ({
  showQuikFieldSelector,
  setShowQuikFieldSelector,
  servarMeta,
  elementType,
  isRepeatable = false,
  onSelect,
  disabled = false
}) => {
  const { getQuikFieldSelectorTabData } = useQuikFieldConfig(servarMeta);

  const [selectedQuikFieldId, setSelectedQuikFieldId] = useState('');
  const [quikFieldSearchFilter, setQuikFieldSearchFilter] = useState('');

  const quikFieldSelectorTableTabs = getQuikFieldSelectorTabData(
    onSelect,
    elementType,
    isRepeatable
  );

  const filteredTabs = quikFieldSelectorTableTabs.map((tab) => ({
    ...tab,
    rows: tab.rows.filter(
      (row: QuikFieldRow) =>
        !quikFieldSearchFilter || row.field.includes(quikFieldSearchFilter)
    )
  }));

  const [currentTab, setCurrentTab] = useState(
    filteredTabs.length > 0 ? filteredTabs[0].key : ''
  );

  useEffect(() => {
    if (
      filteredTabs.length > 0 &&
      !filteredTabs.some((tab) => tab.key === currentTab)
    ) {
      setCurrentTab(filteredTabs[0].key);
    }
  }, [filteredTabs, currentTab]);

  useEffect(() => {
    // clear quik field id in metadata when repeatable is toggled on/off
    if (!servarMeta.quik_field_id) return;

    if (servarMeta.quik_field_id.match(/^\d/) && isRepeatable) {
      onSelect('');
    } else if (!servarMeta.quik_field_id.match(/^\d/) && !isRepeatable) {
      onSelect('');
    }
  }, [isRepeatable, servarMeta.quik_field_id, onSelect]);

  return (
    <div>
      <DynamicTextTooltip text={servarMeta.quik_field_id} maxLength={12}>
        <button
          disabled={disabled}
          className={classNames(
            ruleStyles.ruleTarget,
            ruleStyles.fieldTarget,
            servarMeta.quik_field_id && ruleStyles.selected
          )}
          onClick={(e) => {
            e.preventDefault();
            setShowQuikFieldSelector(true);
          }}
        >
          <span className={ruleStyles.fieldSpan}>
            {servarMeta.quik_field_id || 'Select'}
          </span>
        </button>
      </DynamicTextTooltip>
      <Dialog
        isOpen={showQuikFieldSelector}
        onClose={() => setShowQuikFieldSelector(false)}
        shouldShowCloseIcon
        title='Select Quik Field'
        size='xlg'
      >
        <div className={styles.fieldSelectorContainer}>
          <div
            className={classNames('search-wrap', styles.fieldSelectorSearch)}
          >
            <SearchIcon className='search-icon' />
            <TextField
              className='inp list-view-search'
              value={quikFieldSearchFilter}
              placeholder='Search'
              onComplete={(val: string) => setQuikFieldSearchFilter(val)}
              style={{ height: '30px' }}
            />
          </div>
          <Tabs value={currentTab} onValueChange={setCurrentTab}>
            <TabsList className='legacyTabsList' unstyled>
              {quikFieldSelectorTableTabs.map((tab) => (
                <TabsTrigger
                  key={tab.key}
                  value={tab.key}
                  className='legacyTabsTrigger'
                  unstyled
                >
                  {tab.label}
                </TabsTrigger>
              ))}
            </TabsList>
            {filteredTabs.map((tab) => (
              <TabsContent
                key={tab.key}
                value={tab.key}
                className='legacyTabsContent'
                unstyled
              >
                <Table
                  name={tab.label}
                  data={tab.rows}
                  columns={tab.columns}
                  showSelected={!!tab.onRowSelect}
                  initSelectId={tab.selectedRowId}
                  onSelect={(row: QuikFieldRow) => {
                    setSelectedQuikFieldId(row.field);
                  }}
                  type='modal'
                  sortable={true}
                />
              </TabsContent>
            ))}
          </Tabs>
          <div className={classNames('text-center', styles.buttonContainer)}>
            <div className='flex items-center gap-3'>
              <Positive
                onClick={async () => {
                  setShowQuikFieldSelector(false);
                  onSelect(selectedQuikFieldId);
                }}
              >
                Select
              </Positive>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default QuikFieldSelector;
