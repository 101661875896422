// A combobox is like a createable multiselect, but no option menu.
import classNames from 'classnames';
import React, { KeyboardEventHandler, memo, useMemo } from 'react';

import CreatableSelect from 'react-select/creatable';
import styles from './styles.module.scss';

const components = {
  DropdownIndicator: null
} as const;

type Option = {
  label: string;
  value: string;
};

const createOption = (label: string): Option => ({
  label,
  value: label
});

const getClassNames = (overrides: any = {}) => {
  const cn = classNames;
  const o = overrides;

  return {
    className: cn(styles.multiFieldDropdown, o.className),
    control: (state: any) =>
      cn(
        styles.multiFieldControl,
        typeof o.multiFieldControl === 'function'
          ? o.multiFieldControl(state)
          : o.multiFieldControl,
        {
          [styles.multiFieldControlHovered]: state.isHovered,
          [styles.multiFieldControlFocused]: state.isFocused
        },
        o.multiFieldControl
      ),
    container: () => cn(styles.multiFieldContainer, o.multiFieldContainer),
    valueContainer: () =>
      cn(styles.multiFieldValueContainer, o.multiFieldValueContainer),
    multiValue: () => cn(styles.multiFieldMultiValue, o.multiFieldMultiValue),
    multiValueLabel: () =>
      cn(styles.multiFieldMultiValueLabel, o.multiFieldMultiValueLabel),
    multiValueRemove: (state: any) =>
      cn(
        styles.multiFieldMultiValueRemove,
        {
          [styles.multiFieldMultiValueRemoveHovered]: state.isHovered
        },
        o.multiFieldMultiValueRemove
      ),
    clearIndicator: () =>
      cn(styles.multiFieldClearIndicator, o.multiFieldClearIndicator),
    indicatorSeparator: () =>
      cn(styles.multiFieldIndicatorSeparator, o.multiFieldIndicatorSeparator),
    indicatorsContainer: () =>
      cn(styles.multiFieldIndicatorsContainer, o.multiFieldIndicatorsContainer),
    inputContainer: () => cn(o.multiFieldInputContainer),
    input: () => cn(o.multiFieldInput),
    placeholder: () => cn(o.multiFieldPlaceholder)
  };
};

type OmittedProps =
  | 'value'
  | 'onChange'
  | 'menuIsOpen'
  | 'onInputChange'
  | 'onKeyDown'
  | 'isMulti'
  | 'classNames';

interface ComboboxProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof CreatableSelect>,
    OmittedProps
  > {
  value?: string[];
  onChange: (value: string[]) => void;
  classNames?: Record<string, string | ((state: any) => void)>;
}

const Combobox = ({
  value: value = [],
  onChange,
  classNames: _classNames = {},
  className: _className = '',
  placeholder = 'Type something and press enter...',
  ...delegatedProps
}: ComboboxProps) => {
  const [inputValue, setInputValue] = React.useState('');
  const renderValue = useMemo(() => value.map(createOption), [value]);

  const addValue = () => {
    if (!inputValue || value.includes(inputValue)) return;
    onChange([...value, inputValue]);
    setInputValue('');
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        addValue();
        event.preventDefault();
    }
  };

  const { className = '', ...classNameOverrides } = getClassNames({
    ...(_classNames || {}),
    className: _className ?? ''
  });

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={
        ((newValue: Option[]) =>
          onChange(newValue.map((v: Option) => v.value))) as any
      }
      onInputChange={(val: string, { action }: any) => {
        // do not clear input on blur or close
        if (action !== 'input-blur' && action !== 'menu-close') {
          setInputValue(val);
        }
      }}
      onBlur={() => addValue()}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      value={renderValue}
      classNamePrefix='multi-field'
      className={className}
      classNames={classNameOverrides}
      {...delegatedProps}
    />
  );
};

export default memo(Combobox);
