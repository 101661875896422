import { memo, useCallback, useEffect, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import Dialog from '../../Dialog';
import { Neutral, Positive } from '../../Core/Button';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import useFeatheryRedux from '../../../redux';
import { DropdownField } from '../../Core';
import Label from '../../Dialog/Label';
import { useAppSelector } from '../../../hooks';
import useDraftForm from '../../../utils/useDraftForm';
import { deepEquals } from '../../../utils/core';
import { PlusIcon, TrashIcon } from '../../Icons';

import styles from './styles.module.scss';

export type ColumnsPropType = 'result_columns' | 'task_list_result_columns';

type EditTableViewModalProps = {
  panel?: any;
  show: boolean;
  close: () => void;
  onEdit: () => void;
  columnsProp?: ColumnsPropType;
};

function EditTableViewModal({
  panel,
  show,
  close,
  onEdit,
  columnsProp = 'result_columns'
}: EditTableViewModalProps) {
  const { editOrganization } = useFeatheryRedux();
  const org = useAppSelector((state) => state.accounts.organization);

  const steps = Object.values(useDraftForm().steps ?? {});
  const servars = useAppSelector(
    (state) => state.fields.servars ?? [],
    deepEquals
  );

  let servarOptions: any[];
  const servarMap: Record<string, string> = {};
  if (panel) {
    steps.forEach((step: any) =>
      step.servar_fields.forEach((field: any) => {
        const servar = field.servar;
        servarMap[servar.id] = servar.key;
      })
    );
    servarOptions = Object.entries(servarMap).map(([servarId, servarKey]) => ({
      display: servarKey,
      value: servarId
    }));
  } else {
    servarOptions = servars.map((servar) => {
      servarMap[servar.id] = servar.key;
      return {
        display: servar.key,
        value: servar.id
      };
    });
  }
  servarOptions = servarOptions.sort((a: any, b: any) =>
    a.display > b.display ? 1 : -1
  );

  const hiddenFieldOptions = useAppSelector(
    (state) =>
      (state.fields.hiddenFields ?? [])
        .map((hf: any) => ({
          display: hf.key,
          value: hf.id
        }))
        .sort((a: any, b: any) => (a.display > b.display ? 1 : -1)),
    deepEquals
  );

  const curColumns = panel ? panel[columnsProp] : org ? org[columnsProp] : [];
  const [columns, setColumns] =
    useState<{ column: string; type: string }[]>(curColumns);
  useEffect(() => setColumns(curColumns), [show]);

  const updateColumn = (index: number, newColumn: any) => {
    const newColumns = JSON.parse(JSON.stringify(columns));
    newColumns[index] = newColumn;
    setColumns(newColumns);
  };
  const addColumn = () => {
    const newColumns = JSON.parse(JSON.stringify(columns));
    newColumns.push({ column: '', type: '' });
    setColumns(newColumns);
  };
  const deleteColumn = (index: number) => {
    const newColumns = JSON.parse(JSON.stringify(columns));
    newColumns.splice(index, 1);
    setColumns(newColumns);
  };

  const { editPanel } = useFeatheryRedux();

  const submit = useCallback(async () => {
    const filtered = columns.filter((column) => column.column);
    if (filtered.length === 0) return;

    if (panel) {
      await editPanel({
        panelId: panel.id,
        [columnsProp]: filtered
      });
    } else {
      await editOrganization({
        [columnsProp]: filtered
      });
    }

    onEdit();
    close();
  }, [panel?.id, columns]);

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  let collabOptions: any[] = [];
  if (panel?.collaboration_enabled) {
    collabOptions = [
      {
        display: 'Pending Collaborator',
        value: 'pending_collaborator'
      },
      {
        display: 'Pending Collaborator Role',
        value: 'pending_collaborator_label'
      }
    ];
  }

  const defaultResultColumns = {
    result_columns: [{ display: 'Started At', value: 'created_at' }],
    task_list_result_columns: [
      { display: 'Form', value: 'panel_key' },
      { display: 'User ID', value: 'fuser_key' },
      { display: 'Collaborator', value: 'collaborator_email' },
      { display: 'Role', value: 'template_label' },
      { display: 'Status', value: 'status' },
      { display: 'Updated At', value: 'updated_at' },
      { display: 'Completed At', value: 'completed_at' }
    ]
  };
  const defaultColumns = [
    'user_id',
    'submission_status',
    'last_updated',
    'pending_collaborator',
    'pending_collaborator_label',
    ...Object.values(defaultResultColumns)
      .flatMap((c) => c)
      .map((c) => c.value)
  ];

  const additionalOptions = panel
    ? [
        {
          display: 'Submission Status',
          value: 'submission_status'
        },
        ...collabOptions,
        { display: 'Last Updated', value: 'last_updated' }
      ]
    : defaultResultColumns[columnsProp];

  return (
    <Dialog isOpen={show} title='Edit Table View' size='sm' onClose={close}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        {columns.map((column, index) => {
          return (
            <div className={styles.columnRow} key={index}>
              <Label className={styles.columnLabel}>Column {index + 1}</Label>
              <DropdownField
                className={styles.columnDropdown}
                selected={column.column}
                onChange={(event: any) => {
                  const newVal = event.target.value;
                  const newColumn: Record<string, string> = {
                    column: newVal
                  };
                  if (defaultColumns.includes(newVal))
                    newColumn.type = 'default';
                  else if (newVal in servarMap) newColumn.type = 'servar';
                  else newColumn.type = 'hidden';
                  updateColumn(index, newColumn);
                }}
                options={[
                  { display: '', value: '', hidden: true },
                  { display: 'User ID', value: 'user_id' },
                  ...additionalOptions,
                  ...servarOptions,
                  ...hiddenFieldOptions
                ].filter(
                  (option: any) =>
                    option &&
                    (option.value === column.column ||
                      !columns
                        .map(({ column }) => column)
                        .includes(option.value))
                )}
              />
              <TrashIcon
                className={styles.trashIcon}
                height={16}
                width={16}
                onClick={() => deleteColumn(index)}
              />
            </div>
          );
        })}
        <PlusIcon onClick={addColumn} className={styles.addColumn} />
        <div className='dialog-form-action text-center'>
          <Neutral onClick={close}>Cancel</Neutral>
          <Positive lockoutOverride={lockOutFlag}>Save</Positive>
        </div>
      </form>
    </Dialog>
  );
}

export default memo(EditTableViewModal);
