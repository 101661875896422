import '../../style/dialog-form.css';

import { Fragment, useState } from 'react';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import {
  CollapsibleSection,
  DropdownField,
  NumberInput,
  PropertyLabel,
  TextField,
  InlineTooltip,
  CheckboxField
} from '../Core';
import IntegrationsSidebar from './IntegrationsSidebar';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { produce } from 'immer';
import { FieldSelectorWithModal } from '../Modals';
import classNames from 'classnames';
import { PlusIcon, TrashIcon } from '../Icons';

export default function FlinksSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.FLINKS,
    panelId: formId,
    includeInactive: true
  });

  const [isPartial, setIsPartial] = useState(false);
  const [err, setErr] = useState('');

  const secretMeta = integration?.data.secret_metadata ?? {};
  const [customerId, setCustomerId] = useState(secretMeta.customer_id ?? '');
  const [flinksFieldMap, setFlinksFieldMap] = useState<FlinksFieldMapType>(
    secretMeta.field_map || [defaultEntry()]
  );
  const [flinksAuthKey, setFlinksAuthKey] = useState(
    secretMeta.flinks_auth_key ?? ''
  );
  const [daysTransactions, setDaysTransactions] = useState(
    secretMeta.days_transactions ?? 365
  );

  const meta = integration?.data.metadata ?? {};
  const [environment, setEnvironment] = useState(
    meta.environment ?? 'production'
  );
  const [getLoginAccountIdOnly, setGetLoginAccountIdOnly] = useState(
    meta.get_login_account_id_only ?? false
  );
  const [instance, setInstance] = useState(meta.instance ?? ''); // environment name

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial =
        (!customerId && environment === 'production') ||
        flinksFieldMap.some(
          (data) => data.flinks_attribute && !data.feathery_field
        );
      if (partial) setErr('Required fields must be filled');
      const isDaysValid = daysTransactions
        ? daysTransactions <= 365 &&
          daysTransactions > 0 &&
          Number.isInteger(daysTransactions)
        : true;
      if (!isDaysValid) {
        setErr(`Days of Transaction History must be between 1 to 365`);
      }

      setIsPartial(partial);
      if (partial || !isDaysValid) return;
    }

    setErr('');
    return {
      isUpdate: integration?.data,
      metadata: {
        environment,
        instance,
        get_login_account_id_only: getLoginAccountIdOnly
      },
      secretMetadata: {
        customer_id: customerId,
        field_map: flinksFieldMap,
        days_transactions: daysTransactions,
        flinks_auth_key: flinksAuthKey
      }
    };
  }

  const updateFlinksMap = (newMap: FlinksFieldMapType) => {
    setFlinksFieldMap(
      produce(newMap, (draft) => {
        if (draft.length === 0) draft.push(defaultEntry());
      })
    );
  };

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.FLINKS]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <form className='integration-modal-form'>
        <div>
          <PropertyLabel label='Environment Type' />
          <DropdownField
            selected={environment}
            onChange={(event: any) => setEnvironment(event.target.value)}
            options={[
              { value: 'production', display: 'Production' },
              { value: 'sandbox', display: 'Sandbox' }
            ]}
          />
        </div>
        {environment === 'production' && (
          <>
            <div>
              <PropertyLabel label='Environment Name' />
              <TextField
                value={instance}
                onChange={setInstance}
                error={isPartial && !instance}
              />
            </div>
            <div>
              <PropertyLabel label='Customer ID' />
              <TextField
                value={customerId} // https://docs.flinks.com/reference/getallattributes (default value of customer ID)
                onChange={setCustomerId}
                error={isPartial && !customerId}
              />
            </div>
            <div>
              <PropertyLabel label='Flinks Bearer Token' />
              <TextField value={flinksAuthKey} onChange={setFlinksAuthKey} />
            </div>
          </>
        )}
        <CheckboxField
          checked={getLoginAccountIdOnly}
          text={
            <>
              Retrieve Login Id and Account Id Only
              <InlineTooltip
                text='Once Flinks successfully connects with your bank account,
                      Feathery will only retrieve the loginId and accountId from Flinks.
                      You may then set up custom logic and use these attributes
                      to pull information from Flinks as needed. These two attributes
                      are not returned if this option is not checked.'
              />
            </>
          }
          onChange={(checked: boolean) => {
            setGetLoginAccountIdOnly(checked);
            updateFlinksMap(
              produce(flinksFieldMap, (draft) => {
                if (checked) {
                  // filter out the default empty pair
                  for (let i = draft.length - 1; i >= 0; i--) {
                    if (!draft[i].flinks_attribute) {
                      draft.splice(i, 1);
                    }
                  }

                  // add the loginId pair if it doesn't exist
                  if (
                    !draft.some((pair) => pair.flinks_attribute === 'loginId')
                  ) {
                    draft.push({
                      flinks_attribute: 'loginId',
                      feathery_field: '',
                      feathery_type: ''
                    });
                  }

                  // add the accountId pair if it doesn't exist
                  if (
                    !draft.some((pair) => pair.flinks_attribute === 'accountId')
                  ) {
                    draft.push({
                      flinks_attribute: 'accountId',
                      feathery_field: '',
                      feathery_type: ''
                    });
                  }
                } else {
                  // remove the loginId and accountId pairs
                  // since they aren't returned in the regular Flinks pull
                  for (let i = draft.length - 1; i >= 0; i--) {
                    if (
                      draft[i].flinks_attribute === 'loginId' ||
                      draft[i].flinks_attribute === 'accountId'
                    ) {
                      if (draft.length > 1) {
                        draft.splice(i, 1);
                      } else {
                        // if this is the only pair remaining, then we can't remove it
                        // thus instead, we set the flinks_attribute to be an empty string
                        draft[i].flinks_attribute = '';
                      }
                    }
                  }
                }
              })
            );
          }}
          style={{ marginTop: '15px', marginBottom: '10px' }}
        />
        {!getLoginAccountIdOnly && (
          <div>
            <PropertyLabel
              label={
                <>
                  Days of Transaction History (optional)
                  <InlineTooltip text='The value entered must be an integer between 1 and 365. If no value is entered, it is defaulted to 365.' />
                </>
              }
            />
            <NumberInput
              value={daysTransactions}
              onChange={setDaysTransactions}
              placeholder='365'
            />
          </div>
        )}

        <CollapsibleSection
          title='Send Flinks Data to Fields'
          collapsible={false}
        >
          <div className={styles.twoColumnContainer}>
            <div className={styles.fieldHeaderText}>Flinks Attribute</div>
            <div className={styles.fieldHeaderText}>Feathery Field</div>
            {flinksFieldMap.map(
              (data: any, index: number) =>
                // only render the Flinks attribute - Feathery field pair if
                // the get login and account id only checkbox is unchecked
                // or if the box is checked and the Flinks attribute of the pair
                // is loginId or accountId
                (!getLoginAccountIdOnly ||
                  (getLoginAccountIdOnly &&
                    (data.flinks_attribute === 'loginId' ||
                      data.flinks_attribute === 'accountId'))) && (
                  <Fragment key={`${index}`}>
                    <TextField
                      value={data.flinks_attribute}
                      onChange={(attr: string) =>
                        updateFlinksMap(
                          produce(flinksFieldMap, (draft) => {
                            draft[index] = {
                              ...draft[index],
                              flinks_attribute: attr
                            };
                          })
                        )
                      }
                      disabled={getLoginAccountIdOnly}
                    />
                    <div className={styles.fieldSelectorContainer}>
                      <FieldSelectorWithModal
                        selectId={data.feathery_field}
                        selectType={data.feathery_type}
                        placeholder='Select'
                        onSelect={(field) => {
                          updateFlinksMap(
                            produce(flinksFieldMap, (draft) => {
                              draft[index] = {
                                ...draft[index],
                                feathery_field: field.selectId,
                                feathery_type: field.selectType
                              };
                            })
                          );
                        }}
                        error={isPartial && !data.feathery_field}
                        className={classNames(
                          styles.marginBottom,
                          styles.fieldSelector
                        )}
                      />
                      {!getLoginAccountIdOnly && (
                        <TrashIcon
                          height={16}
                          width={16}
                          className={classNames(
                            'tr-icon',
                            styles.customPropertyDelete
                          )}
                          onClick={() => {
                            updateFlinksMap(
                              produce(flinksFieldMap, (draft) => {
                                draft.splice(index, 1);
                              })
                            );
                          }}
                        />
                      )}
                    </div>
                  </Fragment>
                )
            )}
            {!getLoginAccountIdOnly && (
              <PlusIcon
                className={styles.customPropertyAdd}
                onClick={() => {
                  updateFlinksMap(
                    produce(flinksFieldMap, (draft) => {
                      draft.push(defaultEntry());
                    })
                  );
                }}
              />
            )}
          </div>
        </CollapsibleSection>
      </form>
    </IntegrationsSidebar>
  );
}

const defaultEntry = () => ({
  flinks_attribute: '',
  feathery_field: '',
  feathery_type: ''
});

type FlinksFieldMapType = {
  flinks_attribute?: string;
  feathery_field?: string;
  feathery_type?: string;
}[];
