import { useIntercom } from 'react-use-intercom';
import { useCallback } from 'react';
import { useAuthClient } from '@feathery/react';
import mixpanel from 'mixpanel-browser';
import { IS_PROD_FE } from '../api/utils';
import { Auth } from 'aws-amplify';
import { isAmplifyConfigured } from './sso';
import store from '../redux/store';

let logoutGuard = false;

const FEATHERY_JWT_KEY = 'featheryWorkspaceAuthToken';
export const getWorkspaceJwt = () =>
  window.localStorage.getItem(FEATHERY_JWT_KEY);

const params = new URLSearchParams(new URL(window.location.href).search);
const authToken = params.get('auth_token');
if (authToken) window.localStorage.setItem(FEATHERY_JWT_KEY, authToken);

export default function useLogout() {
  const authClient = useAuthClient();

  const logout = async () => {
    if (isAmplifyConfigured()) {
      const ssoSession = await Auth.currentSession();
      if (ssoSession) await Auth.signOut();
      location.href = location.origin + '/sso';
    } else if (getWorkspaceJwt()) {
      // fetch from redux store directly instead of using an app selector
      // app selector causes rerenders which could reset the app
      const state = store.getState();
      const org = state.accounts.organization;
      window.localStorage.removeItem(FEATHERY_JWT_KEY);
      location.href = org?.brand_url ?? 'https://feathery.io';
    } else if (authClient?.session.getSync()) {
      await authClient.session.revoke();
      location.href = location.origin;
    } else {
      location.href = location.origin;
    }
  };
  const { shutdown } = useIntercom();

  return useCallback(async () => {
    if (logoutGuard) return;
    logoutGuard = true;
    shutdown();
    if (IS_PROD_FE) {
      try {
        mixpanel.reset();
      } catch (error) {
        // Ignore errors like https://feathery-forms.sentry.io/issues/4399277616
      }
    }
    await logout();
    logoutGuard = false;
  }, [authClient, shutdown]);
}
