import '../../style/dialog-form.css';

import { Fragment, useState } from 'react';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import {
  CheckboxField,
  CollapsibleSection,
  DropdownField,
  DropdownMultiField,
  InlineTooltip,
  PropertyLabel,
  TextField
} from '../Core';
import { produce } from 'immer';
import IntegrationsSidebar from './IntegrationsSidebar';
import styles from './styles.module.scss';
import { mapArrayToObject } from '../../utils/core';
import { FieldSelectorWithModal } from '../Modals';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';

function PlaidSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.PLAID,
    panelId: formId,
    includeInactive: true
  });

  const [isPartial, setIsPartial] = useState(false);

  const meta = integration?.data.metadata ?? {};
  const secretMeta = integration?.data.secret_metadata ?? {};

  const [secret, setSecret] = useState(secretMeta.secret ?? '');
  const [optionalProducts, setOptionalProducts] = useState(
    secretMeta.optional_products ?? []
  );

  const [err, setErr] = useState('');
  const [plaidFieldMap, setPlaidFieldMap] = useState(
    meta.plaid_field_map ? mapArrayToObject(meta.plaid_field_map, 'key') : {}
  );
  const [clientId, setClientId] = useState(meta.client_id ?? '');
  const [environment, setEnvironment] = useState(
    meta.environment ?? 'production'
  );
  const [enableConsumerReport, setEnableConsumerReport] = useState(
    secretMeta.consumer_report ?? false
  );
  const [consumerReportFields, setConsumerReportFields] = useState(
    secretMeta.consumer_report_fields ?? {}
  );
  const [consumerAttributes, setConsumerAttributes] = useState(
    secretMeta.consumer_attributes ?? {}
  );
  const [assetWebhook, setAssetWebhook] = useState(meta.asset_webhook ?? '');

  function onSubmitCustom(newIsActive: boolean) {
    setErr('');

    if (newIsActive) {
      let partial = !clientId || !secret;
      if (!partial && enableConsumerReport) {
        const missingAttr = Object.keys(PLAID_CONSUMER_ATTRIBUTES).some(
          (attr) => !consumerAttributes[attr]?.field_id
        );
        const noField = Object.keys(consumerReportFields).length === 0;
        partial = missingAttr || noField;
      }

      setIsPartial(partial);
      if (partial) return;
    }

    if (!enableConsumerReport) {
      const selectedFields = Object.keys(plaidFieldMap);
      if (
        selectedFields.every(
          (product) => !PLAID_REPORT_FIELDS.includes(product)
        )
      ) {
        setErr('At least one piece of user data must be collected from Plaid');
        return;
      }

      const requiredProducts = Object.values(plaidFieldMap)
        .map((field: any) => field.product)
        .filter((product) => product && !optionalProducts.includes(product));
      if (!requiredProducts.length) {
        setErr('At least one non-optional Plaid product must be configured');
        return;
      }
    }

    // transform to array and filter out fields that had a product change but no value set
    const plaidPayload = Object.values(plaidFieldMap).filter(
      ({ value }: any) => value !== ''
    );

    return {
      isUpdate: integration?.data,
      metadata: {
        plaid_field_map: plaidPayload,
        client_id: clientId,
        environment,
        asset_webhook: assetWebhook
      },
      secretMetadata: {
        secret,
        optional_products: optionalProducts,
        consumer_report: enableConsumerReport,
        consumer_report_fields: consumerReportFields,
        consumer_attributes: consumerAttributes
      }
    };
  }

  const changeValue = (fieldName: any, newValue: any) => {
    setPlaidFieldMap(
      produce(plaidFieldMap, (draft: any) => {
        const currentEntry = draft[fieldName];
        if (currentEntry && newValue) {
          draft[fieldName].value = newValue;
        } else if (currentEntry && !newValue) {
          // If newValue is '' we want to delete fieldName, so that we aren't sending empty strings to BE
          delete draft[fieldName];
        } else {
          // need to initialize if plaidFieldMap[fieldName] doesn't exist
          const product = getProduct(plaidFieldMap, fieldName);
          draft[fieldName] = {
            key: fieldName,
            value: newValue,
            product
          };
        }
      })
    );
  };

  const changeProduct = (fieldName: any) => (newValue: any) => {
    setPlaidFieldMap(
      produce(plaidFieldMap, (draft: any) => {
        const currentEntry = draft[fieldName];
        const product = newValue.toLowerCase(); // need to decapitalize product name
        if (currentEntry) {
          draft[fieldName].product = product;
        } else {
          // need to initialize if plaidFieldMap[fieldName] doesn't exist
          draft[fieldName] = { key: fieldName, product, value: '' };
        }
      })
    );
  };

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.PLAID]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <form className='integration-modal-form'>
        <div>
          <PropertyLabel label='API Client ID' />
          <TextField
            placeholder='Enter Client ID'
            value={clientId || ''}
            onChange={setClientId}
            error={isPartial && (!clientId || clientId === '')}
          />
        </div>
        <div>
          <PropertyLabel label='API Secret' />
          <TextField
            type='password'
            placeholder='Enter Secret'
            value={secret || ''}
            onChange={setSecret}
            error={isPartial && (!secret || secret === '')}
          />
        </div>
        <div>
          <PropertyLabel label='Environment' />
          <DropdownField
            selected={environment}
            onChange={(event: any) => setEnvironment(event.target.value)}
            options={[
              { value: 'sandbox', display: 'Sandbox' },
              { value: 'production', display: 'Production' }
            ]}
          />
        </div>

        <CollapsibleSection
          title='Save Plaid data to fields'
          style={{ marginTop: '15px' }}
        >
          <div className={styles.twoColumnContainer}>
            <div className={styles.fieldHeaderText}>Metadata</div>
            <div className={styles.fieldHeaderText}>Hidden Field</div>

            {PLAID_METADATA_FIELDS.map((fieldName) => (
              <Fragment key={fieldName}>
                <PropertyLabel label={PLAID_FIELD_TO_LABEL[fieldName]} />
                <FieldSelectorWithModal
                  selectId={getValue(plaidFieldMap, fieldName)}
                  placeholder='Select'
                  onlyHiddenFields
                  selectType='hidden'
                  onSelect={(data) => changeValue(fieldName, data.selectId)}
                  className={styles.marginBottom}
                />
              </Fragment>
            ))}
          </div>
          <div className={styles.helperText}>
            * You must enable the relevant Plaid product(s) in order to receive
            desired user data.
          </div>

          <div className={styles.productContainer}>
            <div className={styles.fieldHeaderText}>User Data</div>
            <div className={styles.fieldHeaderText}>Product</div>
            <div className={styles.fieldHeaderText}>Hidden Field</div>

            {PLAID_REPORT_FIELDS.map((fieldName) => (
              <Fragment key={fieldName}>
                <PropertyLabel label={PLAID_FIELD_TO_LABEL[fieldName]} />
                {PLAID_FIELD_TO_PRODUCTS[fieldName].length > 1 ? (
                  <PlaidProductDropdown
                    selectableProducts={PLAID_FIELD_TO_PRODUCTS[fieldName].map(
                      ({ display, value }: any) => ({ display, value })
                    )}
                    selected={getProduct(plaidFieldMap, fieldName)}
                    onChange={(event: any) =>
                      changeProduct(fieldName)(event.target.value)
                    }
                  />
                ) : (
                  PLAID_FIELD_TO_PRODUCTS[fieldName][0].display
                )}
                <FieldSelectorWithModal
                  selectId={getValue(plaidFieldMap, fieldName)}
                  placeholder='Select'
                  onlyHiddenFields
                  selectType='hidden'
                  onSelect={(data) => changeValue(fieldName, data.selectId)}
                  className={styles.marginBottom}
                />
              </Fragment>
            ))}
          </div>
          <div className={styles.fieldHeaderText}>Consumer Report</div>
          <CheckboxField
            checked={enableConsumerReport}
            text='Pull Plaid Consumer Report'
            onChange={(checked) => setEnableConsumerReport(checked)}
          />
          {enableConsumerReport && (
            <>
              <div className={styles.twoColumnContainer}>
                <div className={styles.fieldHeaderText}>Consumer Attribute</div>
                <div className={styles.fieldHeaderText}>Field</div>

                {Object.entries(PLAID_CONSUMER_ATTRIBUTES).map(
                  ([attribute, label]) => (
                    <Fragment key={attribute}>
                      <PropertyLabel label={label} />
                      <FieldSelectorWithModal
                        selectId={consumerAttributes[attribute]?.field_id}
                        selectType={consumerAttributes[attribute]?.field_type}
                        placeholder='Select'
                        onSelect={(data) => {
                          const newAttributes = JSON.parse(
                            JSON.stringify(consumerAttributes)
                          );
                          newAttributes[attribute] = {
                            field_id: data.selectId,
                            field_type: data.selectType
                          };
                          setConsumerAttributes(newAttributes);
                        }}
                        error={
                          isPartial && !consumerAttributes[attribute]?.field_id
                        }
                        className={styles.marginBottom}
                      />
                    </Fragment>
                  )
                )}
              </div>
              <div className={styles.productContainer}>
                <div className={styles.fieldHeaderText}>Consumer Data</div>
                <div className={styles.fieldHeaderText}>Report</div>
                <div className={styles.fieldHeaderText}>Hidden Field</div>
                {PLAID_CONSUMER_REPORT_FIELDS.map(
                  ({ value, report }, index) => (
                    <Fragment key={value}>
                      <PropertyLabel
                        label={PLAID_CONSUMER_REPORT_VALUE_LABELS[value]}
                      />
                      {PLAID_CONSUMER_REPORT_LABELS[report]}
                      <FieldSelectorWithModal
                        selectId={consumerReportFields[value]?.field_id}
                        placeholder='Select'
                        onlyHiddenFields
                        selectType='hidden'
                        onSelect={(data) => {
                          const newFields = JSON.parse(
                            JSON.stringify(consumerReportFields)
                          );
                          if (data.selectId)
                            newFields[value] = {
                              field_id: data.selectId,
                              report
                            };
                          else delete newFields[value];
                          setConsumerReportFields(newFields);
                        }}
                        error={
                          isPartial &&
                          index === 0 &&
                          !consumerReportFields[value]?.field_id
                        }
                        className={styles.marginBottom}
                      />
                    </Fragment>
                  )
                )}
              </div>
            </>
          )}
          <div className={styles.fieldHeaderText}>
            Assets Report Webhook
            <InlineTooltip text="Post to this URL if you're pulling from the Plaid Assets product and a user's asset report is ready" />
          </div>
          <TextField
            placeholder='https://api.feathery.io'
            value={assetWebhook}
            onChange={setAssetWebhook}
          />
          <div className={styles.fieldHeaderText}>
            Optional Products
            <InlineTooltip text='Plaid will pull the data from the institution if possible, but in the event of a failure, Item creation will still succeed.' />
          </div>
          <DropdownMultiField
            selected={optionalProducts}
            options={Object.values(PLAID_API_PRODUCTS).map(
              ({ value, display }) => ({ value, label: display })
            )}
            onChange={(items: { value: string }[]) =>
              setOptionalProducts(items.map((item) => item.value))
            }
          />
        </CollapsibleSection>
      </form>
    </IntegrationsSidebar>
  );
}

const getValue = (plaidFieldMap: any, fieldName: any) => {
  if (!plaidFieldMap[fieldName]) return '';
  const entry = plaidFieldMap[fieldName];
  return entry.value;
};

const getProduct = (plaidFieldMap: any, fieldName: any) => {
  // Return the default product choice if fieldName not yet in plaidFieldMap.
  // This is needed because plaidFieldMap only has an entry if the user has
  // entered a value to the TextField, but we still need to display a product
  // selection to the user before they enter a value
  if (!plaidFieldMap[fieldName])
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return DEFAULT_PLAID_PRODUCT_MAP[fieldName]?.value ?? '';
  const entry = plaidFieldMap[fieldName];
  return entry?.product ?? '';
};

const PLAID_API_PRODUCTS = {
  ASSETS: { value: 'assets', display: 'Assets' },
  AUTH: { value: 'auth', display: 'Auth' },
  BALANCE: { value: 'balance', display: 'Balance' },
  IDENTITY: { value: 'identity', display: 'Identity' },
  TRANSACTIONS: { value: 'transactions', display: 'Transactions' }
};

const PLAID_METADATA_FIELDS = ['access_token', 'item_id'];

const PLAID_CONSUMER_ATTRIBUTES = {
  first_name: 'First Name',
  last_name: 'Last Name',
  phone_number: 'Phone Number',
  email: 'Email',
  date_of_birth: 'Date of Birth',
  address_city: 'Address City',
  address_region: 'Address Region',
  address_street: 'Address Street',
  address_postal_code: 'Address Postal Code',
  address_country: 'Address Country'
};

const PLAID_CONSUMER_REPORT_VALUE_LABELS = {
  base_report_id: 'Base Report ID',
  income_insights_report_id: 'Income Insights Report ID'
};

const PLAID_CONSUMER_REPORT_LABELS = {
  cra_base_report: 'Base Report',
  cra_income_insights: 'Income Insights'
};

const PLAID_CONSUMER_REPORT_FIELDS = [
  { value: 'base_report_id', report: 'cra_base_report' },
  { value: 'income_insights_report_id', report: 'cra_income_insights' }
] as {
  value: keyof typeof PLAID_CONSUMER_REPORT_VALUE_LABELS;
  report: keyof typeof PLAID_CONSUMER_REPORT_LABELS;
}[];

const PLAID_REPORT_FIELDS = [
  'account_number',
  'routing_number',
  'eft_account_number',
  'eft_branch',
  'eft_institution',
  'verification_status',
  'bank_name',
  'account_name',
  'account_address',
  'account_owners',
  'account_transactions',
  'account_balance'
];

const PLAID_FIELD_TO_PRODUCTS: Record<
  string,
  { value: string; display: string }[]
> = {
  access_token: [],
  item_id: [],
  account_number: [PLAID_API_PRODUCTS.AUTH],
  routing_number: [PLAID_API_PRODUCTS.AUTH],
  eft_account_number: [PLAID_API_PRODUCTS.AUTH],
  eft_branch: [PLAID_API_PRODUCTS.AUTH],
  eft_institution: [PLAID_API_PRODUCTS.AUTH],
  verification_status: [PLAID_API_PRODUCTS.AUTH],
  bank_name: [PLAID_API_PRODUCTS.IDENTITY, PLAID_API_PRODUCTS.ASSETS],
  account_name: [PLAID_API_PRODUCTS.IDENTITY, PLAID_API_PRODUCTS.ASSETS],
  account_address: [PLAID_API_PRODUCTS.IDENTITY, PLAID_API_PRODUCTS.ASSETS],
  account_owners: [PLAID_API_PRODUCTS.IDENTITY, PLAID_API_PRODUCTS.ASSETS],
  account_transactions: [
    PLAID_API_PRODUCTS.TRANSACTIONS,
    PLAID_API_PRODUCTS.ASSETS
  ],
  account_balance: [PLAID_API_PRODUCTS.BALANCE, PLAID_API_PRODUCTS.ASSETS]
};

// Default product selection to the first product option in PLAID_FIELD_TO_PRODUCTS
const DEFAULT_PLAID_PRODUCT_MAP = PLAID_REPORT_FIELDS.reduce(
  (obj, fieldName) => {
    const products = PLAID_FIELD_TO_PRODUCTS[fieldName];
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    obj[fieldName] = products[0];
    return obj;
  },
  {}
);

const PLAID_FIELD_TO_LABEL: { [fieldName: string]: string } = {
  access_token: 'Access Token',
  item_id: 'Item ID',
  account_number: 'ACH Account Number',
  routing_number: 'ACH Account Routing Number',
  eft_account_number: 'EFT Account Number',
  eft_branch: 'EFT Branch',
  eft_institution: 'EFT Institution',
  verification_status: 'Verification Status',
  bank_name: 'Bank Name',
  account_name: 'Bank Account Name',
  account_address: 'Bank Account Address',
  account_owners: 'Bank Account Owners',
  account_transactions: 'Account Transaction History',
  account_balance: 'Bank Account Balance'
};

const PlaidProductDropdown = ({
  selected,
  onChange,
  selectableProducts
}: any) => {
  return (
    <DropdownField
      selected={selected}
      onChange={onChange}
      options={selectableProducts.sort()}
    />
  );
};

export default PlaidSettingsSidebar;
