import { useEffect, useMemo, useState } from 'react';
import {
  chosenPlan,
  ctaDisplayedThisPeriod,
  getBillingCycleDates,
  onFreePlan
} from './plans';
import styles from './styles.module.scss';
import CtaPanel from './CtaPanel';
import {
  formatDate,
  formatMonthAndDay,
  formatNumeric
} from '../../utils/format';
import { StytchCtaIcon, UserCtaIcon } from '../Icons';
import { Positive } from '../Core/Button';
import { INTEGRATIONS } from '../FormIntegrations';
import { useAppSelector } from '../../hooks';
import useFeatheryRedux from '../../redux';

const VIOLATIONS = {
  OVER_SUBMISSIONS: 'over submissions limit',
  OVER_SUBMISSIONS_LEVEL_1: 'submissions LEVEL 1',
  OVER_SUBMISSIONS_LEVEL_2: 'submissions LEVEL 2',
  OVER_FORMS: 'forms',
  OVER_STYTCH_QUOTA: 'stytch-over-quota',
  OVER_DOCUMENTS: 'over documents limit'
};

function UsageEnforcement({ style }: any) {
  const org = useAppSelector((state) => state.accounts.organization) ?? {};
  const account = useAppSelector((state) => state.accounts.account);

  const {
    type: orgType,
    submissions_this_month: submissions,
    documents_over_limit: documentsOverLimit
  } = org;
  const currentPlan = useMemo(() => chosenPlan(org), [org?.tier]);
  const freeTier = onFreePlan(org);
  const [showCTA, setShowCTA] = useState(false);
  const [ctaType, setCtaType] = useState('');
  const [stytchOverQuotaForm, setStytchOverQuotaForm] = useState('');

  const panelsData = useAppSelector((state) => state.panels.panels);
  const integrations = useAppSelector(
    (state) => state.integrations.integrations
  );
  const bill = useAppSelector((state) => state.accounts.bill);
  const {
    first_submission_cta,
    second_submission_cta,
    submission_limit_cta,
    document_limit_cta
  } = useAppSelector<any>((state) => state.accounts.account);
  const documentsEnabled = org.enterprise_features.documents ?? false;

  const { editAccount } = useFeatheryRedux();

  const [billStart, billEnd] = useMemo(
    () =>
      bill.subscription_start
        ? getBillingCycleDates(new Date(bill.subscription_start))
        : [null, null],
    [bill.subscription_start]
  );

  function updateCTA(ctaType = '') {
    // Viewers shouldn't be shown these messages
    if (account.role === 'viewer') return;

    setShowCTA(!!ctaType);
    setCtaType(ctaType);
  }

  useEffect(() => {
    const overQuotaPanelId = Object.keys(integrations).find(
      (id) =>
        panelsData[id]?.active &&
        integrations[id][INTEGRATIONS.STYTCH]?.data.error === 'STYTCH_NO_QUOTA'
    );
    const activeForms = Object.values(panelsData).reduce(
      (count, p) => count + (p.active ? 1 : 0),
      0
    );

    setStytchOverQuotaForm('');
    if (overQuotaPanelId) {
      updateCTA(VIOLATIONS.OVER_STYTCH_QUOTA);
      setStytchOverQuotaForm(overQuotaPanelId);
    } else if (activeForms > currentPlan.forms) {
      updateCTA(VIOLATIONS.OVER_FORMS);
    } else if (
      currentPlan.monthlySubmissions > 0 &&
      submissions > currentPlan.monthlySubmissions &&
      !ctaDisplayedThisPeriod(submission_limit_cta, billStart)
    ) {
      updateCTA(VIOLATIONS.OVER_SUBMISSIONS);
    } else if (
      currentPlan.monthlySubmissions > 0 &&
      submissions > currentPlan.monthlySubmissions * 0.9 &&
      !ctaDisplayedThisPeriod(second_submission_cta, billStart)
    ) {
      updateCTA(VIOLATIONS.OVER_SUBMISSIONS_LEVEL_2);
    } else if (
      currentPlan.monthlySubmissions > 0 &&
      submissions > currentPlan.monthlySubmissions * 0.5 &&
      !ctaDisplayedThisPeriod(first_submission_cta, billStart)
    ) {
      updateCTA(VIOLATIONS.OVER_SUBMISSIONS_LEVEL_1);
    } else if (
      !documentsEnabled &&
      documentsOverLimit &&
      !ctaDisplayedThisPeriod(document_limit_cta, billStart)
    )
      updateCTA(VIOLATIONS.OVER_DOCUMENTS);
    else updateCTA();
  }, [
    currentPlan,
    panelsData,
    submissions,
    orgType,
    integrations,
    documentsOverLimit
  ]);

  const [Icon, message, buttonLabel, onCloseCta = () => setShowCTA(false)] =
    useMemo(() => {
      const getCtaParams = (
        title: string,
        ctaProp: string,
        message = `Your month end is ${formatDate(billEnd, false)}.`,
        label = 'Request Upgrade',
        icon = UserCtaIcon
      ): any => [
        icon,
        <>
          <div className={styles.ctaTitle}>{title}</div>
          <div className={styles.ctaMessage}>{message}</div>
        </>,
        label,
        () => {
          setShowCTA(false);
          editAccount({
            [ctaProp]: new Date().toISOString()
          });
        }
      ];

      if (ctaType === VIOLATIONS.OVER_FORMS)
        return [
          UserCtaIcon,
          <>
            <div className={styles.ctaTitle}>
              You&apos;re over the active forms limit.
            </div>
            <div className={styles.ctaMessage}>
              {`You have over ${formatNumeric(currentPlan.forms)} active forms.
            Either turn forms off or upgrade.`}
            </div>
          </>,
          'Request Upgrade'
        ];
      else if (ctaType === VIOLATIONS.OVER_DOCUMENTS)
        return getCtaParams(
          `You filled over ${formatNumeric(
            currentPlan.monthlyDocuments
          )} documents this month.`,
          'document_limit_cta',
          `Document filling is disabled through
            ${formatMonthAndDay(billEnd)}.`
        );
      else if (ctaType === VIOLATIONS.OVER_SUBMISSIONS_LEVEL_1)
        return getCtaParams(
          "You've used 50% of your monthly submissions.",
          'first_submission_cta'
        );
      else if (ctaType === VIOLATIONS.OVER_SUBMISSIONS_LEVEL_2)
        return getCtaParams(
          "You've used 90% of your monthly submissions.",
          'second_submission_cta'
        );
      else if (ctaType === VIOLATIONS.OVER_SUBMISSIONS)
        return getCtaParams(
          `You have over ${formatNumeric(
            currentPlan.monthlySubmissions
          )} form submissions.`,
          'submission_limit_cta',
          freeTier
            ? `Submissions are disabled through
            ${formatMonthAndDay(billEnd)}.`
            : `You are being charged for overage until ${formatMonthAndDay(
                billEnd
              )}.`
        );
      else if (ctaType === VIOLATIONS.OVER_STYTCH_QUOTA)
        return [
          StytchCtaIcon,
          <>
            <div className={styles.ctaTitle}>
              Your Stytch trial has expired.
            </div>
            <div className={styles.ctaMessage}>
              Please update your Stytch token to regain access.
            </div>
          </>,
          'Update Stytch'
        ];
      return [null, null, null, ''];
    }, [ctaType, currentPlan, stytchOverQuotaForm]);

  return showCTA ? (
    <div>
      <CtaPanel closePanel={onCloseCta} width='lg' style={style}>
        <div className={styles.cta}>
          <div className={styles.ctaLeft}>
            <Icon className={styles.ctaIcon} />
            <div className={styles.ctaBody}>{message}</div>
          </div>
          <Positive
            className={styles.ctaButton}
            onClick={() => {
              onCloseCta();
              // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
              window.location =
                'mailto:sales@feathery.io?subject=Business plan quote request&body=I would like to upgrade our Feathery plan. The requirements are: ';
            }}
          >
            {buttonLabel}
          </Positive>
        </div>
      </CtaPanel>
    </div>
  ) : null;
}

export default UsageEnforcement;
