import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SettingsContainer from '../../components/SettingsContainer';
import SideNavigation from '../../components/SideNavigation';
import { useAppSelector } from '../../hooks';
import { PUBLISH_STATUS } from '../../redux/utils';
import styles from './styles.module.scss';
import { PublishButton } from '../../components/Core/Button';

import useFeatheryRedux from '../../redux';
import { TextField } from '../../components/Core';
import { objectPick } from '../../utils/core';
import WorkspaceNavigation from '../../components/NavBar/WorkspaceNavigation';

function WorkspaceSettingsPage() {
  const {
    toasts: { addInfoToast, addErrorToast },
    editWorkspace
  } = useFeatheryRedux();
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const workspace = useAppSelector(
    (state) => state.accounts.workspaces[workspaceId]
  );

  const [publishStatus, setPublishStatus] = useState(PUBLISH_STATUS.FULFILLED);
  const [formData, setFormData] = useState<Record<string, any>>({
    name: '',
    logo: '',
    brand_url: '',
    brand_name: ''
  });

  useEffect(() => {
    if (workspace)
      setFormData({ ...objectPick(workspace, Object.keys(formData)) });
  }, [workspace]);

  const updateData = (property: string, value: any) => {
    if (value !== formData[property]) {
      setFormData((formData) => ({
        ...formData,
        [property]: value
      }));
      setPublishStatus(PUBLISH_STATUS.ACTIVE);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setPublishStatus(PUBLISH_STATUS.LOADING);
    try {
      await editWorkspace({ workspaceId, ...formData });
      setPublishStatus(PUBLISH_STATUS.FULFILLED);
      addInfoToast('Your settings have been saved.');
    } catch (error: any) {
      setPublishStatus(PUBLISH_STATUS.ACTIVE);
      addErrorToast({ text: error.toString() });
    }
  };

  return (
    <>
      <WorkspaceNavigation
        activeItem='settings'
        submitButton={
          <PublishButton
            status={publishStatus}
            onPublish={handleSubmit}
            saveFlag
          />
        }
      />
      <div className={styles.workspacePage}>
        <SideNavigation title='Settings'>
          <SettingsContainer title='Properties'>
            <div className='field-group'>
              <label>
                <p className='form-label'>Name</p>
                <TextField
                  placeholder='New Client'
                  value={formData.name}
                  onComplete={(val: string) => updateData('name', val)}
                />
              </label>
            </div>
            <div className='field-group'>
              <label>
                <p className='form-label'>Brand Logo URL</p>
                <TextField
                  type='url'
                  placeholder='https://brand.com/logo'
                  value={formData.logo}
                  onComplete={(val: string) => updateData('logo', val)}
                />
              </label>
            </div>
            <div className='field-group'>
              <label>
                <p className='form-label'>Brand Name (Parent Company)</p>
                <TextField
                  placeholder='Parent Company'
                  value={formData.brand_name}
                  onComplete={(val: string) => updateData('brand_name', val)}
                />
              </label>
            </div>
            <div className='field-group'>
              <label>
                <p className='form-label'>Brand URL</p>
                <TextField
                  type='url'
                  placeholder='https://brand.com'
                  value={formData.brand_url}
                  onComplete={(val: string) => updateData('brand_url', val)}
                />
              </label>
            </div>
            <div className='field-group'>
              <label>
                <p className='form-label'>Brand Favicon</p>
                <TextField
                  type='url'
                  placeholder='https://brand.com/favicon.ico'
                  value={formData.brand_favicon}
                  onComplete={(val: string) => updateData('brand_favicon', val)}
                />
              </label>
            </div>
          </SettingsContainer>
        </SideNavigation>
      </div>
    </>
  );
}

export default memo(WorkspaceSettingsPage);
