import { useAppSelector } from '../../../../hooks';
import useIntegrations from '../../../FormIntegrations/useIntegrations';
import { INTEGRATIONS } from '../../../FormIntegrations';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import useFeatheryRedux from '../../../../redux';
import {
  QuikFieldRow,
  QuikTableColumn
} from '../../../Modals/FieldSelectorWithModal/QuikFieldSelector';

export interface QuikFieldData {
  id: string;
  role: string;
  repeatable: boolean;
}

interface QuikSelectorTab {
  key: string;
  label: string;
  columns: QuikTableColumn[];
  rows: QuikFieldRow[];
  onRowSelect?: (row: QuikFieldRow) => void;
  selectedRowId?: string;
}

export const QUIK_FIELD_UNSUPPORTED_TYPES = new Set([
  'button_group',
  'pin_input',
  'hex_color',
  'password',
  'payment_method',
  'slider',
  'signature',
  'url',
  'qr_scanner',
  'rating'
]);

const getRole = (quikField: any) => {
  if (quikField.RoleShortName) {
    return quikField.RoleShortName;
  } else if (quikField.FieldShortName) {
    return quikField.FieldShortName;
  }
  return 'None';
};

const mapFields = (fields: Map<string, QuikFieldData>) => {
  return Array.from(fields.values())
    .sort((a, b) => {
      const aKey = a.id.replace(/^\d+/, '');
      const bKey = b.id.replace(/^\d+/, '');
      return aKey.localeCompare(bKey);
    })
    .map((field) => ({
      value: field.id,
      label: field.id,
      role: field.role,
      repeatable: field.repeatable
    }));
};

export default function useQuikFieldConfig(servarMeta: any) {
  const { formId } = useParams<{ formId: string }>();
  const { fetchQuikFields } = useFeatheryRedux();

  const isEnterprise = useAppSelector((state) => {
    const org = state.accounts.organization;
    return !!(org && org.tier >= 4);
  });

  const quikIntegration = useIntegrations({
    type: INTEGRATIONS.QUIK,
    panelId: formId
  });

  const isQuikConnected = useAppSelector((state) => {
    const org = state.accounts.organization;
    return !!(
      quikIntegration &&
      org &&
      isEnterprise &&
      org.enterprise_features.quik
    );
  });

  const quikMeta = quikIntegration?.data.secret_metadata;
  const quikFields = useAppSelector((state) => state.integrations.quikFields);

  useEffect(() => {
    if (!isQuikConnected) return;

    quikMeta.form_ids.forEach((quikFormId: string) => {
      quikFormId = quikFormId.split(':')[0];
      if (!quikFields[quikFormId])
        fetchQuikFields({ panelId: formId, quikFormId });
    });
  }, [isQuikConnected, quikFields]);

  const {
    quikSelectFields,
    quikPhoneNumFields,
    quikTextFields,
    quikAllFields
  } = useMemo(() => {
    if (!isQuikConnected)
      return {
        quikSelectFields: [],
        quikPhoneNumFields: [],
        quikTextFields: [],
        quikAllFields: []
      };

    const selectFields = new Map<string, QuikFieldData>();
    const phoneNumFields = new Map<string, QuikFieldData>();
    const textFields = new Map<string, QuikFieldData>();
    const allFields = new Map<string, QuikFieldData>();

    quikMeta.form_ids?.forEach((quikFormId: string) => {
      const normalizedFormId = quikFormId.split(':')[0];
      quikFields[normalizedFormId]?.forEach((quikField) => {
        const quikFieldName = quikField.FullFieldName;
        const repeatable = quikFieldName.startsWith('2');
        // store field id without leading number if repeatable for use in repetable containers
        // ex. 2ben.FullName -> ben.FullName
        const id = repeatable ? quikFieldName.substring(1) : quikFieldName;
        const role = getRole(quikField);

        const fieldData = {
          id,
          role,
          repeatable
        };

        if (quikField.RoleName === 'User') {
          // Include user-defined fields in both select and text types
          selectFields.set(id, fieldData);
          textFields.set(id, fieldData);
        } else if (quikField.FieldValue != null) {
          // Field has specific options
          selectFields.set(id, fieldData);
        } else {
          // Generic text fields
          textFields.set(id, fieldData);

          // categorize phone number fields
          if (id.endsWith('Phone') || id.endsWith('Mobile')) {
            phoneNumFields.set(id, fieldData);
          }
        }

        // Maintain a list of all Quik Fields
        allFields.set(id, fieldData);
      });
    });

    return {
      quikSelectFields: mapFields(selectFields),
      quikPhoneNumFields: mapFields(phoneNumFields),
      quikTextFields: mapFields(textFields),
      quikAllFields: mapFields(allFields)
    };
  }, [quikFields, quikMeta, isQuikConnected]);

  const quikFieldsByType = [
    { label: 'Text Fields', value: 'text_field', options: quikTextFields },
    {
      label: 'Single Select Fields',
      value: 'select',
      options: quikSelectFields
    },
    {
      label: 'Phone Number Fields',
      value: 'phone_number',
      options: quikPhoneNumFields
    }
  ];

  const getQuikFieldIDOptions = (elementType: string) => {
    switch (elementType) {
      case 'select':
        return quikSelectFields;
      case 'text_field':
        return quikTextFields;
      case 'phone_number':
        return quikPhoneNumFields;
      default:
        // display all quik field IDs for any other Feathery field types
        return quikAllFields;
    }
  };

  const isQuikSelectField = (quikFieldId: string) => {
    return quikSelectFields.some((field) => quikFieldId === field.value);
  };

  const getQuikFieldSelectorTabData = (
    onSelect: (id: string) => void,
    elementType: string,
    isRepeatable: boolean
  ): QuikSelectorTab[] => {
    let filteredQuikFieldsByType = quikFieldsByType;

    const typeMatch = quikFieldsByType.find(
      ({ value }) => value === elementType
    );

    if (typeMatch) {
      filteredQuikFieldsByType = [typeMatch];
    }

    return filteredQuikFieldsByType
      .filter(({ options }) => options && options.length > 0)
      .map(({ label, options = [], value }) => ({
        key: value,
        label,
        columns: [
          { key: 'field', name: 'Name' },
          { key: 'role', name: 'Role' }
        ],
        rows: options
          .filter((field) => field.repeatable === isRepeatable)
          .map((field) => ({
            id: field.label,
            field: field.value,
            role: field.role
          })),
        onRowSelect: (row) => onSelect(row.id),
        selectedRowId: servarMeta.quik_field_id
      }));
  };

  return {
    isQuikConnected,
    getQuikFieldIDOptions,
    isQuikSelectField,
    getQuikFieldSelectorTabData
  };
}
