import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import UserResultsTable from '../../components/UserResultsTable';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useFeatheryRedux from '../../redux';
import { LinkText } from '../../utils/domOperations';
import { AIExtractionRun } from '../AIExtractionRunPage/types';
import { capitalizeFirstLetter } from '../../utils/format';
import AINavigation from '../../components/NavBar/AINavigation';
import { Tooltip } from '../../components/Core/Tooltip/Tooltip';
type URLParams = {
  formId: string;
  extractionId: string;
};

export default function AIExtractionDetailPage() {
  const { extractionId } = useParams<URLParams>();

  const history = useHistory();

  const [loadError, setLoadError] = useState('');

  const { getAIExtractionRuns, deleteAIExtractionRun } = useFeatheryRedux();

  const handleFetchData = (payload: { [key: string]: any }) => {
    return getAIExtractionRuns({
      ...payload,
      extractionId
    });
  };

  const handleSelectResult = (run: any) => {
    if (run.success === null) return;
    history.push(`/ai/${extractionId}/results/${run.id}`);
  };

  const handleLoadError = (error: string) => {
    setLoadError(error);
  };

  return (
    <>
      <AINavigation activeItem='results' error={loadError} />
      <div className={styles.aiExtractionsListPage}>
        <div className={styles.section}>
          <UserResultsTable
            extractionId={extractionId}
            onFetchData={handleFetchData}
            onSelectResult={handleSelectResult}
            onDelete={(run: AIExtractionRun) =>
              deleteAIExtractionRun({ runId: run.id })
            }
            onLoadError={handleLoadError}
            hideTitle
            hideUploadSubmissions
            hideEditTableView
            padding={false}
            resultColumns={[
              { key: 'id', name: 'Run ID' },
              {
                key: 'file_sources',
                name: 'Files',
                noSort: true,
                renderCell: (data) => {
                  if (
                    data.file_sources?.length &&
                    data.file_sources?.[0]?.url
                  ) {
                    return data.file_sources.map((source: any) => (
                      <LinkText
                        key={source.field_id}
                        link={source.url}
                        text={source.path.split('/').at(-1)}
                      />
                    ));
                  } else if (data.email_content) {
                    return `Email - ${data.email_content.subject}`;
                  } else {
                    return 'Unknown Source';
                  }
                }
              },
              {
                key: 'error_message',
                name: 'Status',
                renderCell: (data) => {
                  let status, message;
                  if (data.success === null) status = 'processing';
                  else if (!data.success) status = 'error';
                  else if (!data.approved) status = 'pending';
                  else if (data.bounding_box_success === false)
                    status = 'error';
                  else {
                    status = 'approved';
                    if (
                      data.fetching_bounding_boxes &&
                      data.bounding_box_success === null
                    )
                      message = 'Fetching bounding boxes';
                    if (data.approver) message = `Approved - ${data.approver}`;
                  }
                  let component = (
                    <div className={classNames(styles.status, styles[status])}>
                      {message || capitalizeFirstLetter(status)}
                    </div>
                  );
                  if (status === 'error') {
                    const errMessage =
                      data.error_message ||
                      data.bounding_box_error_message ||
                      'An error occurred';
                    component = (
                      <Tooltip content={errMessage}>{component}</Tooltip>
                    );
                  }
                  return component;
                }
              },
              { key: 'updated_at', name: 'Last Updated' }
            ]}
          />
        </div>
      </div>
    </>
  );
}
