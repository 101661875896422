import { useCallback, useMemo, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import useFeatheryRedux from '../../../redux';
import '../../../style/dialog-form.css';
import { deepEquals } from '../../../utils/core';
import { getDefaultQuestionGroups } from '../utils';
import { ALLOWED_FIELD_TYPES, FIELDS_WITH_OPTIONS } from './constants';
import type { FieldProperties } from './types';
import { getFieldOptions } from './utils';

export function useExtractionForm(
  extraction: any,
  onSubmit: () => void = () => {},
  onChange: () => void = () => {}
) {
  const { createAIExtraction, editAIExtraction, getFields } =
    useFeatheryRedux();

  const accounts = useAppSelector(
    (state) => state.accounts.organization?.team_accounts ?? []
  );

  const servars = useAppSelector((state) => state.fields.servars) ?? [];

  const { fileOptions, fieldProperties } = useMemo(() => {
    const servarFileMap: Record<string, string> = {};
    const singleDataMap: Record<string, string> = {};
    const multipleDataMap: Record<string, string> = {};
    const fieldProperties: Record<string, any> = {};

    servars.forEach((servar: any) => {
      if (servar.type === 'file_upload') {
        servarFileMap[servar.id] = servar.key;
      } else if (ALLOWED_FIELD_TYPES[servar.type]) {
        const _fieldProperties: FieldProperties = {
          type: servar.type
        };

        if (FIELDS_WITH_OPTIONS.includes(servar.type)) {
          if (servar.type !== 'checkbox') {
            _fieldProperties.options = getFieldOptions(servar);
          } else {
            _fieldProperties.options = [
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' }
            ];
          }
        }

        if (servar.semantic_description) {
          _fieldProperties.semanticDescription = servar.semantic_description;
        }

        if (servar.repeated) {
          multipleDataMap[servar.id] = servar.key;
        } else {
          singleDataMap[servar.id] = servar.key;
        }

        fieldProperties[servar.id] = _fieldProperties;
      }
    });
    const options = [servarFileMap, singleDataMap, multipleDataMap].map(
      (fieldMap) =>
        Object.entries(fieldMap).map(([servarId, servarKey]) => ({
          label: servarKey,
          value: servarId
        }))
    );

    return {
      fileOptions: options[0] as { label: string; value: string }[],
      singleDataOptions: options[1] as { label: string; value: string }[],
      multipleDataOptions: options[2] as { label: string; value: string }[],
      fieldProperties: fieldProperties as Record<string, FieldProperties>
    };
  }, [servars]);

  const hiddenFields = useAppSelector(
    (state) =>
      (state.fields.hiddenFields ?? []).filter(
        (field) => !field.key.startsWith('feathery.')
      ),
    deepEquals
  );

  const { hiddenFieldOptions, hiddenFieldProperties } = useMemo(() => {
    const _hiddenFieldOptions: { label: string; value: string }[] = [];
    const _hiddenFieldProperties: Record<string, Partial<FieldProperties>> = {};

    hiddenFields.forEach((field) => {
      if (field.options && field.options.length) {
        _hiddenFieldProperties[field.id] = {
          options: field.options.map((option: string) => ({
            label: option,
            value: option
          }))
        };
      }

      _hiddenFieldOptions.push({
        label: field.key,
        value: field.id
      });
    });

    return {
      hiddenFieldOptions: _hiddenFieldOptions,
      hiddenFieldProperties: _hiddenFieldProperties
    };
  }, [hiddenFields]);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<{
    key: string;
    file_sources: string[];
    merge_files: boolean;
    extraction_type: string;
    question_groups: any[];
    file_type: string;
    file_filter_query: string;
    reviewers: string[];
  }>({
    key: extraction?.key ?? '',
    extraction_type: extraction?.extraction_type ?? '',
    file_sources: extraction?.file_sources ?? [],
    merge_files: extraction?.merge_files ?? false,
    question_groups: getDefaultQuestionGroups(extraction),
    file_type: extraction?.file_type ?? 'image',
    file_filter_query: extraction?.file_filter_query ?? '',
    reviewers: extraction?.reviewers ?? []
  });

  const usedFields = useMemo(() => {
    return [
      ...formData.file_sources,
      ...formData.question_groups.reduce((acc, group) => {
        return [...acc, ...group.field_ids];
      }, [])
    ];
  }, [formData]);

  const handleChange = (newData: Record<string, any>) => {
    setFormData((formData) => ({
      ...formData,
      ...newData
    }));
    onChange();
  };

  const handleSubmit = useCallback(() => {
    setLoading(true);
    setError('');

    (async () => {
      try {
        const parsedData = JSON.parse(JSON.stringify({ ...formData }));

        parsedData.question_groups = parsedData.question_groups.map(
          (group: any) => {
            group.field_ids = group.entities.map(
              (entity: any) => entity.field_id
            );

            group.entities = group.entities.map((entity: any) => {
              const { field_id, options, ...rest } = entity;

              if (
                !FIELDS_WITH_OPTIONS.includes(
                  fieldProperties[field_id]?.type
                ) &&
                !hiddenFieldProperties[field_id]?.options
              ) {
                rest.options = options;
              }

              return rest;
            });

            return group;
          }
        );

        const isValid = parsedData.question_groups.every((group: any) => {
          if (!group.entities.every((entity: any) => entity.name)) {
            setError('Please provide a name for each entity.');
            return false;
          }

          if (group.entities.length !== group.field_ids.length) {
            setError('Please select a field to save each entity to.');
            return false;
          }

          return true;
        });

        if (!isValid) {
          setLoading(false);
          return;
        }

        if (extraction) parsedData.extractionId = extraction.id;
        return (
          extraction
            ? editAIExtraction(parsedData)
            : createAIExtraction(parsedData)
        )
          .then(() => getFields({ hidden: true }))
          .then(() => onSubmit())
          .catch((err: any) => setError(err.message))
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setError((error as any).message);
        setLoading(false);
      }
    })();
  }, [formData, fieldProperties]);

  return {
    formData,
    setFormData,
    accounts,
    fileOptions,
    fieldProperties,
    hiddenFieldProperties,
    usedFields,
    handleChange,
    handleSubmit,
    error,
    loading,
    hiddenFieldOptions
  };
}
