/* eslint-disable react-hooks/exhaustive-deps */

import './flags';

import { useEffect, useState } from 'react';
import { Form, LoginForm } from '@feathery/react';
import * as FullStory from '@fullstory/browser';
import { useIntercom } from 'react-use-intercom';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

import useFeatheryRedux from './redux';
import { LogoLoader } from './components/Core';
import { IS_PROD_FE } from './api/utils';
import useTestUser from './test_utils/useTestUser';
import { getCookie, updateFavicon } from './utils/browserUtils';
import { checkUser, configureSSO, ssoRedirect } from './utils/sso';
import AppRouter from './AppRouter';
import { Auth } from 'aws-amplify';
import { isSSO, isSSOPath } from './utils/useGetJwt';
import { ACTIVATION_FORM_ID, SSO_FORM_ID } from './utils/constants';
import { getWorkspaceJwt } from './utils/useLogout';

const App = () => {
  const {
    getDocuments,
    getAIExtractions,
    getOrganization,
    getConfig,
    getBill,
    getThemes,
    getIntegrations,
    getTemplates,
    getABTests,
    getImages,
    getFields,
    getPanels,
    getPanelThemeAssetUse,
    getDynamicFonts,
    getBillingMetrics,
    getWorkspaces
  } = useFeatheryRedux();
  const { update } = useIntercom();
  const [stytchClient, setStytchClient] = useState(null);
  const [testAuthId, setTestAuthId] = useState('');
  useTestUser(stytchClient, setTestAuthId);

  const [resourcesInitialized, setResourcesInitialized] = useState(false);

  const [ssoUser, setSsoUser] = useState(null);

  useEffect(() => {
    if (isSSO) {
      configureSSO();
      checkUser(setSsoUser, fetchResources);
    } else if (getWorkspaceJwt()) fetchResources();
  }, []);

  setInterval(() => {
    // Refresh Cognito token every 10 minutes if logging in via SSO
    if (isSSO) Auth.currentSession();
  }, 600000);

  const initThirdParties = (email: string, company: string) => {
    if (!IS_PROD_FE) return;

    Sentry.setUser({ email });
    if ((window as any).heap) {
      (window as any).heap.identify(email);
      (window as any).heap.addUserProperties({ email, Email: email });
    }
    update({ email, company: company as any });
    mixpanel.init('43bd50ad5277615ac5e4b738bdc5e023');
    mixpanel.identify(email);
    if (!email.endsWith('@gmail.com')) {
      // Only track non-personal accounts to reduce # of sessions
      FullStory.init({ orgId: '188HTV' });
      FullStory.identify(email);
    }
  };

  const fetchResources = async () => {
    // Create new account on our backend if needed
    if (resourcesInitialized) return;

    const onboardingCookie = getCookie('featheryOnboardingId');
    const onboardingSource = getCookie('featheryOnboardingSource');
    await getOrganization({
      onboarding_id: onboardingCookie,
      onboarding_source: onboardingSource
    })
      .then((org: any) => {
        // Initialize third party applications with identification info
        initThirdParties(org.account.email, org.name);
        getPanels({ active: false });
        if (org.whitelabel?.brand_name) {
          document.title = org.whitelabel.brand_name;
        }
        if (org.whitelabel?.brand_favicon) {
          updateFavicon(org.whitelabel.brand_favicon);
        }
        // Initialize / preload global state in the application
        return Promise.all([
          getConfig(),
          getDocuments(),
          getAIExtractions(),
          getThemes(),
          getIntegrations(),
          getIntegrations({ entityType: 'ai' }),
          getTemplates(),
          getPanels({ active: true }),
          getFields(),
          getFields({ hidden: true }),
          getPanelThemeAssetUse(),
          getImages(),
          getABTests(),
          getDynamicFonts(),
          getBill(),
          getBillingMetrics(),
          org.enterprise_features.workspaces && getWorkspaces()
        ]);
      })
      .catch((error: any) => {
        if (error?.code === 'require-sso') location.href = '/sso';
        else throw error;
      })
      .then(() => {
        setResourcesInitialized(true);
      });
  };

  if (isSSO) {
    if (ssoUser) {
      return resourcesInitialized ? <AppRouter /> : <LogoLoader />;
    } else if (isSSOPath)
      return (
        <Form
          formId={SSO_FORM_ID}
          style={{ height: '100vh', width: '100vw' }}
          onSubmit={ssoRedirect}
        />
      );
  }
  if (getWorkspaceJwt())
    return resourcesInitialized ? <AppRouter /> : <LogoLoader />;
  return (
    <LoginForm
      formProps={{
        formId: ACTIVATION_FORM_ID,
        initialLoader: {
          show: true,
          loader: <LogoLoader />,
          initialContainerHeight: '100vh',
          initialContainerWidth: '100vw'
        }
      }}
      loginPath='/'
      // @ts-expect-error
      loader={<LogoLoader />}
      onLogin={fetchResources}
      onLogout={() => (location.href = location.origin)}
      onClientReady={(authClient) => setStytchClient(authClient)}
      authId={testAuthId}
    >
      <AppRouter />
    </LoginForm>
  );
};

export { App };
