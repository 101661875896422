import {
  DropdownField,
  InlineTooltip,
  TextField,
  YesNoSwitch
} from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { onFreePlan } from '../../../components/PricingPlans/plans';
import { useAppSelector } from '../../../hooks';

const FormBehaviorSection = ({ title, formData, handleValueChange }: any) => {
  const org = useAppSelector((state) => state.accounts.organization);

  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Complete Submission When All Required Fields Filled
            <InlineTooltip text='NOTE: If your last form steps have optional fields, your submissions may be set to "Completed" earlier than you expect' />
          </p>
          <YesNoSwitch
            id='submission-complete-required-fields-fill'
            checked={formData.complete_required_fields_submission ?? true}
            onCheckedChange={(value) =>
              handleValueChange('complete_required_fields_submission')(value)
            }
          />
        </label>
      </div>

      <div className='field-group'>
        <label>
          <p className='form-label'>
            After Form Completion
            <InlineTooltip text='What happens to the form after your user completes it' />
          </p>
          <DropdownField
            name='completion_behavior'
            selected={formData.completion_behavior || ''}
            onChange={(event: any) =>
              handleValueChange('completion_behavior')(event.target.value)
            }
            options={[
              { value: '', display: 'Hide Form' },
              {
                value: 'redirect',
                display: 'Redirect to URL'
              },
              {
                value: 'show_completed_screen',
                display: 'Show Form Completed Screen'
              }
            ]}
          />
        </label>
      </div>

      {formData.completion_behavior === 'redirect' && (
        <div className='field-group'>
          <label>
            <p className='form-label'>Redirect URL</p>
            <TextField
              placeholder='https://homepage.com'
              value={formData.redirect_url ?? ''}
              onComplete={handleValueChange('redirect_url')}
            />
          </label>
        </div>
      )}

      <div className='field-group'>
        <label>
          <p className='form-label'>
            Autoscroll
            <InlineTooltip text='When on a new step, autoscroll to the top' />
          </p>
          <DropdownField
            name='autoscroll'
            selected={formData.autoscroll || 'top_of_form'}
            onChange={(e: any) =>
              handleValueChange('autoscroll')(e.target.value)
            }
            options={[
              { value: 'top_of_form', display: 'To top of the form' },
              {
                value: 'top_of_window',
                display: 'To top of the site (if embedded)'
              },
              { value: 'none', display: 'None' }
            ]}
          />
        </label>
      </div>

      <div className='field-group'>
        <label>
          <p className='form-label'>
            Autofocus Fields
            <InlineTooltip text='On each step, automatically set the cursor to the first field' />
          </p>
          <YesNoSwitch
            id='autofocus'
            checked={formData.autofocus ?? true}
            onCheckedChange={(value) => handleValueChange('autofocus')(value)}
          />
        </label>
      </div>

      <div className='field-group'>
        <label>
          <p className='form-label'>Press &quot;Enter&quot; to Submit</p>
          <YesNoSwitch
            id='enter_submit'
            checked={formData.enter_submit ?? true}
            onCheckedChange={(value) =>
              handleValueChange('enter_submit')(value)
            }
          />
        </label>
      </div>

      <div className='field-group'>
        <label>
          <p className='form-label'>
            Browser Autocomplete
            <InlineTooltip text='Allow the browser to complete form fields for the user' />
          </p>
          <YesNoSwitch
            id='browser-autocomplete'
            checked={formData.autocomplete ?? true}
            onCheckedChange={(value) =>
              handleValueChange('autocomplete')(value)
            }
          />
        </label>
      </div>

      <div className='field-group'>
        <label>
          <p className='form-label'>Show Form Validation Errors</p>
          <DropdownField
            name='error_type'
            selected={formData.error_type || 'html5'}
            onChange={(e: any) =>
              handleValueChange('error_type')(e.target.value)
            }
            options={[
              { value: 'html5', display: 'Browser Native' },
              { value: 'inline', display: 'Inline' }
            ]}
          />
        </label>
      </div>

      {(onFreePlan(org) || org?.show_brand) && (
        <div className='field-group'>
          <label>
            <p className='form-label'>
              Feathery Badge Position
              <InlineTooltip text='Free plans display a "Built on Feathery" badge on your forms. You may configure its position here.' />
            </p>
            <DropdownField
              selected={formData.brand_position ?? 'bottom_right'}
              onChange={(e: any) =>
                handleValueChange('brand_position')(e.target.value)
              }
              options={[
                { value: 'bottom_right', display: 'Bottom Right Corner' },
                { value: 'bottom_left', display: 'Bottom Left Corner' },
                { value: 'top_right', display: 'Top Right Corner' },
                { value: 'top_left', display: 'Top Left Corner' }
              ]}
            />
          </label>
        </div>
      )}
    </SettingsContainer>
  );
};

export default FormBehaviorSection;
